import { Icon, Spinner } from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import "./CustomTable.scss";

export type CustomTableProps<T = any> = {
  tableData: T[];
  loading?: boolean;
  itemsPerPage: number;
  emptyTableMessage?: { icon: IconName; message: string };
  headerRow: JSX.Element;
  rowRenderer: (item: T, index: number) => JSX.Element;
  onPageChange?: (event: { selected: number }) => void;
  className?: string;
};

function CustomTable<T>(props: PropsWithChildren<CustomTableProps<T>>) {
  const [currentPageItems, setCurrentPageItems] = useState<T[]>([{} as any]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const handlePageChange = (event: { selected: number }) => {
    setPageNumber(event.selected + 1);
  };

  const updateTableView = () => {
    const startOffset = (pageNumber - 1) * props.itemsPerPage;
    const endOffset = startOffset + props.itemsPerPage;
    setCurrentPageItems(props.tableData.slice(startOffset, endOffset));
    setPageCount(Math.ceil(props.tableData.length / props.itemsPerPage));
  };

  useEffect(() => {
    if (props.tableData.length === 0) return;
    updateTableView();
  }, [pageNumber]);

  useEffect(() => {
    updateTableView();
    setPageNumber(1);
  }, [props.tableData]);
  return (
    <>
      {props.loading ? (
        <table className={"styled-table " + (props.className || "")}>
          <tr>
            <th></th>
          </tr>
          <tr>
            <td className="spinner-container">
              <Spinner />
            </td>
          </tr>
        </table>
      ) : (
        <table className={"styled-table " + (props.className || "")}>
          {props.headerRow}
          {props.tableData.length !== 0 ? (
            <>
              {currentPageItems.map(props.rowRenderer)}
              {pageNumber === pageCount ? (
                new Array(
                  props.tableData.length % props.itemsPerPage === 0
                    ? 0
                    : props.itemsPerPage -
                      (props.tableData.length % props.itemsPerPage)
                )
                  .fill(null)
                  .map(() => (
                    <tr>
                      <td colSpan={42}></td>
                    </tr>
                  ))
              ) : (
                <></>
              )}
            </>
          ) : (
            props.emptyTableMessage && (
              <tr className="no-data-row">
                <td className="no-data-cell" colSpan={42}>
                  <div className="message-container">
                    <Icon icon={props.emptyTableMessage.icon} size={36} />
                    <div className="message">
                      {props.emptyTableMessage.message}
                    </div>
                  </div>
                </td>
              </tr>
            )
          )}
        </table>
      )}
      {props.tableData.length !== 0 && !props.loading ? (
        <ReactPaginate
          className="pagination"
          pageCount={pageCount}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          previousLabel={<Icon icon="chevron-left" />}
          nextLabel={<Icon icon="chevron-right" />}
        ></ReactPaginate>
      ) : (
        <></>
      )}
    </>
  );
}

export default CustomTable;
