
export const MINIMUM_NUMBER_OF_HOURS = {
  THEORETICAL: 15,
  PRACTICAL: 8,
};

export const DL_RESTRICTIONS = [
  {
    DLCode: "A",
    description: "Motorcycle",
    disabled: false,
    vehicleCategories: [
      {
        category: "L1",
        description:
          "Two wheels with a maximum design speed not exceeding 50 kph",
        disabled: false,
      },
      {
        category: "L2",
        description:
          "Three wheels with a maximum design speed not exceeding 50 kph",
        disabled: false,
      },
      {
        category: "L3",
        description:
          "Two wheels with a maximum design speed exceeding 50 kph",
        disabled: false,
      },
    ],
  },
  {
    DLCode: "A1",
    description: "Tricycle",
    disabled: false,
    vehicleCategories: [
      {
        category: "L4",
        description:
          "Motorcycle with side cars with a maximum design speed exceeding 50 kph",
        disabled: false,
      },
      {
        category: "L5",
        description:
          "Three wheels symmetrically arranged with a maximum speed exceeding 50 kph",
        disabled: false,
      },
      {
        category: "L6",
        description:
          "Four wheels whose unladen mass is not more than 350 kg with maximum design speed not exceeding 45kph",
        disabled: false,
      },
      {
        category: "L7",
        description:
          "Four wheels whose unladen mass is not more than 550 kg with maximum design speed not exceeding 45kph",
        disabled: false,
      },
    ],
  },
  {
    DLCode: "B",
    description: "Light Passenger Car",
    disabled: false,
    vehicleCategories: [
      {
        category: "M1",
        description:
          "Vehicles used for carriage of passengers, comprising not more than 8 seats in addition to the driver's seat with GVW up to 5,000 kgs",
        disabled: false,
      },
    ],
  },
  {
    DLCode: "B1",
    description: "Jeepney and Passenger Van",
    disabled: false,
    vehicleCategories: [
      {
        category: "M2",
        description:
          "Vehicles used for carriage of passengers, comprising more than 8 seats in addition to the driver's seat with GVW up to 5,000 kgs",
        disabled: false,
      },
    ],
  },
  {
    DLCode: "B2",
    description: "Light Commercial Vehicle",
    disabled: false,
    vehicleCategories: [
      {
        category: "N1",
        description:
          "Vehicles used for the carriage of goods and having up to 3,500 kgs",
        disabled: false,
      },
    ],
  },
  {
    DLCode: "BE",
    description: "Articulated Passenger Car",
    disabled: true,
    vehicleCategories: [
      {
        category: "O1",
        description: "Trailers with a maximum GVW not exceeding 750 kgs",
        disabled: true,
      },
      {
        category: "O2",
        description:
          "Trailers with a maximum GVW exceeding 750 kgs but not exceeding 3,500 kgs",
        disabled: true,
      },
    ],
  },
  {
    DLCode: "C",
    description: "Heavy Commercial Vehicle",
    disabled: true,
    vehicleCategories: [
      {
        category: "N2",
        description:
          "Vehicles used for the carriage of goods and having a maximum GVW exceeding 3,500 kgs but not exceeding 12,000 kgs",
        disabled: true,
      },
      {
        category: "N3",
        description:
          "Vehicles used for the carriage of goods and having a maximum GVW exceeding 12,000 kgs",
        disabled: true,
      },
    ],
  },
  {
    DLCode: "CE",
    description: "Heavy Articulated Vehicle",
    disabled: true,
    vehicleCategories: [
      {
        category: "O3",
        description:
          "Trailers with a maximum GVW exceeding 3,500 kgs, but not exceeding 10,000 kgs",
        disabled: true,
      },
      {
        category: "O4",
        description: "Trailers with a maximum GVW exceeding 10,000 kgs",
        disabled: true,
      },
    ],
  },
  {
    DLCode: "D",
    description: "Buses, Coaches and the likes",
    disabled: true,
    vehicleCategories: [
      {
        category: "M3",
        description:
          "Vehicles used for carriage of passengers, comprising more than 8 seats in addition to the driver's seat and having a maximum GVW exceeding 5,000 kgs",
        disabled: true,
      },
    ],
  },
];

export const TRANSMITTAL_PRICES = {
  FORM_THEORETICAL: 90,
  FORM_PRACTICAL: 90
}