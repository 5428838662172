import {
  HTMLInputProps,
  NumericInput,
  NumericInputProps,
} from "@blueprintjs/core";
import { FC } from "react";

export type CustomNumberInputProps = Omit<NumericInputProps, "value"> &
  HTMLInputProps & { value?: string; allowNegative?: boolean };

const CustomNumberInput: FC<CustomNumberInputProps> = (props) => {
  const _onKeyDown: HTMLInputProps["onKeyDown"] = (event) => {
    const inputElement = event.target as HTMLInputElement;

    switch (event.key) {
      case "e":
      case "E":
      case "+":
        event.preventDefault();
        break;
      case "-":
        if (!props.allowNegative) {
          event.preventDefault();
        } else if (inputElement.value.startsWith("-")) {
          inputElement.value = inputElement.value.slice(1);
          event.preventDefault();
        } else if (isNaN(parseFloat("-" + inputElement.value))) {
          event.preventDefault();
        } else {
          inputElement.value = "-" + inputElement.value;
          event.preventDefault();
        }
        break;
      case ".":
        if (inputElement.value.includes(".")) {
          event.preventDefault();
        } else if (isNaN(parseFloat(inputElement.value + "."))) {
          event.preventDefault();
        }
    }

    props.onKeyDown?.(event);
  };

  const { onKeyDown, ...remainingProps } = props;

  return <NumericInput onKeyDown={_onKeyDown} {...remainingProps} />;
};

export default CustomNumberInput;
