import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/login/LoginPage";
import Layout from "./layout/Layout";
import HomePage from "./pages/home/HomePage";
import PersonalInformationPage from "./pages/enroll-student/personal-information-page/PersonalInformationPage";
import DLRestrictionsPage from "./pages/submit-certificate/dlc-classification-page/DLRestrictionsPage";
import PhotoCapturePage from "./pages/enroll-student/photo-capture-page/PhotoCapturePage";
import CourseDetailsPage from "./pages/submit-certificate/course-details-page/CourseDetailsPage";
import ConfirmationPage from "./pages/submit-certificate/confirmation-page/ConfirmationPage";
import SettingsPage from "./pages/settings/SettingsPage";
import ViewAccountsPage from "./pages/view-accounts/ViewAccountsPage";
import ViewDrivingSchoolPage from "./pages/view-accounts/view-driving-school/ViewDrivingSchoolPage";
import PageRedirection from "./pages/page-redirection/PageRedirection";
import ViewStudentsPage from "./pages/view-students/ViewStudentsPage";
import StudentConfirmationPage from "./pages/enroll-student/confirmation-page/StudentConfirmationPage";
import ReportsPage from "./pages/reports/ReportsPage";
import AboutPage from "./pages/about/AboutPage";
import {
  aboutTheSystem,
  privacyPolicy,
  refundPolicy,
  termsOfUseText,
} from "./pages/about/information-texts";
import InfoPage from "./pages/info/InfoPage";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Navigate to="/main" replace={true} />}
        ></Route>
        <Route path="/login" element={<LoginPage isAdmin={false} />}></Route>
        <Route
          path="/terms-of-use"
          element={
            <InfoPage
              header="Terms of Use"
              text={termsOfUseText}
              includeBackToLoginPageButton={true}
            />
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <InfoPage
              header="Privacy Policy"
              text={privacyPolicy}
              includeBackToLoginPageButton={true}
            />
          }
        />
        <Route
          path="/about-the-system"
          element={
            <InfoPage
              header="About the System"
              text={aboutTheSystem}
              includeBackToLoginPageButton={true}
            />
          }
        />
        <Route path="/about-the-company" element={<AboutPage />} />
        <Route
          path="/refund-policy"
          element={
            <InfoPage
              header="Refund Policy"
              text={refundPolicy}
              includeBackToLoginPageButton={true}
            />
          }
        />
        <Route
          path="/admin-login"
          element={<LoginPage isAdmin={true} />}
        ></Route>
        <Route path="/main" element={<Layout />}>
          <Route path="/main" element={<HomePage />}></Route>
          <Route path="/main/submit-certificate">
            <Route
              path="/main/submit-certificate"
              element={<DLRestrictionsPage />}
            ></Route>
            <Route
              path="/main/submit-certificate/2"
              element={<CourseDetailsPage />}
            ></Route>
            <Route
              path="/main/submit-certificate/confirmation"
              element={<ConfirmationPage />}
            ></Route>
          </Route>
          <Route
            path="/main/student-list"
            element={<ViewStudentsPage />}
          ></Route>
          <Route path="/main/enroll-student">
            <Route
              path="/main/enroll-student"
              element={<PersonalInformationPage />}
            ></Route>
            <Route
              path="/main/enroll-student/2"
              element={<PhotoCapturePage />}
            ></Route>
            <Route
              path="/main/enroll-student/confirmation"
              element={<StudentConfirmationPage />}
            ></Route>
          </Route>
          <Route path="/main/reports" element={<ReportsPage />}></Route>

          {/* System Admin Features */}
          <Route path="/main/accounts">
            <Route path="/main/accounts" element={<ViewAccountsPage />}></Route>
            <Route
              path="/main/accounts/:drivingSchoolId"
              element={<ViewDrivingSchoolPage />}
            ></Route>
          </Route>

          <Route path="/main/settings" element={<SettingsPage />}></Route>
          <Route path="*" element={<PageRedirection to="/main" />} />
        </Route>
        <Route path="*" element={<PageRedirection to="/main" />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"),
  () => {
    // Add script tags for the Fingerprint SDK to work.
    const script1 = document.createElement("script");
    const script2 = document.createElement("script");

    script1.src = "/websdk.client.bundle.min.js";
    script2.src = "/fingerprint.sdk.min.js";
    script1.type = "text/javascript";
    script2.type = "text/javascript";
    script1.async = true;
    script2.async = true;
    script1.id = "fingerprint-sdk-1";
    script2.id = "fingerprint-sdk-2";
    script1.onload = () => (script1.className = "loaded");
    script2.onload = () => (script2.className = "loaded");

    document.body.appendChild(script1);
    document.body.appendChild(script2);
  }
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
