import { Button, H5, InputGroup } from "@blueprintjs/core";
import { DateInput, DateInputProps } from "@blueprintjs/datetime";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Asterisk from "../../../common-ui/asterisk/Asterisk";
import CustomCard from "../../../common-ui/custom-card/CustomCard";
import CustomDialog from "../../../common-ui/custom-dialog/CustomDialog";
import CustomSelect, {
  CustomSelectProps,
} from "../../../common-ui/custom-select/CustomSelect";
import { HttpResponseHandlerProps } from "../../../common-ui/req-error-handler/ReqErrorHandler";
import { MIN_DATE, SELECT_ITEMS } from "../../../utils/constants";
import { EMPTY_FORMS } from "../../../utils/constants/empty-forms";
import {
  getCredentialsFromLocalStorage,
  mainStorage,
} from "../../../utils/custom-local-storage";
import { HTTP_APIs } from "../../../utils/http-apis";
import { templateDateInputProps } from "../../../utils/template-props";
import { getToaster, showHTTPResponseToaster } from "../../../utils/toaster-ui";
import { StringVal } from "../../../utils/typings";
import "./PersonalInformationPage.scss";

const PersonalInformationPage = () => {
  const credentials = getCredentialsFromLocalStorage();
  const navigation = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (credentials?.role !== "ADMIN") {
      navigation("/main");
    }
  }, []);

  const [formValues, setFormValues] = useState(
    mainStorage.get("student-profile-to-enroll")
  );
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSearchSuccessful, setIsSearchSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasExistingLicense, setHasExistingLicense] = useState(true);

  const [regionItems, setRegionItems] = useState<StringVal[]>([]);
  const [provinceItems, setProvinceItems] = useState<StringVal[]>([]);
  const [cityItems, setCityItems] = useState<StringVal[]>([]);
  const [brgyItems, setBrgyItems] = useState<StringVal[]>([]);

  const [isClearAllModalOpen, setIsClearAllModalOpen] = useState(false);
  // Form Handlers

  type SelectKeys =
    | "sex"
    | "status"
    | "region"
    | "province"
    | "cityMunicipality"
    | "brgyDistrict";
  type TextInputKeys =
    | "firstName"
    | "middleName"
    | "lastName"
    | "licenseNumber"
    | "houseBuilding"
    | "streetName"
    | "zip"
    | "ltoPortalId";

  const onProgramTypeUpdate = (programType: string) => {
    switch (programType) {
      case "theoretical":
        setHasExistingLicense(false);
        break;
      case "practical":
        setHasExistingLicense(true);
        setIsSearchSuccessful(false);
        break;
      case "":
      case null:
        break;
      default:
        throw new Error(`Program Type ${programType} not caught.`);
    }
  };

  const handleItemSelect: (
    key: SelectKeys
  ) => CustomSelectProps["onItemSelect"] = (key) => {
    switch (key) {
      case "sex":
      case "status":
        return (item) =>
          setFormValues({
            ...formValues,
            [key]: item.value,
          });
      case "region":
        return (item) =>
          setFormValues({
            ...formValues,
            [key]: { name: item.string, code: item.value },
            province: { name: "", code: "" },
          });
      case "province":
        return (item) =>
          setFormValues({
            ...formValues,
            [key]: { name: item.string, code: item.value },
            cityMunicipality: { name: "", code: "" },
          });
      case "cityMunicipality":
        return (item) =>
          setFormValues({
            ...formValues,
            [key]: { name: item.string, code: item.value },
            brgyDistrict: { name: "", code: "" },
          });
      case "brgyDistrict":
        return (item) =>
          setFormValues({
            ...formValues,
            [key]: { name: item.string, code: item.value },
          });
    }
  };

  const handleTextInputChange: (
    key: TextInputKeys
  ) => ChangeEventHandler<HTMLInputElement> = (key) => {
    switch (key) {
      case "zip":
        // if ()
        return (event) => {
          const reg = /^\d+$/;
          const isInputEmpty = !(event?.target as HTMLInputElement).value;
          const isInputMadeOfNumbers = reg.test(
            (event?.target as HTMLInputElement).value || ""
          );
          const isInputAtMostFourCharacters =
            (event?.target as HTMLInputElement).value.length <= 4;
          if (
            isInputEmpty ||
            (isInputMadeOfNumbers && isInputAtMostFourCharacters)
          ) {
            setFormValues({ ...formValues, [key]: event?.target.value });
          }
        };
      default:
        return (event) =>
          setFormValues({ ...formValues, [key]: event?.target.value });
    }
  };

  const handleDobChange: DateInputProps["onChange"] = (selectedDate) => {
    setFormValues({ ...formValues, dob: selectedDate.toISOString() });
  };

  const handleDobError = () => {
    setFormValues({ ...formValues, dob: "" });
  };

  // Form Validation

  useEffect(() => {
    const computedFormValidity = Object.entries(formValues).every((entry) => {
      const formKey = entry[0] as keyof typeof formValues;
      switch (formKey) {
        case "ltoPortalId":
        case "firstName":
        case "lastName":
        case "dob":
        case "sex":
        case "status":
        case "houseBuilding":
        case "streetName":
          const stringValue = entry[1] as typeof formValues[typeof formKey];
          if (!stringValue) return false;
          return true;
        // Add validation of zip code
        case "zip":
          const zipStringValue = entry[1] as typeof formValues[typeof formKey];
          if (!zipStringValue) return false;
          if (!/^\d+$/.test(zipStringValue)) return false;
          if (zipStringValue.length !== 4) return false;
          return true;
        case "licenseNumber":
          const licenseValue = entry[1] as typeof formValues[typeof formKey];
          if (hasExistingLicense && !licenseValue) return false;
          return true;
        case "region":
        case "province":
        case "cityMunicipality":
        case "brgyDistrict":
          const locationValue = entry[1] as typeof formValues[typeof formKey];
          if (!locationValue.code) return false;
          return true;
        case "middleName":
          return true;
        default:
          throw new Error(`Uncaught Form Entry: ${entry[0]}, ${entry[1]}`);
      }
    });

    setIsFormValid(computedFormValidity);
  }, [formValues]);

  const [resHandler, setResHandler] = useState<HttpResponseHandlerProps>({
    resStatus: "default",
  });

  // Address Form Dynamics

  useEffect(() => {
    HTTP_APIs.address.getRegionsPH().then((response) => {
      setResHandler({
        resStatus: response.status,
        definedErrorModalProps: {
          message: "Unable to fetch the Regions data.",
        },
      });

      if (response.status !== "success") return;

      setRegionItems(
        response.data.map((region) => ({
          string: region.region_name,
          value: region.region_code,
        }))
      );
    });
  }, []);

  useEffect(() => {
    const formRegionCode = formValues.region.code;
    if (!formRegionCode) return;

    HTTP_APIs.address.getProvincesPH(formRegionCode).then((response) => {
      setResHandler({
        resStatus: response.status,
        definedErrorModalProps: {
          message: "Unable to fetch the Provinces data.",
        },
      });

      if (response.status !== "success") return;
      setProvinceItems(
        response.data.map((province) => ({
          string: province.province_name,
          value: province.province_code,
        }))
      );
    });
  }, [formValues.region]);

  useEffect(() => {
    setCityItems([]);

    const formProvinceCode = formValues.province.code;
    if (!formProvinceCode) return;

    HTTP_APIs.address.getCitiesPH(formProvinceCode).then((response) => {
      setResHandler({
        resStatus: response.status,
        definedErrorModalProps: {
          message: "Unable to fetch the Cities data.",
        },
      });

      if (response.status !== "success") return;
      setCityItems(
        response.data.map((city) => ({
          string: city.city_name,
          value: city.city_code,
        }))
      );
    });
  }, [formValues.province.code]);

  useEffect(() => {
    setBrgyItems([]);

    const formCityCode = formValues.cityMunicipality.code;
    if (!formCityCode) return;

    HTTP_APIs.address.getBrgyPH(formCityCode).then((response) => {
      setResHandler({
        resStatus: response.status,
        definedErrorModalProps: {
          message: "Unable to fetch the Barangays data.",
        },
      });

      if (response.status !== "success") return;
      setBrgyItems(
        response.data.map((barangays) => ({
          string: barangays.brgy_name,
          value: barangays.brgy_code,
        }))
      );
      console.log(formValues);

      // Reassign form values to recheck form validity
      setFormValues({ ...formValues });
    });
  }, [formValues.cityMunicipality.code]);

  const onSearchButtonClick = async () => {
    setIsLoading(true);
    const response = await HTTP_APIs.LTO.getDLDetails({
      licenseNumber: formValues.licenseNumber,
      dateOfBirth: formValues.dob,
    });
    setIsLoading(false);

    switch (response.status) {
      case "success":
        setIsSearchSuccessful(true);
        console.log(response);
        const regionObj = {
          name: response.data.region,
          code: response.data.psgcAddressCode.slice(0, 2),
        };
        const provinceObj = {
          name: response.data.province.slice(0, 4),
          code: response.data.psgcAddressCode.slice(0, 4),
        };
        const cityObj = {
          name: response.data.cityMunicipality.slice(0, 6),
          code: response.data.psgcAddressCode.slice(0, 6),
        };
        const brgyObj = {
          name: response.data.brgyDistrict.slice(0, 9),
          code: response.data.psgcAddressCode.slice(0, 9),
        };

        setFormValues({
          ...formValues,
          firstName: response.data.firstName,
          middleName: response.data.middleName || "",
          lastName: response.data.lastName,
          sex: response.data.sex,
          status: response.data.status,
          houseBuilding: response.data.houseBuilding,
          streetName: response.data.streetName,
          region: regionObj,
          province: provinceObj,
          cityMunicipality: cityObj,
          brgyDistrict: brgyObj,
          zip: response.data.zip,
          ltoPortalId: response.data.ltoPortalId,
        });
        break;
      default:
    }
    showHTTPResponseToaster(response);
  };

  const onNextButtonClick = () => {
    mainStorage.set("student-profile-to-enroll", formValues);
    navigation("/main/enroll-student/2");
  };

  const onClearForm = () => {
    mainStorage.clear("student-profile-to-enroll");
    mainStorage.clear("photo-url");
    setFormValues(EMPTY_FORMS.PERSONAL_INFORMATION);
    setIsClearAllModalOpen(false);
    getToaster().show({
      message: "Successfully cleared form.",
      icon: "trash",
      intent: "danger",
    });
  };

  return (
    <>
      <CustomCard
        className="general-information-form"
        cardHeaderText="General Information"
      >
        <div className="form-row">
          <div className="field-container-vert">
            <div>
              Does Student have License Number?
              <Asterisk />
            </div>
            <CustomSelect
              items={[
                { string: "YES", value: "yes" },
                { string: "NO", value: "no" },
              ]}
              onItemSelect={(value) =>
                setHasExistingLicense(value.value === "yes" ? true : false)
              }
              value={hasExistingLicense === true ? "yes" : "no"}
              width={"300px"}
              disabled={isLoading}
            ></CustomSelect>
          </div>
        </div>
      </CustomCard>
      <CustomCard
        className="personal-information-form"
        cardHeaderText="Student Driver Information"
      >
        <div className="form-row">
          <div className="field-container-vert">
            <div>
              LTO Portal ID
              <Asterisk />
            </div>
            <InputGroup
              className="first-name-input"
              spellCheck={false}
              value={formValues.ltoPortalId}
              onChange={handleTextInputChange("ltoPortalId")}
            ></InputGroup>
          </div>
        </div>
        <div className="form-row">
          <div className="field-container-vert">
            <div>
              First Name
              <Asterisk />
            </div>
            <InputGroup
              className="first-name-input"
              value={formValues.firstName}
              onChange={handleTextInputChange("firstName")}
              spellCheck={false}
              disabled={hasExistingLicense}
            ></InputGroup>
          </div>
          <div className="field-container-vert">
            <div>Middle Name</div>
            <InputGroup
              className="middle-name-input"
              value={formValues.middleName}
              onChange={handleTextInputChange("middleName")}
              spellCheck={false}
              disabled={hasExistingLicense}
            ></InputGroup>
          </div>
          <div className="field-container-vert">
            <div>
              Last Name
              <Asterisk />
            </div>
            <InputGroup
              className="last-name-input"
              value={formValues.lastName}
              onChange={handleTextInputChange("lastName")}
              spellCheck={false}
              disabled={hasExistingLicense}
            ></InputGroup>
          </div>
        </div>
        <div className="form-row">
          <div className="field-container-vert">
            <div>
              License Number
              {hasExistingLicense && <Asterisk />}
            </div>
            <InputGroup
              className="license-number-input"
              value={formValues.licenseNumber}
              onChange={handleTextInputChange("licenseNumber")}
              spellCheck={false}
              disabled={
                !hasExistingLicense ||
                isLoading ||
                (hasExistingLicense && isSearchSuccessful)
              }
            ></InputGroup>
          </div>
          <div className="field-container-vert">
            <div>
              Date of Birth
              <Asterisk />
            </div>
            <DateInput
              className="last-name-input"
              value={formValues.dob ? new Date(formValues.dob) : null}
              onError={handleDobError}
              onChange={handleDobChange}
              {...templateDateInputProps}
              disabled={isLoading || (hasExistingLicense && isSearchSuccessful)}
              minDate={MIN_DATE}
            />
          </div>
          <div className="field-container-vert">
            <div style={{ opacity: 0, userSelect: "none" }}>___</div>
            {hasExistingLicense && (
              <Button
                icon="search"
                intent="primary"
                onClick={onSearchButtonClick}
                loading={isLoading}
                disabled={
                  !formValues.licenseNumber ||
                  !formValues.dob ||
                  isSearchSuccessful
                }
              />
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="field-container-vert">
            <div>
              Sex
              <Asterisk />
            </div>
            <CustomSelect
              items={SELECT_ITEMS.SEX}
              onItemSelect={handleItemSelect("sex")}
              value={formValues.sex}
              width={"100px"}
              disabled={hasExistingLicense}
            />
          </div>
          <div className="field-container-vert">
            <div>
              Marital Status
              <Asterisk />
            </div>
            <CustomSelect
              items={SELECT_ITEMS.STATUS}
              onItemSelect={handleItemSelect("status")}
              value={formValues.status}
              width={"200px"}
              disabled={hasExistingLicense}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="field-container-vert">
            <div>
              House/Building Name
              <Asterisk />
            </div>
            <InputGroup
              className="house-building-input"
              value={formValues.houseBuilding}
              onChange={handleTextInputChange("houseBuilding")}
              spellCheck={false}
              disabled={hasExistingLicense}
            ></InputGroup>
          </div>
          <div className="field-container-vert">
            <div>
              Street Name
              <Asterisk />
            </div>
            <InputGroup
              className="street-name-input"
              value={formValues.streetName}
              onChange={handleTextInputChange("streetName")}
              spellCheck={false}
              disabled={hasExistingLicense}
            ></InputGroup>
          </div>
        </div>
        <div className="form-row">
          <div className="field-container-vert">
            <div>
              Region
              <Asterisk />
            </div>
            <CustomSelect
              items={regionItems}
              value={formValues.region.code}
              onItemSelect={handleItemSelect("region")}
              disabled={hasExistingLicense}
            />
          </div>
          <div className="field-container-vert">
            <div>
              Province
              <Asterisk />
            </div>
            <CustomSelect
              items={provinceItems}
              value={formValues.province.code}
              onItemSelect={handleItemSelect("province")}
              disabled={provinceItems.length === 0 || hasExistingLicense}
            />
          </div>
          <div className="field-container-vert">
            <div>
              City/Municipality
              <Asterisk />
            </div>
            <CustomSelect
              items={cityItems}
              value={formValues.cityMunicipality.code}
              onItemSelect={handleItemSelect("cityMunicipality")}
              disabled={cityItems.length === 0 || hasExistingLicense}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="field-container-vert">
            <div>
              Brgy/District
              <Asterisk />
            </div>
            <CustomSelect
              items={brgyItems}
              value={formValues.brgyDistrict.code}
              onItemSelect={handleItemSelect("brgyDistrict")}
              disabled={brgyItems.length === 0 || hasExistingLicense}
            />
          </div>
          <div className="field-container-vert">
            <div>
              ZIP Code
              <Asterisk />
            </div>
            <InputGroup
              className="zip-input"
              value={formValues.zip}
              onChange={handleTextInputChange("zip")}
              disabled={hasExistingLicense}
            ></InputGroup>
          </div>
        </div>
        <div className="buttons-container">
          <Button
            large={true}
            intent="danger"
            onClick={() => setIsClearAllModalOpen(true)}
          >
            CLEAR ALL
          </Button>
          <Button
            className="next-button"
            large={true}
            intent="primary"
            disabled={!isFormValid}
            onClick={onNextButtonClick}
          >
            NEXT
          </Button>
        </div>
      </CustomCard>
      <CustomDialog
        title="Clear Form?"
        isOpen={isClearAllModalOpen}
        onClose={() => setIsClearAllModalOpen(false)}
      >
        Are you sure you want to clear the entire form?
        <div className="buttons-container">
          <Button onClick={() => setIsClearAllModalOpen(false)}>BACK</Button>
          <Button intent="danger" onClick={onClearForm}>
            CLEAR ALL
          </Button>
        </div>
      </CustomDialog>
    </>
  );
};

export default PersonalInformationPage;
