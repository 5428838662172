import {
  Button,
  Divider,
  InputGroup,
  NumericInputProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
  TextArea,
  TextAreaProps,
} from "@blueprintjs/core";
import { DateInput, DateInputProps } from "@blueprintjs/datetime";
import dayjs from "dayjs";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Asterisk from "../../../common-ui/asterisk/Asterisk";
import CustomCard from "../../../common-ui/custom-card/CustomCard";
import CustomNumberInput from "../../../common-ui/custom-number-input/CustomNumberInput";
import CustomSelect, {
  CustomSelectProps,
} from "../../../common-ui/custom-select/CustomSelect";
import {
  formatName,
  getSelectValue,
  isStringNumeric,
} from "../../../utils/common";
import {
  MINIMUM_HOURS,
  MIN_DATE,
  SELECT_ITEMS,
} from "../../../utils/constants";
import {
  getCredentialsFromLocalStorage,
  mainStorage,
} from "../../../utils/custom-local-storage";
import { templateDateInputProps } from "../../../utils/template-props";
import { StringVal } from "../../../utils/typings";
import "./CourseDetailsPage.scss";

const CourseDetailsPage = () => {
  const credentials = getCredentialsFromLocalStorage();
  const navigation = useNavigate();
  const studentProfile = mainStorage.get("student-profile-to-certify")!;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formType = mainStorage.get("form-to-submit-type");
  const minimumHours =
    MINIMUM_HOURS[formType === "theoretical" ? "theoretical" : "practical"];
  const [formValues, setFormValues] = useState(
    mainStorage.get("course-details-form")
  );
  const [isFormValid, setIsFormValid] = useState(false);

  const [dateConstraints, setDateConstraints] = useState({
    maxDate: new Date(),
    theoretical: {
      dateStartedMinDate: MIN_DATE,
      dateCompletedMinDate: MIN_DATE,
    },
    practical: {
      dateStartedMinDate: MIN_DATE,
      dateCompletedMinDate: MIN_DATE,
    },
  });

  const issuingBranchItems: StringVal[] =
    mainStorage
      .get("user-credentials")
      ?.issuingBranches.map((branch) => ({ string: branch, value: branch })) ||
    [];

  type TextInputKeys = "drivingCourseName";
  const handleTextInputChange: (
    key: TextInputKeys
  ) => ChangeEventHandler<HTMLInputElement> = (key) => (event) => {
    setFormValues({ ...formValues, [key]: event.target.value });
  };

  const handleNumericInput: NumericInputProps["onValueChange"] = (value) => {
    setFormValues({
      ...formValues,
      noOfHours: value.toString(),
    });
  };

  const handleItemSelect: CustomSelectProps["onItemSelect"] = (item) => {
    setFormValues({ ...formValues, issuingBranch: item.value });
  };

  const handleTextAreaChange: TextAreaProps["onChange"] = (event) => {
    setFormValues({
      ...formValues,
      comments: event.target.value,
    });
  };

  type DateInputKeys = "date-started" | "date-completed";

  const handleDateInputChange: (
    key: DateInputKeys
  ) => DateInputProps["onChange"] = (key) => {
    switch (key) {
      case "date-started":
        return (selectedDate) => {
          setDateConstraints({
            ...dateConstraints,
            theoretical: {
              ...dateConstraints.theoretical,
              dateCompletedMinDate: selectedDate,
            },
          });

          const dateStartedUnix = dayjs(selectedDate.toISOString()).unix();
          const dateCompletedUnix = dayjs(formValues.dateCompleted).unix();

          if (dateStartedUnix <= dateCompletedUnix) {
            setFormValues({
              ...formValues,
              dateStarted: selectedDate.toISOString(),
            });
          } else {
            setFormValues({
              ...formValues,
              dateStarted: selectedDate.toISOString(),
            });
          }
        };
      case "date-completed":
        return (selectedDate) => {
          setFormValues({
            ...formValues,
            dateCompleted: selectedDate.toISOString(),
          });
        };
    }
  };

  type RadioKeys = "assessment" | "overall-rating";
  const handleRadioGroupChange: (
    key: RadioKeys
  ) => RadioGroupProps["onChange"] = (key) => {
    switch (key) {
      case "assessment":
        return (event) =>
          setFormValues({
            ...formValues,
            assessment: event.currentTarget.value,
          });
      case "overall-rating":
        return (event) =>
          setFormValues({
            ...formValues,
            rating: event.currentTarget.value,
          });
    }
  };

  const onBackButtonClick = () => {
    mainStorage.set("course-details-form", formValues);
    if (formType === "theoretical") {
      navigation("/main/student-list");
    } else {
      navigation("/main/submit-certificate");
    }
  };

  const onNextButtonClick = () => {
    mainStorage.set("course-details-form", formValues);
    navigation("/main/submit-certificate/confirmation");
  };

  useEffect(() => {
    const computedFormValidity = Object.entries(formValues).every((entry) => {
      const key = entry[0] as keyof typeof formValues;
      const value = entry[1];
      console.log(key)
      switch (key) {
        case "trainingPurpose":
        case "issuingBranch":
        case "drivingCourseName":
          const stringValue = entry[1] as typeof formValues[typeof key];
          if (!stringValue) return false;
          console.log(1)
          return true;
        case "dateStarted":
          if (!value) return false;
          if (!dayjs(value).isValid()) return false;
          console.log(2)
          return true;
        case "dateCompleted":
          if (!value) return false;
          if (!dayjs(value).isValid()) return false;

          const dateStartedUnix = dayjs(formValues.dateStarted).unix();
          const dateCompletedUnix = dayjs(formValues.dateCompleted).unix();

          if (dateStartedUnix > dateCompletedUnix) return false;
          console.log(3)
          return true;
        case "noOfHours":
          if (!value) return false;
          if (!isStringNumeric(value)) return false;
          const noOfHours = parseFloat(value);
          if (noOfHours < minimumHours) return false;
          console.log(4)
          return true;
        case "rating":
        case "assessment":
          if (!value) return false;
          if (!["PASSED", "FAILED"].includes(value)) return false;
          console.log(5)
          return true;
        case "comments":
          return true;
      }
    });

    setIsFormValid(computedFormValidity);
  }, [formValues]);

  return (
    <>
      <CustomCard
        className="course-details-form"
        cardHeaderText={`Course Details (${formatName(studentProfile, "fl")})`}
      >
        {" "}
        <div className="form-row">
          <div className="field-container-vert">
            <div>Training Purpose</div>
            <CustomSelect
              items={
                SELECT_ITEMS[
                  formType === "theoretical"
                    ? "THEORETICAL_TRAINING_PURPOSE"
                    : "PRACTICAL_TRAINING_PURPOSE"
                ]
              }
              value={formValues.trainingPurpose}
              onItemSelect={(item) =>
                setFormValues({ ...formValues, trainingPurpose: item.value })
              }
            ></CustomSelect>
          </div>
        </div>
        <div className="form-row">
          <div className="field-container-vert">
            <div>Issuing Branch</div>
            <CustomSelect
              items={issuingBranchItems}
              value={formValues.issuingBranch}
              onItemSelect={handleItemSelect}
            ></CustomSelect>
          </div>
        </div>
        <div className="form-row">
          <div className="field-container-vert">
            <div>Driving Course Name</div>
            <InputGroup
              value={formValues.drivingCourseName}
              onChange={handleTextInputChange("drivingCourseName")}
              style={{ width: 200 }}
            />
          </div>
        </div>
        <Divider className="horizontal-divider" />
        <div className="forms-divider">
          <div className="theoretical-form subform">
            <h4 className="subform-header">
              {formType === "theoretical" ? "Theoretical" : "Practical"}{" "}
              Instruction Training
            </h4>
            <div className="form-row">
              <div className="field-container-vert">
                <div>Date Started</div>
                <DateInput
                  {...templateDateInputProps}
                  value={
                    formValues.dateStarted
                      ? new Date(formValues.dateStarted)
                      : null
                  }
                  onChange={handleDateInputChange("date-started")}
                  minDate={dateConstraints.theoretical.dateStartedMinDate}
                  maxDate={dateConstraints.maxDate}
                />
              </div>
              <div className="field-container-vert">
                <div>Date Completed</div>
                <DateInput
                  {...templateDateInputProps}
                  value={
                    formValues.dateCompleted
                      ? new Date(formValues.dateCompleted)
                      : null
                  }
                  onChange={handleDateInputChange("date-completed")}
                  minDate={dateConstraints.theoretical.dateCompletedMinDate}
                  maxDate={dateConstraints.maxDate}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="field-container-vert">
                <div>Total No. of Classroom Hours</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CustomNumberInput
                    value={formValues.noOfHours}
                    onValueChange={handleNumericInput}
                    min={minimumHours}
                    max={999}
                  />
                  {parseFloat(formValues.noOfHours) < minimumHours && (
                    <span
                      style={{
                        color: "var(--red)",
                        marginLeft: 10,
                        width: "120px",
                      }}
                    >
                      <Asterisk />
                      <span>Must be at least {minimumHours} hrs.</span>
                    </span>
                  )}
                  {parseFloat(formValues.noOfHours) > 4 &&
                    dayjs(new Date(), "hours").diff(
                      formValues.dateStarted,
                      "days"
                    ) <= 1 && (
                      <span
                        style={{
                          color: "var(--red)",
                          marginLeft: 10,
                          width: "180px",
                        }}
                      >
                        <Asterisk />
                        <span>Invalid no. of hours from start date.</span>
                      </span>
                    )}
                </div>
              </div>
            </div>
            <div className="radio-buttons-container">
              <div className="form-row">
                <div className="field-container-hor">
                  <div>ASSESSMENT:</div>
                  <RadioGroup
                    inline={true}
                    onChange={handleRadioGroupChange("assessment")}
                    selectedValue={formValues.assessment}
                  >
                    <Radio value="PASSED">PASSED</Radio>
                    <Radio value="FAILED">FAILED</Radio>
                  </RadioGroup>
                </div>
              </div>
              <div className="form-row">
                <div className="field-container-hor">
                  <div>OVERALL RATING:</div>
                  <RadioGroup
                    inline={true}
                    onChange={handleRadioGroupChange("overall-rating")}
                    selectedValue={formValues.rating}
                  >
                    <Radio value="PASSED">PASSED</Radio>
                    <Radio value="FAILED">FAILED</Radio>
                  </RadioGroup>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="field-container-hor">
                <div>Additional Comments:</div>
                <TextArea
                  draggable={false}
                  spellCheck={false}
                  value={formValues.comments}
                  onChange={handleTextAreaChange}
                ></TextArea>
              </div>
            </div>
          </div>
        </div>
        <div className="buttons-container">
          <Button large={true} onClick={onBackButtonClick}>
            {formType === "theoretical" ? "BACK" : "PREVIOUS"}
          </Button>
          <Button
            intent="primary"
            large={true}
            onClick={onNextButtonClick}
            disabled={!isFormValid}
          >
            NEXT
          </Button>
        </div>
      </CustomCard>
    </>
  );
};

export default CourseDetailsPage;
