import { H1 } from "@blueprintjs/core";
import { FC } from "react";
import CustomCard from "../../../common-ui/custom-card/CustomCard";
import BackToLoginButton from "../back-to-login-button/BackToLoginButton";
import "./InformationTextCard.scss";

export type InformationTextCardProps = {
  header: string;
  text: string;
  includeBackToLoginPageButton?: boolean;
};

const InformationTextCard: FC<InformationTextCardProps> = (props) => {
  return (
    <CustomCard className="information-text-card">
      {props.includeBackToLoginPageButton && <BackToLoginButton />}
      <H1 className="information-header">{props.header}</H1>
      <p
        className="information-text"
        dangerouslySetInnerHTML={{ __html: props.text }}
      ></p>
      {props.includeBackToLoginPageButton && <BackToLoginButton />}
    </CustomCard>
  );
};

export default InformationTextCard;
