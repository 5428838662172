import { Button, ButtonProps } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { FC, Ref, useState } from "react";
import "./NotesButton.scss";

export type NotesButtonProps = Omit<ButtonProps, "text" | "disabled"> & {
  notes?: string;
};

const NotesButton: FC<NotesButtonProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover2
      className="notes-button-popover"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      content={<div className="notes-container">{props.notes}</div>}
      position="top"
      renderTarget={(event) => (
        <Button
          elementRef={event.ref || undefined}
          {...props}
          disabled={!props.notes}
          onClick={() => setIsOpen(true)}
          text="Notes"
          active={isOpen}
        />
      )}
    ></Popover2>
  );
};

export default NotesButton;
