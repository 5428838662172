import { Dialog, DialogProps, IDialogProps } from "@blueprintjs/core";
import { FC } from "react";
import "./CustomDialog.css";

export type CustomDialogProps = DialogProps & { id?: string };

const CustomDialog: FC<DialogProps> = (props) => {
  return (
    <Dialog {...props}>
      <div className="custom-modal-window-container">{props.children}</div>
    </Dialog>
  );
};

export default CustomDialog;
