import addressApis from './address-ph-apis';
import loginApis from './personnel-apis';
import formsApis from './forms-apis';
import LTOApis from './LTO-apis';
import { systemAdminApis } from './system-admin-apis';
import fingerprintApis from './fingerprint-apis';

export const HTTP_APIs = {
  address: addressApis,
  personnel: loginApis,
  forms: formsApis,
  LTO: LTOApis,
  systemAdmin: systemAdminApis,
  fingerprint: fingerprintApis,
};