import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { BTFMessage } from 'shared-types';
import { ReqStatus } from '../typings';
import { APIPromise, CustomResponseData } from './typings';
import axios from 'axios';
import * as mime from 'mime';

export const HTTP_ERROR_MESSAGES = {
  error_500: 'Server Error occurred.',
  error_401: 'ERROR 401',
  error_CONNECTION: 'No internet connection.',
  error_UNKNOWN: 'Unknown error occurred.'
}

export function processAxiosResponse<T>(axiosResponse: AxiosResponse<T>): CustomResponseData<T, ReqStatus> {
  const serverMessage = (axiosResponse.data as unknown as BTFMessage)._message;
  console.log('RESPONSE RECEIVED. Status: ', axiosResponse.status, ' Data: ', axiosResponse.data);
  switch (axiosResponse.status) {
    case 200:
      const parsedData = axiosResponse.data as Exclude<T, BTFMessage>;
      return {
        status: 'success',
        data: parsedData,
        message: serverMessage
      };
    case 400:
      return {
        status: 'error-defined',
        message: serverMessage
      };
    case 401:
      return {
        status: 'error-connection',
        message: HTTP_ERROR_MESSAGES.error_401
      }
    case 500:
      return {
        status: 'error-server',
        message: HTTP_ERROR_MESSAGES.error_500
      }
    case -1:
      return {
        status: 'error-connection',
        message: HTTP_ERROR_MESSAGES.error_CONNECTION
      }
    default:
      return {
        status: 'error-unknown',
        message: HTTP_ERROR_MESSAGES.error_UNKNOWN
      }
  }
};

export async function processInternalAPI<T>(params: {
  path: string;
  method: 'GET' | 'POST';
  reqBody?: any;
  reqQuery?: any;
  withCredentials?: boolean;
  config?: AxiosRequestConfig<any>;
}): APIPromise<T> {
  try {
    console.log("Path: ", params.path)

    let response: AxiosResponse<T, any>;
    const config = {
      withCredentials: params.withCredentials, params: params.reqQuery,
      ...(params.config || {})
    }

    switch (params.method) {
      case 'GET':
        response = await axios.get<T>(
          params.path,
          config
        );
        break;
      case 'POST':
        response = await axios.post<T>(
          params.path, params.reqBody,
          config
        );
    }
    return processAxiosResponse(response)
  } catch (error) {
    const errorStatus = (error as any)?.toJSON?.().status as number;
    const errorMessage = (error as any)?.response?.data?._message as string;
    console.error('this is the errorrr: ', error)
    switch (errorStatus) {
      case 400:
        return {
          status: 'error-defined',
          message: errorMessage
        };
      case 401:
        return {
          status: 'unauthorized',
          message: errorMessage
        }
      case 500:
        return {
          status: 'error-server',
          message: HTTP_ERROR_MESSAGES.error_500
        }
      case -1:
        return {
          status: 'error-connection',
          message: HTTP_ERROR_MESSAGES.error_CONNECTION
        }
      default:
        if (errorMessage === 'Network Error') {
          return {
            status: 'error-connection',
            message: HTTP_ERROR_MESSAGES.error_CONNECTION,
          }
        } else {
          return {
            status: 'error-unknown',
            message: HTTP_ERROR_MESSAGES.error_UNKNOWN,
          }
        }
    }
  }
}

export function parseToFormData(obj: any): FormData {
  let formData: FormData = new FormData();
  for (const [key, value] of Object.entries(obj)) {
    if (value === undefined) continue;
    if (value instanceof Blob) {
      // @ts-ignore
      formData.append(key, value, `${key}.${mime.getExtension(value.type)}`);
      continue;
    };

    if (typeof value === 'string') {
      if (value !== '') {
        formData.append(key, value)
      };
    } else {
      formData.append(key, JSON.stringify(value));
    };
  };
  return formData;
};