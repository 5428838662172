import {
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarHeading,
  Button,
  IconName,
  Icon,
} from "@blueprintjs/core";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import PageRedirection from "../pages/page-redirection/PageRedirection";
import { formatName } from "../utils/common";
import { getCredentialsFromLocalStorage } from "../utils/custom-local-storage";
import "./Layout.scss";
import LogoutModal from "./LogoutModal";

type MenuButton = {
  name: string;
  icon: IconName;
  url: string;
};

const Layout = () => {
  const menuButtonsArray: MenuButton[] = [];

  menuButtonsArray.push({
    name: "Home",
    icon: "home",
    url: "/main",
  });

  const credentials = getCredentialsFromLocalStorage();

  if (!credentials) {
    return <PageRedirection to="/login" />;
  }

  const name = formatName(credentials.data, "lfi");

  switch (credentials.role) {
    case "ADMIN":
      menuButtonsArray.push(
        {
          name: "View Students",
          icon: "th-list",
          url: "/main/student-list",
        },
        {
          name: "Enroll Student",
          icon: "user",
          url: "/main/enroll-student",
        },
        { name: "Reports", icon: "clipboard", url: "/main/reports" }
      );
      break;
    case "INSTRUCTOR":
      menuButtonsArray.push({
        name: "View Students",
        icon: "th-list",
        url: "/main/student-list",
      });
      break;
    case "SYSTEM-ADMIN":
      menuButtonsArray.push({
        name: "View Accounts",
        icon: "application",
        url: "/main/accounts",
      });
      break;
  }

  menuButtonsArray.push({
    name: "Settings",
    icon: "cog",
    url: "/main/settings",
  });

  return (
    <>
      <Navbar id="main-navigation-bar">
        <NavbarGroup align="left">
            <img className="logo" src="coso-logo.png"></img>
          <NavbarHeading className="unselect-text">
          </NavbarHeading>
          <NavbarDivider className="divider" />
          <ul className="menu">
            {menuButtonsArray.map((buttonSpecs, index) => (
              <li className="menu-item" key={buttonSpecs.name}>
                <NavLink end={true} className="link" to={buttonSpecs.url}>
                  <Icon icon={buttonSpecs.icon}></Icon>
                  <div className="item-name">{buttonSpecs.name}</div>
                </NavLink>
              </li>
            ))}
          </ul>
        </NavbarGroup>
        <NavbarGroup className="nav-right" align="right">
          <div className="big-font unselect-text">
            {name} - {credentials.role}
          </div>
          <NavbarDivider className="divider" />
          <LogoutModal />
        </NavbarGroup>
      </Navbar>
      <div id="page">
        <div id="cards-container">
          <Outlet></Outlet>
        </div>
      </div>
    </>
  );
};

export default Layout;
