import { Button, Icon, Spinner } from "@blueprintjs/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import CustomCard from "../../../common-ui/custom-card/CustomCard";
import {
  getCredentialsFromLocalStorage,
  mainStorage,
} from "../../../utils/custom-local-storage";
import PageRedirection from "../../page-redirection/PageRedirection";
import "./PhotoCapturePage.scss";

const PhotoCapturePage = () => {
  const credentials = getCredentialsFromLocalStorage();

  const navigation = useNavigate();

  const goBackPage = () => {
    navigation("/main/enroll-student");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (credentials?.role !== "ADMIN") {
      navigation("/main");
    }

    if (!mainStorage.get("student-profile-to-enroll").firstName) {
      navigation("/main/enroll-student");
    }
  }, []);

  const webcamRef = useRef<Webcam | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const [streamStatus, setStreamStatus] = useState<
    "loading" | "error" | "active"
  >("loading");
  const [imageUrl, setImgUrl] = useState(
    mainStorage.get("photo-url") || undefined
  );
  const [verticalMaskWidth, setVerticalMaskWidth] = useState(0);
  const [horizontalMaskHeight, setHorizontalMaskHeight] = useState(0);

  const [webcamWidth, setWebcamWidth] = useState(0);
  const [webcamHeight, setWebcamHeight] = useState(0);

  const handleOnStreamLoad = () => {
    setStreamStatus("active");
    let interval = setTimeout(() => {
      if (!webcamRef.current) return;
      clearTimeout(interval);

      const webcamCanvas = webcamRef.current.getCanvas();

      if (!webcamCanvas) return;
      const frameDimension = Math.min(webcamCanvas.width, webcamCanvas.height);
      const verticalMaskWidthValue = Math.max(
        0,
        (webcamCanvas.width - frameDimension) / 2
      );
      const horizontalMaskHeightValue = Math.max(
        webcamCanvas.height - frameDimension,
        0
      );
      setWebcamWidth(webcamCanvas.width);
      setWebcamHeight(webcamCanvas.height);
      setVerticalMaskWidth(verticalMaskWidthValue);
      setHorizontalMaskHeight(horizontalMaskHeightValue);
    }, 100);
  };

  const capture = () => {
    if (!webcamRef.current) return;

    let screenshot = webcamRef.current.getScreenshot();
    if (!screenshot) return;
    if (!canvasRef.current) return;

    const webcamCanvas = webcamRef.current.getCanvas()!;

    const frameDimension = Math.min(webcamCanvas.width, webcamCanvas.height);

    canvasRef.current.width = frameDimension;
    canvasRef.current.height = frameDimension;

    canvasRef.current
      .getContext("2d")
      ?.drawImage(
        webcamCanvas,
        verticalMaskWidth,
        horizontalMaskHeight,
        frameDimension,
        frameDimension,
        0,
        0,
        frameDimension,
        frameDimension
      );

    const dataURI = canvasRef.current.toDataURL("image/jpeg");
    setImgUrl(dataURI);
    mainStorage.set("photo-url", dataURI);
  };

  const onPreviousButtonClick = () => {
    goBackPage();
  };

  const onNextButtonClick = () => {
    navigation("/main/enroll-student/confirmation");
  };

  return (
    <>
      <CustomCard cardHeaderText="Photo Capture">
        <div className="webcam-main-container">
          <div className="webcam-stream-container">
            <Webcam
              className="webcamera-stream"
              onUserMedia={handleOnStreamLoad}
              ref={webcamRef as any}
            />
            <div
              className="side-mask-container stream-overlay"
              style={{ width: webcamWidth, height: webcamHeight }}
            >
              <div
                className="stream-mask"
                style={{ width: verticalMaskWidth }}
              />
              <div
                className="stream-mask"
                style={{ width: verticalMaskWidth }}
              />
            </div>
            <div
              className="upper-mask-container stream-overlay"
              style={{ width: webcamWidth, height: webcamHeight }}
            >
              <div
                className="stream-mask"
                style={{ height: horizontalMaskHeight }}
              />
            </div>
            {streamStatus === "loading" && (
              <div className="webcam-spinner-container">
                <Spinner></Spinner>
              </div>
            )}
          </div>
          <div className="stream-vert-divider" />
          <div className="webcam-form">
            <div className="picture-container">
              <canvas className="hidden-canvas" ref={canvasRef}></canvas>
              <img className="photo" src={imageUrl} draggable={false}></img>
              <div className="picture-placeholder">
                <Icon icon="user" size={130} />
              </div>
            </div>
            <Button
              large={true}
              className="capture-button"
              intent="primary"
              icon="camera"
              onClick={capture}
              disabled={streamStatus !== "active"}
            />
          </div>
        </div>
        <div className="buttons-container">
          <Button large={true} onClick={onPreviousButtonClick}>
            PREVIOUS
          </Button>
          <Button
            intent="primary"
            large={true}
            onClick={onNextButtonClick}
            disabled={!imageUrl}
          >
            NEXT
          </Button>
        </div>
      </CustomCard>
    </>
  );
};

export default PhotoCapturePage;
