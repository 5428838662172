import { FC } from "react";
import BackgroundComponent from "../../common-ui/background/BackgroundComponent";
import InformationTextCard, {
  InformationTextCardProps,
} from "../about/information-text-card/InformationTextCard";
import "./InfoPage.scss";

export type InfoPageProps = InformationTextCardProps;

const InfoPage: FC<InfoPageProps> = (props) => {
  return (
    <>
      <BackgroundComponent url="/login-bg.jpg" />
      <div id="cards-container" className="info-cards-container">
        <InformationTextCard {...props} />
      </div>
    </>
  );
};

export default InfoPage;
