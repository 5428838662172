import { Button, InputGroup } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { FC } from "react";
import Asterisk from "../../common-ui/asterisk/Asterisk";
import CustomDialog, {
  CustomDialogProps,
} from "../../common-ui/custom-dialog/CustomDialog";
import CustomSelect from "../../common-ui/custom-select/CustomSelect";
import { templateDateInputProps } from "../../utils/template-props";
import "./StudentRegistrationWindow.scss";

export type StudentRegistrationWindowProps = {
  isOpen: boolean;
  onClose: CustomDialogProps["onClose"];
};

const StudentRegistrationWindow: FC<StudentRegistrationWindowProps> = (
  props
) => {
  return (
    <CustomDialog
      style={{ width: "auto" }}
      isOpen={props.isOpen}
      title="Register Student"
      onClose={props.onClose}
    >
      <form style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <div className="form-row" style={{ marginBottom: 20 }}>
          <div className="field-container-vert">
            <div>LTO Portal ID</div>
            <InputGroup></InputGroup>
          </div>
        </div>
        <div className="form-row">
          <div className="field-container-vert">
            <div>
              First Name
              <Asterisk />
            </div>
            <InputGroup
              className="first-name-input"
              spellCheck={false}
            ></InputGroup>
          </div>
          <div className="field-container-vert">
            <div>Middle Name</div>
            <InputGroup
              className="middle-name-input"
              spellCheck={false}
            ></InputGroup>
          </div>
          <div className="field-container-vert">
            <div>
              Last Name
              <Asterisk />
            </div>
            <InputGroup
              className="last-name-input"
              spellCheck={false}
            ></InputGroup>
          </div>
        </div>
        <div className="form-row">
          <div className="field-container-vert">
            <div>
              Date of Birth
              <Asterisk />
            </div>
            <DateInput
              className="last-name-input"
              {...templateDateInputProps}
            />
          </div>
          <div className="field-container-vert">
            <div>
              Sex
              <Asterisk />
            </div>
            <CustomSelect
              items={[]}
              onItemSelect={() => {}}
              value=""
              width={"100px"}
            />
          </div>
          <div className="field-container-vert">
            <div>
              Marital Status
              <Asterisk />
            </div>
            <CustomSelect
              items={[]}
              onItemSelect={() => {}}
              value=""
              width={"200px"}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="field-container-vert">
            <div>
              House/Building Name
              <Asterisk />
            </div>
            <InputGroup
              className="house-building-input"
              spellCheck={false}
            ></InputGroup>
          </div>
          <div className="field-container-vert">
            <div>
              Street Name
              <Asterisk />
            </div>
            <InputGroup
              className="street-name-input"
              spellCheck={false}
            ></InputGroup>
          </div>
        </div>
        <div className="form-row">
          <div className="field-container-vert">
            <div>
              Region
              <Asterisk />
            </div>
            <CustomSelect items={[]} value="" onItemSelect={() => {}} />
          </div>
          <div className="field-container-vert">
            <div>
              Province
              <Asterisk />
            </div>
            <CustomSelect items={[]} value="" onItemSelect={() => {}} />
          </div>
          <div className="field-container-vert">
            <div>
              City/Municipality
              <Asterisk />
            </div>
            <CustomSelect items={[]} value="" onItemSelect={() => {}} />
          </div>
        </div>
        <div className="form-row">
          <div className="field-container-vert">
            <div>
              Brgy/District
              <Asterisk />
            </div>
            <CustomSelect items={[]} value="" onItemSelect={() => {}} />
          </div>
          <div className="field-container-vert">
            <div>
              ZIP Code
              <Asterisk />
            </div>
            <InputGroup
              className="zip-input"
              value=""
              onChange={() => {}}
            ></InputGroup>
          </div>
        </div>
        <div className="buttons-container">
          <Button intent="danger">CLEAR ALL</Button>
          <Button className="next-button" intent="primary">
            SUBMIT
          </Button>
        </div>
      </form>
    </CustomDialog>
  );
};

export default StudentRegistrationWindow;
