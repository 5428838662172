import { Button } from "@blueprintjs/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomCard from "../../../common-ui/custom-card/CustomCard";
import { getCredentialsFromLocalStorage } from "../../../utils/custom-local-storage";
import AddAccountModal from "./AddAccountModal";
import "./manage-driving-schools.scss";

const ManageDrivingSchools = () => {
  const navigation = useNavigate();
  const credentials = getCredentialsFromLocalStorage();

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (credentials?.role !== "SYSTEM-ADMIN") return <></>;
  return (
    <>
      <CustomCard
        cardHeaderText="Manage Driving Schools"
        id="manage-driving-schools-card"
      >
        <div className="manage-schools-buttons">
          <Button
            icon="application"
            onClick={() => navigation("/main/accounts")}
            large={true}
          >
            View Accounts
          </Button>
          <Button icon="add" onClick={() => setIsModalOpen(true)} large={true}>
            Add New Account
          </Button>
        </div>
      </CustomCard>
      <AddAccountModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default ManageDrivingSchools;
