import CustomCard from "../../../common-ui/custom-card/CustomCard";

const DashboardReportCard = () => {
  return (
    <CustomCard cardHeaderText="Quick Report">
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            flexDirection: "column",
          }}
        >
          <div style={{ fontWeight: 700, fontSize: 40 }}>62</div>
          <div style={{ fontSize: 20, width: 250, textAlign: "center" }}>
            Students this month
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            flexDirection: "column",
          }}
        >
          <div style={{ fontWeight: 700, fontSize: 40 }}>48</div>
          <div style={{ fontSize: 20, width: 250, textAlign: "center" }}>
            Certificate transmittals this month
          </div>
        </div>
      </div>
    </CustomCard>
  );
};

export default DashboardReportCard;
