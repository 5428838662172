import {
  Button,
  ButtonProps,
  FileInput,
  FileInputProps,
  InputGroup,
} from "@blueprintjs/core";
import { FC, useState } from "react";
import CustomDialog, {
  CustomDialogProps,
} from "../../../common-ui/custom-dialog/CustomDialog";
import { dataUritoBlob } from "../../../utils/common";
import "./AddAccountModal.scss";

export type AddAccountModalProps = {
  isOpen: boolean;
  onClose?: CustomDialogProps["onClose"];
};

const emptyForm = {
  name: "",
  logoFilename: "",
  logoBlob: new Blob(),
};

const AddAccountModal: FC<AddAccountModalProps> = (props) => {
  const [formValues, setFormValues] = useState(emptyForm);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onFileUpload: FileInputProps["onInput"] = (event) => {
    let reader = new FileReader();
    const file = (event.target as HTMLInputElement).files?.[0];
    if (!file) return;
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFormValues({
        ...formValues,
        logoBlob: dataUritoBlob(reader.result as string),
        logoFilename: file.name,
      });
      console.log(formValues);
    };
  };

  const onAddAccountButtonClick: ButtonProps["onClick"] = (event) => {
    props.onClose?.(event);
    setIsConfirmationModalOpen(true);
  };

  const onConfirmationButtonClick: ButtonProps["onClick"] = (event) => {
    setIsLoading(true);

    setTimeout(() => {
      setFormValues(emptyForm);
      setIsLoading(false);
      setIsConfirmationModalOpen(false);
    }, 1000);
  };

  const onModalClose: CustomDialogProps["onClose"] = (event) => {
    setFormValues(emptyForm);
    props.onClose?.(event);
  };

  return (
    <>
      <CustomDialog
        isOpen={props.isOpen}
        onClose={onModalClose}
        title="Add Driving School Account"
      >
        <form className="add-account-form">
          <div className="field-container-vert">
            <div>Driving School Name</div>
            <InputGroup
              value={formValues.name}
              onChange={(event) =>
                setFormValues({ ...formValues, name: event.target.value })
              }
            />
          </div>
          <div className="field-container-vert">
            <div>Logo</div>
            <FileInput
              text={formValues.logoFilename || "Choose file."}
              hasSelection={!!formValues.logoFilename}
              onInput={onFileUpload}
            />
          </div>
          <div className="buttons-container">
            <Button onClick={onModalClose}>BACK</Button>
            <Button
              intent="primary"
              type="submit"
              onClick={(event) => {
                event.preventDefault();
                onAddAccountButtonClick(event);
              }}
              disabled={
                !formValues.name ||
                !formValues.logoFilename ||
                !formValues.logoBlob
              }
            >
              ADD ACCOUNT
            </Button>
          </div>
        </form>
      </CustomDialog>
      <CustomDialog
        title="Adding Account Confirmation"
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
      >
        <span>
          Are you sure you want to add the account <b>{formValues.name}?</b>
        </span>
        <div className="buttons-container">
          <Button
            onClick={onModalClose}
            disabled={isLoading}
          >
            CANCEL
          </Button>
          <Button
            intent="primary"
            onClick={onConfirmationButtonClick}
            loading={isLoading}
          >
            ADD ACCOUNT
          </Button>
        </div>
      </CustomDialog>
    </>
  );
};

export default AddAccountModal;
