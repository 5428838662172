import { Button, Divider, H4, H5, Icon } from "@blueprintjs/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomCard from "../../../common-ui/custom-card/CustomCard";
import { getSelectValue } from "../../../utils/common";
import { naString } from "../../../utils/constants";
import { mainStorage } from "../../../utils/custom-local-storage";
import { HTTP_APIs } from "../../../utils/http-apis";
import { showHTTPResponseToaster } from "../../../utils/toaster-ui";
import { ReqStatus } from "../../../utils/typings";
import "./StudentConfirmationPage.scss";
import FingerprintCapture from "../../../common-ui/fingerprint-authentication/FingerprintCapture";
import StudentProfile from "../../../common-ui/student-profile/StudentProfile";

const StudentConfirmationPage = () => {
  const navigation = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!mainStorage.get("photo-url")) {
      navigation("/main/enroll-student/2");
    }
  }, []);

  const [reqStatus, setReqStatus] = useState<ReqStatus>("default");
  const personalInformationForm = mainStorage.get("student-profile-to-enroll");
  const imageUrl = mainStorage.get("photo-url") as string;

  const sexString =
    getSelectValue("SEX", personalInformationForm.sex) || naString;
  const statusString =
    getSelectValue("STATUS", personalInformationForm.status) || naString;

  const [isFingerprintModalOpen, setIsFingerprintModalOpen] = useState(false);

  const onSubmitButtonClick = async () => {
    setIsFingerprintModalOpen(true);
  };

  const onFormSubmission = async (fingerprints: [string, string]) => {
    setReqStatus("loading");

    const blob = await fetch(imageUrl).then((res) => res.blob());

    const theoreticalResponse = await HTTP_APIs.forms.submitStudentProfile({
      brgyDistrict: personalInformationForm.brgyDistrict.name,
      cityMunicipality: personalInformationForm.cityMunicipality.name,
      dateOfBirth: personalInformationForm.dob,
      firstName: personalInformationForm.firstName,
      houseBuilding: personalInformationForm.houseBuilding,
      image: blob,
      lastName: personalInformationForm.lastName,
      province: personalInformationForm.province.name,
      psgcAddressCode: personalInformationForm.brgyDistrict.code,
      region: personalInformationForm.region.name,
      sex: personalInformationForm.sex,
      status: personalInformationForm.status,
      streetName: personalInformationForm.streetName,
      zip: personalInformationForm.zip,
      middleName: personalInformationForm.middleName,
    });
    setReqStatus(theoreticalResponse.status);
    showHTTPResponseToaster(theoreticalResponse);

    if (theoreticalResponse.status === "success") {
      setReqStatus("success");
      navigation("/main");
      mainStorage.clear("student-profile-to-enroll");
      mainStorage.clear("photo-url");
      setIsFingerprintModalOpen(false);
    }
  };

  return (
    <>
      <CustomCard
        className="confirmation-card"
        cardHeaderText="Please Confirm Student Profile"
      >
        <StudentProfile
          data={{
            brgyDistrict: personalInformationForm.brgyDistrict.name,
            cityMunicipality: personalInformationForm.cityMunicipality.name,
            dateOfBirth: personalInformationForm.dob,
            firstName: personalInformationForm.firstName,
            houseBuilding: personalInformationForm.houseBuilding,
            lastName: personalInformationForm.lastName,
            province: personalInformationForm.province.name,
            region: personalInformationForm.region.name,
            sex: personalInformationForm.sex,
            status: personalInformationForm.status,
            streetName: personalInformationForm.streetName,
            zip: personalInformationForm.zip,
            middleName: personalInformationForm.middleName,
            imageUrl: imageUrl,
          }}
        ></StudentProfile>
        <div className="buttons-container">
          <Button
            onClick={() => navigation("/main/enroll-student/2")}
            large={true}
            disabled={reqStatus === "loading"}
          >
            BACK
          </Button>
          <Button
            intent="primary"
            large={true}
            // onClick={onSubmitButtonClick}
            onClick={() => onFormSubmission([] as any)}
            loading={reqStatus === "loading"}
          >
            SUBMIT
          </Button>
        </div>
      </CustomCard>
      <FingerprintCapture
        isOpen={isFingerprintModalOpen}
        onClose={() => setIsFingerprintModalOpen(false)}
        numberOfFingerprints={2}
        onCapture={(fingerprints) => {
          onFormSubmission(fingerprints as [string, string]);
        }}
        defaultMessage={[
          "Please press your thumb on the fingerprint scanner.",
          "Please press your index finger on the fingerprint scanner.",
        ]}
        status="default"
      ></FingerprintCapture>
    </>
  );
};

export default StudentConfirmationPage;
