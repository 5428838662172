import {
  Button,
  Divider,
  IconName,
  InputGroup,
  Radio,
  RadioGroup,
} from "@blueprintjs/core";
import { DateRange, DateRangeInput } from "@blueprintjs/datetime";
import axios from "axios";
import dayjs from "dayjs";
import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { BTFMessage, GetStudentList, ParsedCertificates } from "shared-types";
import CustomCard from "../../common-ui/custom-card/CustomCard";
import { formatDate, parseDateString } from "../../utils/common";
import { MIN_DATE } from "../../utils/constants";
import { HTTP_APIs } from "../../utils/http-apis";
import { showHTTPResponseToaster } from "../../utils/toaster-ui";
import "./ViewStudentsPage.scss";
import CustomTable from "../../common-ui/custom-table/CustomTable";
import {
  getCredentialsFromLocalStorage,
  mainStorage,
} from "../../utils/custom-local-storage";
import { useNavigate } from "react-router-dom";
import FingerprintCapture, {
  FingerprintCaptureProps,
} from "../../common-ui/fingerprint-authentication/FingerprintCapture";
import ViewStudentProfileWindow from "./ViewStudentProfileWindow";

const emptyForm = {
  name: "",
  dateFrom: null as Date | null,
  dateTo: null as Date | null,
};
const queryTypes = [
  "all",
  "no-certificate",
  "w-certificate-to-transmit",
  "advanced",
] as const;
type QueryTypes = typeof queryTypes[number];

const ViewStudentsPage = () => {
  const credentials = getCredentialsFromLocalStorage();
  const navigation = useNavigate();

  if (!credentials) {
    navigation("/main");
  }

  const isAdmin = credentials!.role === "ADMIN";

  const [queryType, setQueryType] = useState<QueryTypes>("all");
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [isTransmittalLoading, setIsTransmittalLoading] = useState(false);
  const [tableData, setTableData] = useState<
    Exclude<GetStudentList["BTF"], BTFMessage>
  >([]);
  const [formValue, setFormValue] = useState(emptyForm);
  const [isFormValid, setIsFormValid] = useState(false);
  const [emptyTableMessage, setEmptyTableMessage] = useState({
    icon: "empty" as IconName,
    message: "",
  });
  const [formToTransmit, setFormToTransmit] = useState({
    type: "",
    formId: "",
    studentId: "",
  });
  const [isFingerprintModalOpen, setIsFingerprintModalOpen] = useState(false);
  const [isStudentProfileWindowOpen, setIsStudentProfileWindowOpen] =
    useState(false);
  const [studentIdToView, setStudentIdToView] = useState(
    undefined as string | undefined
  );
  const scrollingContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    switch (queryType) {
      case "all":
      case "no-certificate":
      case "w-certificate-to-transmit":
        setIsTableDataLoading(true);

        HTTP_APIs.forms.getStudentList({ type: queryType }).then((response) => {
          setIsTableDataLoading(false);
          console.log(response);
          if (response.status === "success") {
            setTableData(response.data);
            setEmptyTableMessage({
              icon: "remove",
              message: "No results found.",
            });
          } else if (response.status === "unauthorized") {
            setTableData([]);
            setEmptyTableMessage({
              icon: "warning-sign",
              message: response.message,
            });
          } else {
            setTableData([]);
            setEmptyTableMessage({
              icon: "warning-sign",
              message: response.message,
            });
          }
          showHTTPResponseToaster(response);
        });
        setFormValue({
          name: "",
          dateFrom: null,
          dateTo: null,
        });
        break;
      case "advanced":
        setTableData([]);
        setEmptyTableMessage({
          icon: "search",
          message: "Please fill up search form to continue.",
        });
        break;
      default:
        throw new Error(`Query type ${queryType} is uncaught.`);
    }
  }, [queryType]);

  useEffect(() => {
    setIsFormValid(
      !!formValue.name || !!(formValue.dateFrom && formValue.dateTo)
    );
  }, [formValue]);

  const handleRadioGroupChange = (event: FormEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value as typeof queryType;
    setQueryType(value);
  };

  const handleDateRangeInputChange = (dateRange: DateRange) => {
    setFormValue({
      ...formValue,
      dateFrom: dateRange[0],
      dateTo: dateRange[1],
    });
  };

  const onSearchButtonClick = () => {
    setIsTableDataLoading(true);

    HTTP_APIs.forms
      .getStudentList({
        type: "advanced",
        name: formValue.name,
        dateFrom: formValue.dateFrom?.toISOString() || MIN_DATE.toISOString(),
        dateTo: formValue.dateTo?.toISOString() || new Date().toISOString(),
      })
      .then((response) => {
        setIsTableDataLoading(false);
        console.log(response);
        if (response.status === "success") {
          setTableData(response.data);
          setEmptyTableMessage({
            icon: "remove",
            message: "No results found.",
          });
        } else if (response.status === "unauthorized") {
          setEmptyTableMessage({
            icon: "warning-sign",
            message: response.message,
          });
        } else {
          setEmptyTableMessage({
            icon: "warning-sign",
            message: response.message,
          });
        }
        showHTTPResponseToaster(response);
      });
  };

  const onFingerprintCapture: FingerprintCaptureProps["onCapture"] = (
    fingerprints
  ) => {
    console.log("transmit!");
    console.log(1, formToTransmit);

    onTransmit(fingerprints as [string, string]);
  };

  const onTransmit = async (fingerprints: [string, string]) => {
    setIsTransmittalLoading(true);
    switch (formToTransmit.type) {
      case "theoretical":
        const theoreticalResponse =
          await HTTP_APIs.forms.transmitFormTheoretical({
            formId: formToTransmit.formId,
            studentId: formToTransmit.studentId,
            fingerprint_0: fingerprints[0],
            fingerprint_1: fingerprints[1],
          });

        setIsTransmittalLoading(false);
        showHTTPResponseToaster(theoreticalResponse);

        if (theoreticalResponse.status === "success") {
          const storedData = mainStorage.get("user-credentials");
          if (!storedData) throw new Error("Stored data not found");
          mainStorage.set("user-credentials", {
            ...storedData,
            remainingAmount: theoreticalResponse.data.remainingCredits,
          });

          switch (queryType) {
            case "no-certificate":
              setTableData(
                tableData.filter(
                  (data) =>
                    data.formTheoreticals.length !== 0 &&
                    data.formPracticals.length !== 0
                )
              );
              break;
            default:
              setTableData(tableData.slice());
              break;
          }
        }
        break;
      case "practical":
        const practicalResponse = await HTTP_APIs.forms.transmitFormPractical({
          formId: formToTransmit.formId,
          studentId: formToTransmit.studentId,
          fingerprint_0: fingerprints[0],
          fingerprint_1: fingerprints[1],
        });
        console.log(practicalResponse);
        setIsTransmittalLoading(false);
        showHTTPResponseToaster(practicalResponse);

        if (practicalResponse.status === "success") {
          const storedData = mainStorage.get("user-credentials");
          if (!storedData) throw new Error("Stored data not found");
          mainStorage.set("user-credentials", {
            ...storedData,
            remainingAmount: practicalResponse.data.remainingCredits,
          });

          switch (queryType) {
            case "no-certificate":
              setTableData(
                tableData.filter(
                  (data) =>
                    data.formTheoreticals.length !== 0 &&
                    data.formPracticals.length !== 0
                )
              );
              break;
            default:
              setTableData(tableData.slice());
              break;
          }
          break;
        }
    }
  };

  const viewProfileButtonClick = (studentId: string) => {
    setIsStudentProfileWindowOpen(true);
    setStudentIdToView(studentId);
  };

  return (
    <>
      <CustomCard
        className="certificate-list-card"
        cardHeaderText="Student List"
      >
        <RadioGroup
          className="radio-group"
          onChange={handleRadioGroupChange}
          selectedValue={queryType}
          disabled={isTableDataLoading || isTransmittalLoading}
        >
          <Radio value="all">All Students</Radio>
          <Radio value="no-certificate">Students w/o Certificate</Radio>
          <Radio value="w-certificate-to-transmit">
            Student with Certificate to Transmit
          </Radio>
          <Radio value="advanced">Advanced Search</Radio>
        </RadioGroup>
        <form
          className={
            "search-container" + (queryType === "advanced" ? " show" : "")
          }
        >
          <div className="field-container-vert">
            <div>Name</div>
            <InputGroup
              style={{ width: 250 }}
              disabled={queryType !== "advanced" || isTableDataLoading}
              value={formValue.name}
              onChange={(event) =>
                setFormValue({ ...formValue, name: event.target.value })
              }
            />
          </div>
          <div className="right-container">
            <div className="field-container-vert">
              <div>Date Range</div>
              <DateRangeInput
                formatDate={formatDate("MM/DD/YYYY")}
                parseDate={parseDateString("MM/DD/YYYY")}
                popoverProps={{ minimal: true, position: "bottom" }}
                startInputProps={{
                  style: { width: 110 },
                  placeholder: "MM/DD/YYYY",
                }}
                endInputProps={{
                  style: { width: 110 },
                  placeholder: "MM/DD/YYYY",
                }}
                value={[formValue.dateFrom, formValue.dateTo]}
                minDate={MIN_DATE}
                maxDate={new Date()}
                shortcuts={false}
                disabled={queryType !== "advanced" || isTableDataLoading}
                onChange={handleDateRangeInputChange}
              />
            </div>
            <div className="buttons-container">
              <Button
                disabled={
                  queryType !== "advanced" ||
                  isTransmittalLoading ||
                  isTableDataLoading
                }
                onClick={() => setFormValue(emptyForm)}
              >
                CLEAR
              </Button>
              <Button
                intent="primary"
                icon="search"
                type="submit"
                disabled={
                  !isFormValid ||
                  queryType !== "advanced" ||
                  isTransmittalLoading ||
                  isTableDataLoading
                }
                onClick={onSearchButtonClick}
              >
                SEARCH
              </Button>
            </div>
          </div>
        </form>

        <Divider className="divider" />
        <div
          className="table-container"
          ref={scrollingContainerRef}
          style={{
            overflowX: tableData.length !== 0 ? "scroll" : "hidden",
          }}
        >
          {formToTransmit.formId}
          {formToTransmit.type}
          <CustomTable
            itemsPerPage={5}
            emptyTableMessage={emptyTableMessage}
            tableData={tableData}
            loading={isTableDataLoading}
            className="view-students-table"
            onPageChange={() =>
              scrollingContainerRef.current?.scrollTo({ left: 0 })
            }
            headerRow={
              <tr>
                <th>Name</th>
                <th>Enrolled On</th>
                <th>Last Action</th>
                <th>Action</th>
              </tr>
            }
            rowRenderer={(student) => {
              return (
                <tr>
                  <td className="name-column">
                    <div className="name-container">
                      {student.firstName} {student.lastName}
                    </div>
                  </td>
                  <td className="date-column">
                    {dayjs(student.enrolledOn).format("MMM DD, YYYY hh:mm A")}
                  </td>
                  <td className="date-column">{student.lastAction}</td>
                  <td className="actions-column">
                    {credentials?.role === "INSTRUCTOR" && (
                      <>
                        <Button
                          onClick={() =>
                            viewProfileButtonClick(student.studentId)
                          }
                        >
                          VIEW PROFILE
                        </Button>
                        {student.formTheoreticals.some(
                          (form) => form.submitDate && !form.transmitDate
                        ) ? (
                          <Button
                            onClick={async () => {
                              HTTP_APIs.personnel
                                .viewCertificate({
                                  studentId: student.studentId,
                                  type: "theoretical",
                                  formId: student.formTheoreticals.find(
                                    (form) =>
                                      form.submitDate && !form.transmitDate
                                  )!.formId,
                                })
                                .then((response) => {
                                  if (response.status === "success") {
                                    const url = window.URL.createObjectURL(
                                      new Blob([response.data as string])
                                    );
                                    const link = document.createElement("a");
                                    link.href = url;
                                    link.setAttribute(
                                      "download",
                                      "download.pdf"
                                    );
                                    document.body.appendChild(link);
                                    link.click();
                                  }
                                });
                            }}
                          >
                            VIEW TDC
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              HTTP_APIs.personnel
                                .getStudentDetails({
                                  studentId: student.studentId,
                                })
                                .then((response) => {
                                  if (response.status === "success") {
                                    mainStorage.set(
                                      "student-profile-to-certify",
                                      {
                                        ...response.data,
                                        dateOfBirth: response.data.dateOfBirth,
                                        imageUrl:
                                          HTTP_APIs.personnel.getStudentImg(
                                            student.studentId
                                          ),
                                      }
                                    );
                                    mainStorage.set(
                                      "form-to-submit-type",
                                      "theoretical"
                                    );
                                    navigation("/main/submit-certificate");
                                  }
                                });
                            }}
                          >
                            SUBMIT TDC
                          </Button>
                        )}
                        {student.licenseNumber &&
                          (student.formPracticals.some(
                            (form) => form.submitDate && !form.transmitDate
                          ) ? (
                            <Button
                              onClick={async () => {
                                HTTP_APIs.personnel
                                  .viewCertificate({
                                    studentId: student.studentId,
                                    type: "practical",
                                    formId: student.formPracticals.find(
                                      (form) =>
                                        form.submitDate && !form.transmitDate
                                    )!.formId,
                                  })
                                  .then((response) => {
                                    if (response.status === "success") {
                                      const url = window.URL.createObjectURL(
                                        new Blob([response.data as string])
                                      );
                                      const link = document.createElement("a");
                                      link.href = url;
                                      link.setAttribute(
                                        "download",
                                        "download.pdf"
                                      );
                                      document.body.appendChild(link);
                                      link.click();
                                    }
                                  });
                              }}
                            >
                              VIEW PD
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                HTTP_APIs.personnel
                                  .getStudentDetails({
                                    studentId: student.studentId,
                                  })
                                  .then((response) => {
                                    if (response.status === "success") {
                                      mainStorage.set(
                                        "student-profile-to-certify",
                                        {
                                          ...response.data,
                                          dateOfBirth:
                                            response.data.dateOfBirth,
                                          imageUrl:
                                            HTTP_APIs.personnel.getStudentImg(
                                              student.studentId
                                            ),
                                        }
                                      );
                                      mainStorage.set(
                                        "form-to-submit-type",
                                        "practical"
                                      );
                                      navigation("/main/submit-certificate");
                                    }
                                  });
                              }}
                            >
                              SUBMIT PD
                            </Button>
                          ))}
                      </>
                    )}
                    {credentials?.role === "ADMIN" && (
                      <>
                        <Button
                          onClick={() =>
                            viewProfileButtonClick(student.studentId)
                          }
                        >
                          VIEW PROFILE
                        </Button>
                        {student.formTheoreticals.length !== 0 && (
                          <>
                            <Button
                              onClick={async () => {
                                HTTP_APIs.personnel
                                  .viewCertificate({
                                    studentId: student.studentId,
                                    type: "theoretical",
                                    formId: student.formTheoreticals
                                      .slice()
                                      .reverse()[0].formId,
                                  })
                                  .then((response) => {
                                    if (response.status === "success") {
                                      const url = window.URL.createObjectURL(
                                        new Blob([response.data as string])
                                      );
                                      const link = document.createElement("a");
                                      link.href = url;
                                      link.setAttribute(
                                        "download",
                                        "download.pdf"
                                      );
                                      document.body.appendChild(link);
                                      link.click();
                                    }
                                  });
                              }}
                            >
                              VIEW TDC
                            </Button>
                            {student.formTheoreticals.some(
                              (form) => form.submitDate && !form.transmitDate
                            ) && (
                              <Button
                                onClick={() => {
                                  setIsFingerprintModalOpen(true);
                                  setFormToTransmit({
                                    studentId: student.studentId,
                                    type: "theoretical",
                                    formId: student.formTheoreticals.find(
                                      (form) =>
                                        form.submitDate && !form.transmitDate
                                    )!.formId,
                                  });
                                }}
                              >
                                TRANSMIT TDC
                              </Button>
                            )}
                          </>
                        )}
                        {student.formPracticals.length !== 0 && (
                          <>
                            <Button
                              onClick={async () => {
                                HTTP_APIs.personnel
                                  .viewCertificate({
                                    studentId: student.studentId,
                                    type: "practical",
                                    formId: student.formPracticals
                                      .slice()
                                      .reverse()[0].formId,
                                  })
                                  .then((response) => {
                                    if (response.status === "success") {
                                      const url = window.URL.createObjectURL(
                                        new Blob([response.data as string])
                                      );
                                      const link = document.createElement("a");
                                      link.href = url;
                                      link.setAttribute(
                                        "download",
                                        "download.pdf"
                                      );
                                      document.body.appendChild(link);
                                      link.click();
                                    }
                                  });
                              }}
                            >
                              VIEW PD
                            </Button>
                            {student.formPracticals.some(
                              (form) => form.submitDate && !form.transmitDate
                            ) && (
                              <Button
                                onClick={() => {
                                  setIsFingerprintModalOpen(true);
                                  setFormToTransmit({
                                    studentId: student.studentId,
                                    type: "practical",
                                    formId: student.formPracticals.find(
                                      (form) =>
                                        form.submitDate && !form.transmitDate
                                    )!.formId,
                                  });
                                }}
                              >
                                TRANSMIT PD
                              </Button>
                            )}
                          </>
                        )}
                      </>
                    )}

                    {/* {student.status === "SUBMITTED" ? (
                      <Button
                        icon="upload"
                        small={true}
                        onClick={() => {
                          setFormToTransmit({
                            type: student.type,
                            formId: student.controlNo,
                          });
                          setIsFingerprintModalOpen(true);
                        }}
                        disabled={isTransmittalLoading || !isAdmin}
                      >
                        TRANSMIT
                      </Button>
                    ) : (
                      <Button
                        icon="document"
                        small={true}
                        onClick={onViewButtonClick({
                          type: student.type,
                          formId: student.controlNo,
                        })}
                        disabled={isTransmittalLoading}
                      >
                        VIEW
                      </Button>
                    )} */}
                  </td>
                </tr>
              );
            }}
          ></CustomTable>
        </div>
      </CustomCard>
      <FingerprintCapture
        isOpen={isFingerprintModalOpen}
        onClose={() => setIsFingerprintModalOpen(false)}
        numberOfFingerprints={2}
        onCapture={onFingerprintCapture}
        defaultMessage={[
          "Please press your thumb on the fingerprint scanner.",
          "Please press your index finger on the fingerprint scanner.",
        ]}
        status="default"
      ></FingerprintCapture>
      <ViewStudentProfileWindow
        isOpen={isStudentProfileWindowOpen}
        onClose={() => setIsStudentProfileWindowOpen(false)}
        studentId={studentIdToView}
      ></ViewStudentProfileWindow>
    </>
  );
};

export default ViewStudentsPage;
