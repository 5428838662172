import { Button, H4, Icon, InputGroup } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import CustomDialog from "../../../../common-ui/custom-dialog/CustomDialog";
import CustomTable, {
  CustomTableProps,
} from "../../../../common-ui/custom-table/CustomTable";
import { HTTP_APIs } from "../../../../utils/http-apis";
import { showHTTPResponseToaster } from "../../../../utils/toaster-ui";
import { useDSDetailsContext } from "../ViewDrivingSchoolPage";
import "./DSIssuingBranches.scss";

const DSIssuingBranches = () => {
  const [tableData, setTableData] = useState<{name: string; accreditationNumber: string}[]>([]);
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [emptyTableMessage, setEmptyTableMessage] = useState<
    CustomTableProps["emptyTableMessage"]
  >({
    icon: "delete",
    message: "",
  });

  const drivingSchoolId = useDSDetailsContext().id;
  const drivingSchoolName = useDSDetailsContext().name;

  const [toDeleteBranch, setToDeleteBranch] = useState("");
  const [toAddBranch, setToAddBranch] = useState({name: "", accreditationNumber: ""});

  const [isReqLoading, setIsReqLoading] = useState(false);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isAddConfirmationModalOpen, setIsAddConfirmationModalOpen] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const getIssuingBranches = async () => {
    setIsTableDataLoading(true);

    const response =
      await HTTP_APIs.systemAdmin.getDrivingSchoolIssuingBranches({
        drivingSchoolId: drivingSchoolId,
      });
    setIsTableDataLoading(false);
    showHTTPResponseToaster(response);

    if (response.status === "success") {
      setTableData(response.data);
      setEmptyTableMessage({
        icon: "delete",
        message: "No Employees found.",
      });
    } else {
      setEmptyTableMessage({
        icon: "warning-sign",
        message: "Error fetching Issuing Branches List.",
      });
    }
  };

  useEffect(() => {
    getIssuingBranches();
  }, []);

  const onAddButtonClick = () => {
    setIsAddModalOpen(false);
    setIsAddConfirmationModalOpen(true);
  };

  const onConfirmAddButtonClick = async () => {
    setIsReqLoading(true);
    const response =
      await HTTP_APIs.systemAdmin.addDrivingSchoolIssuingBranches({
        drivingSchoolId: drivingSchoolId,
        issuingBranch: toAddBranch.name,
        accreditationNumber: toAddBranch.accreditationNumber
      });
    setIsReqLoading(false);
    showHTTPResponseToaster(response);

    if (response.status === "success") {
      setTableData(response.data.updated);
      setIsAddConfirmationModalOpen(false);
    }
  };

  const onConfirmDeleteButtonClick = async () => {
    setIsReqLoading(true);
    const response =
      await HTTP_APIs.systemAdmin.deleteDrivingSchoolIssuingBranch({
        drivingSchoolId: drivingSchoolId,
        issuingBranch: toDeleteBranch,
      });
    setIsReqLoading(false);
    showHTTPResponseToaster(response);

    if (response.status === "success") {
      setTableData(response.data.updated);
      setIsDeleteModalOpen(false);
    }
  };

  return (
    <section id="ds-profile-issuing-branches">
      <H4>Issuing Branches</H4>
      <div className="options-container">
        <Button
          icon="plus"
          text="Add Branch"
          disabled={isReqLoading}
          onClick={() => setIsAddModalOpen(true)}
        />
      </div>
      <CustomTable
        className="issuing-branches-table"
        tableData={tableData}
        headerRow={
          <tr>
            <th>Branch Name</th>
            <th>Accreditation Number</th>
            <th>Actions</th>
          </tr>
        }
        rowRenderer={(item) => (
          <tr>
            <td>{item.name}</td>
            <td>{item.accreditationNumber}</td>
            <td className="center-cell">
              <Button
                small={true}
                text="DELETE"
                onClick={() => {
                  setToDeleteBranch(item.name);
                  setIsDeleteModalOpen(true);
                }}
                disabled={isReqLoading}
              />
            </td>
          </tr>
        )}
        itemsPerPage={5}
        loading={isTableDataLoading}
        emptyTableMessage={emptyTableMessage}
      />
      <CustomDialog
        style={{ width: 500 }}
        title="Add Branch"
        className="add-issuing-branch-modal"
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
      >
        <div className="field-container-vert">
          <div>Driving School Name:</div>
          <div>{drivingSchoolName}</div>
        </div>
        <div className="field-container-vert">
          <div>Branch Name:</div>
          <InputGroup
            style={{ width: 300 }}
            value={toAddBranch.name}
            onChange={(event) => setToAddBranch({...toAddBranch, name: event.target.value})}
            spellCheck={false}
          />
        </div>
        <div className="field-container-vert">
          <div>Accreditation Number:</div>
          <InputGroup
            style={{ width: 300 }}
            value={toAddBranch.accreditationNumber}
            onChange={(event) => setToAddBranch({...toAddBranch, accreditationNumber: event.target.value})}
            spellCheck={false}
          />
        </div>
        <div className="buttons-container">
          <Button onClick={() => setIsAddModalOpen(false)}>BACK</Button>
          <Button
            type="submit"
            intent="primary"
            onClick={onAddButtonClick}
            loading={isReqLoading}
            disabled={!toAddBranch.name || !toAddBranch.accreditationNumber}
          >
            ADD
          </Button>
        </div>
      </CustomDialog>
      <CustomDialog
        style={{ width: 350 }}
        title="Confirm Add Branch"
        className="add-issuing-branch-confirmation-modal"
        isOpen={isAddConfirmationModalOpen}
        onClose={() => setIsAddConfirmationModalOpen(false)}
      >
        <div>
          Are you sure you want to add issuing branch <b>{toAddBranch.name}</b>?
        </div>
        <div className="buttons-container">
          <Button onClick={() => setIsAddConfirmationModalOpen(false)}>
            BACK
          </Button>
          <Button
            intent="primary"
            onClick={onConfirmAddButtonClick}
            loading={isReqLoading}
          >
            CONFIRM
          </Button>
        </div>
      </CustomDialog>
      <CustomDialog
        style={{ width: 350 }}
        title="Delete Branch"
        className="delete-issuing-branch-modal"
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <div>
          Are you sure you want to delete issuing branch <b>{toDeleteBranch}</b>
          ?
        </div>
        <div className="buttons-container">
          <Button onClick={() => setIsDeleteModalOpen(false)}>BACK</Button>
          <Button
            intent="danger"
            onClick={onConfirmDeleteButtonClick}
            loading={isReqLoading}
          >
            DELETE
          </Button>
        </div>
      </CustomDialog>
    </section>
  );
};

export default DSIssuingBranches;
