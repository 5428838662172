import dayjs from "dayjs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomCard from "../../common-ui/custom-card/CustomCard";
import { formatName } from "../../utils/common";
import { mainStorage } from "../../utils/custom-local-storage";
import AccountDetailsCard from "../home/account-details-card/AccountDetailsCard";
import PageRedirection from "../page-redirection/PageRedirection";
import ChangePasswordModal from "./change-password-modal/ChangePasswordModal";
import "./SettingsPage.scss";

const SettingsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigation = useNavigate();

  let fullname: string;
  let dateOfBirth: string | null;
  let designation: string;
  let emailAddress: string | null;
  let username: string;

  const adminCredentials = mainStorage.get("admin-credentials");
  const userCredentials = mainStorage.get("user-credentials");

  if (adminCredentials) {
    fullname = formatName(adminCredentials, "full");
    dateOfBirth = null;
    designation = "SYSTEM ADMIN";
    emailAddress = null;
    username = adminCredentials.username;
  } else if (userCredentials) {
    fullname = formatName(userCredentials, "full");
    dateOfBirth = dayjs(userCredentials.dateOfBirth).format("MMMM DD, YYYY");
    designation = userCredentials.role;
    emailAddress = userCredentials.emailAddress;
    username = userCredentials.username;
  } else {
    console.error("No credentials found. Navigating to login page.");
    return <PageRedirection to="/" />;
  }

  return (
    <>
      <CustomCard id="settings-card" cardHeaderText="Settings">
        <div className="field-container-hor">
          <div>Full Name:</div>
          <div>{fullname}</div>
        </div>
        {dateOfBirth && (
          <div className="field-container-hor">
            <div>Date of Birth:</div>
            <div>{dateOfBirth}</div>
          </div>
        )}
        <div className="field-container-hor">
          <div>Designation:</div>
          <div>{designation}</div>
        </div>
        {emailAddress && (
          <div className="field-container-hor">
            <div>Email Address:</div>
            <div>{emailAddress}</div>
          </div>
        )}
        <div className="field-container-hor">
          <div>Username:</div>
          <div>{username}</div>
        </div>
        <ChangePasswordModal className="change-password-button" />
      </CustomCard>
      <AccountDetailsCard />
    </>
  );
};

export default SettingsPage;
