import { Button } from "@blueprintjs/core";
import dayjs from "dayjs";
import { useState } from "react";
import AddCreditsWindow from "../../../common-ui/add-credits-window/AddCreditsWindow";
import CustomCard from "../../../common-ui/custom-card/CustomCard";
import { formatNumber } from "../../../utils/common";
import { getCredentialsFromLocalStorage } from "../../../utils/custom-local-storage";

const AccountDetailsCard = () => {
  const credentials = getCredentialsFromLocalStorage();
  
  const [isAddCreditsWindowOpen, setIsAddCreditsWindowOpen] = useState(false);
  
  if (credentials?.role !== "ADMIN") return <></>;


  return (
      <>
    <CustomCard className="card" cardHeaderText="Account Details">
      <div className="details">
        <div className="field-container-hor">
          <span>Number of Transactions Left:</span>
          <span>N/A</span>
        </div>
        <div className="field-container-hor">
          <span style={{display: "flex", alignItems: 'center'}}>Remaining Credits:</span>
          <span>
            <span style={{marginRight: 24}}>
            {formatNumber(credentials.data.remainingAmount, "currency")}
            </span>
            <Button icon="add" onClick={() => setIsAddCreditsWindowOpen(true)}>Add Credits</Button>
          </span>
        </div>
        <div className="field-container-hor">
          <span>Last Deposit Date:</span>
          <span>
            {dayjs(credentials.data.lastDepositDate).format(
              "MMMM DD, YYYY hh:mm A"
            )} ({formatNumber(credentials.data.lastDepositAmount, 'currency')})
          </span>
        </div>
      </div>
    </CustomCard>
            <AddCreditsWindow isOpen={isAddCreditsWindowOpen} onClose={()=>setIsAddCreditsWindowOpen(false)}></AddCreditsWindow>
    </>
  );
};

export default AccountDetailsCard;
