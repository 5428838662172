import { Button, H4, IconName } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { PersonnelItem } from "shared-types";
import CustomTable, {
  CustomTableProps,
} from "../../../../common-ui/custom-table/CustomTable";
import { HTTP_APIs } from "../../../../utils/http-apis";
import { showHTTPResponseToaster } from "../../../../utils/toaster-ui";
import { useDSDetailsContext } from "../ViewDrivingSchoolPage";
import "./DSPersonnels.scss";

const DSPersonnels = () => {
  const [tableData, setTableData] = useState<PersonnelItem[]>([]);
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [emptyTableMessage, setEmptyTableMessage] = useState<
    CustomTableProps["emptyTableMessage"]
  >({
    icon: "delete",
    message: "",
  });

  const drivingSchoolId = useDSDetailsContext().id;

  const getPersonnels = async () => {
    setIsTableDataLoading(true);

    const response = await HTTP_APIs.systemAdmin.getDrivingSchoolPersonnels({
      drivingSchoolId: drivingSchoolId,
    });
    setIsTableDataLoading(false);
    showHTTPResponseToaster(response);

    if (response.status === "success") {
      setTableData(response.data);
      setEmptyTableMessage({
        icon: "delete",
        message: "No Employees found.",
      });
    } else {
      setEmptyTableMessage({
        icon: "warning-sign",
        message: "Error fetching Employee List.",
      });
    }
  };

  useEffect(() => {
    getPersonnels();
  }, []);

  return (
    <section id="ds-profile-personnels">
      <H4>Personnels</H4>
      <div className="options-container">
        <Button icon="plus" text="Add Profile" />
      </div>
      <CustomTable
        className="personnels-table"
        tableData={tableData}
        headerRow={
          <tr>
            <th>Name</th>
            <th>Username</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        }
        rowRenderer={(item) => (
          <tr>
            <td>{item.name}</td>
            <td>{item.username}</td>
            <td>{item.role}</td>
            <td>
              <Button small={true} text="VIEW PROFILE" />
            </td>
          </tr>
        )}
        itemsPerPage={5}
        loading={isTableDataLoading}
        emptyTableMessage={emptyTableMessage}
      />
    </section>
  );
};

export default DSPersonnels;
