import { Icon, Spinner } from "@blueprintjs/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RecentActivities } from "shared-types";
import CustomCard from "../../../common-ui/custom-card/CustomCard";
import CustomNotification from "../../../common-ui/notification/CustomNotification";
import { getCredentialsFromLocalStorage } from "../../../utils/custom-local-storage";
import { HTTP_APIs } from "../../../utils/http-apis";
import { ReqStatus } from "../../../utils/typings";
import "./RecentActivitiesCard.scss";

const RecentActivitiesCard = () => {
  const credentials = getCredentialsFromLocalStorage();
  const [reqStatus, setReqStatus] = useState<ReqStatus>("default");
  const [recentActivities, setRecentctivities] = useState<RecentActivities>([]);

  useEffect(() => {
    if (credentials?.role === "SYSTEM-ADMIN") return;

    setReqStatus("loading");

    HTTP_APIs.forms.getRecentActivities({}).then((response) => {
      if (response.status === "success") {
        setRecentctivities(response.data.reverse());
        setReqStatus("success");
      } else if (response.status === "unauthorized") {
        setReqStatus("unauthorized");
      } else {
        setReqStatus("error-unknown");
      }
    });
  }, []);

  if (credentials?.role === "SYSTEM-ADMIN") return <></>;

  return (
    <CustomCard
      className="card"
      id="recent-activities-card"
      cardHeaderText="Recent Activities"
    >
      {reqStatus === "loading" && <Spinner />}
      {reqStatus === "unauthorized" && (
        <CustomNotification
          icon="warning-sign"
          message="User unauthorized."
          color="red"
        />
      )}
      {reqStatus === "error-unknown" && (
        <CustomNotification
          icon="warning-sign"
          message="Error fetching data."
          color="red"
        />
      )}
      {reqStatus === "success" && (
        <div className="table-container">
          <table className="activities-table">
            {recentActivities.map((log) => (
              <tr>
                <td>{dayjs(log.date).format("hh:mm A")}</td>
                <td>{log.message}</td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td></td>
            </tr>
          </table>
          {recentActivities.length === 0 && (
            <CustomNotification
              className="no-activities"
              icon="remove"
              message="No activities yet."
            />
          )}
        </div>
      )}

      {/* <Link to="/main/certificates">View more activities</Link> */}
    </CustomCard>
  );
};

export default RecentActivitiesCard;
