import { Button, Dialog, InputGroup, Label } from "@blueprintjs/core";
import { useState, ChangeEvent, useEffect, FC } from "react";
import CustomDialog from "../../common-ui/custom-dialog/CustomDialog";
import { getToaster } from "../../utils/toaster-ui";
import "./ForgotCredentialsModal.css";

type ModalProps = {
  disabled: boolean;
};

const ForgotCredentialsModal: FC<ModalProps> = ({ disabled }) => {
  const [isMainModalOpen, setIsMainModalOpen] = useState(false);
  const [isConfimationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function closeMainModal() {
    setIsMainModalOpen(false);
  }

  function closeConfirmationModal() {
    setIsConfirmationModalOpen(false);
  }

  function proceedToConfirmation() {
    setIsMainModalOpen(false);
    setIsConfirmationModalOpen(true);
  }

  function handleEmail(event: ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
    setIsEmailValid(
      // Source: https://www.w3resource.com/javascript/form/email-validation.php
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)
    );
  }

  async function submitEmailAsync() {
    let promise = new Promise<string>((resolve, reject) => {
      setIsLoading(true);

      setTimeout(() => {
        setIsLoading(false);
        setIsConfirmationModalOpen(false);
        resolve("I am a done promise!");
        const toaster = getToaster();
        toaster.show({
          message: (
            <>
              Sent confirmation email to <b>{email}</b>
            </>
          ),
          intent: "success",
          icon: "tick",
          timeout: 3000,
        });
      }, 3000);
    });
    console.log(await promise);
  }

  useEffect(() => {
    if (isMainModalOpen) {
      setEmail("");
      setIsEmailValid(false);
    }
  }, [isMainModalOpen]);

  return (
    <>
      <Button
        className="forgot-password form-button"
        minimal={true}
        onClick={() => setIsMainModalOpen(!isMainModalOpen)}
        disabled={disabled}
      >
        Forgot Username/Password?
      </Button>
      <CustomDialog
        isOpen={isMainModalOpen}
        canOutsideClickClose={true}
        onClose={closeMainModal}
        title="Forgot Username/Password?"
      >
        <p className="instructions">
          Please input the email address you used to register in the LTO
          {/* TODO: Need to refine the wording of the instruction. */}
        </p>
        <form className="email-form">
          <Label className="large-label">
            Email Address
            <InputGroup
              placeholder="Enter Email Address"
              value={email}
              large={true}
              onChange={handleEmail}
              disabled={isLoading}
            ></InputGroup>
          </Label>
          <div className="buttons-container">
            <Button intent={"none"} large={true} onClick={closeMainModal}>
              BACK
            </Button>
            <Button
              disabled={!isEmailValid}
              intent={"primary"}
              large={true}
              onClick={proceedToConfirmation}
            >
              OK
            </Button>
            <p>{isEmailValid}</p>
          </div>
        </form>
      </CustomDialog>
      <CustomDialog
        isOpen={isConfimationModalOpen}
        canOutsideClickClose={true}
        onClose={closeConfirmationModal}
        title="Confirmation"
      >
        <div className="modal-window-container">
          <p className="confirmation-text">
            Are you sure you want to send the email to <b>{email}</b>?
          </p>
          <div className="buttons-container">
            <Button
              intent={"none"}
              large={true}
              onClick={closeConfirmationModal}
            >
              BACK
            </Button>
            <Button
              disabled={!isEmailValid}
              intent={"primary"}
              large={true}
              onClick={submitEmailAsync}
              loading={isLoading}
            >
              SUBMIT
            </Button>
            <p>{isEmailValid}</p>
          </div>
        </div>
      </CustomDialog>
    </>
  );
};

export default ForgotCredentialsModal;
