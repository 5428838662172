import { FC, useEffect, useState } from "react";
import CustomDialog, {
  CustomDialogProps,
} from "../../common-ui/custom-dialog/CustomDialog";
import StudentProfile, {
  StudentProfileProps,
} from "../../common-ui/student-profile/StudentProfile";
import { HTTP_APIs } from "../../utils/http-apis";

export type ViewStudentProfileWindowProps = {
  studentId?: string;
  isOpen: CustomDialogProps["isOpen"];
  onClose: CustomDialogProps["onClose"];
};

const emptyStudentData: StudentProfileProps["data"] = {
  brgyDistrict: "",
  cityMunicipality: "",
  dateOfBirth: "",
  firstName: "",
  houseBuilding: "",
  imageUrl: "",
  lastName: "",
  province: "",
  region: "",
  sex: "",
  status: "",
  streetName: "",
  zip: "",
};
const ViewStudentProfileWindow: FC<ViewStudentProfileWindowProps> = (props) => {
  const [studentData, setStudentData] =
    useState<StudentProfileProps["data"]>(emptyStudentData);

  useEffect(() => {
    setStudentData(emptyStudentData);

    if (props.studentId) {
      HTTP_APIs.personnel
        .getStudentDetails({ studentId: props.studentId })
        .then((response) => {
          if (response.status === "success") {
            setStudentData({
              ...response.data,
              dateOfBirth: response.data.dateOfBirth,
              imageUrl: HTTP_APIs.personnel.getStudentImg(props.studentId!),
            });
          }
        });
    }
  }, [props.studentId]);
  return (
    <CustomDialog
      title="Student Profile"
      isOpen={props.isOpen}
      onClose={props.onClose}
      style={{ width: "70vw", maxWidth: "650px" }}
    >
      <StudentProfile data={studentData}></StudentProfile>
    </CustomDialog>
  );
};

export default ViewStudentProfileWindow;
