import { AuthenticateAdmin, AuthenticateUser, ChangePassword, GetDashboardReport, GetStudentDetails, RegisterInstructor, ViewRecord } from 'shared-types';
import { processInternalAPI } from './shared';

const loginApis = {
  authenticateUser: async (reqObj: AuthenticateUser['FTB']) => (
    processInternalAPI<AuthenticateUser['BTF']>({
      method: 'POST',
      path: '/api/auth',
      reqBody: reqObj,
      withCredentials: true
    })
  ),
  authenticateAdmin: async (reqObj: AuthenticateAdmin['FTB']) => (
    processInternalAPI<AuthenticateAdmin['BTF']>({
      method: 'POST',
      path: '/api/auth-admin',
      reqBody: reqObj,
      withCredentials: true
    })
  ),
  registerInstructor: async (reqObj: RegisterInstructor['FTB']) => (
    processInternalAPI<RegisterInstructor['BTF']>({
      method: 'POST',
      path: '/api/instructor/register',
      reqBody: reqObj,
      withCredentials: true
    })
  ),
  getDashboardReport: async () => (
    processInternalAPI<GetDashboardReport['BTF']>({
      method: 'GET',
      path: `/api/dashboard-report`,
      withCredentials: true
    })
  ),
  getStudentDetails: async (reqObj: GetStudentDetails['FTB']) => (
    processInternalAPI<GetStudentDetails['BTF']>({
      method: 'GET',
      path: `/api/student/${reqObj.studentId}/details`,
      withCredentials: true
    })
  ),
  viewCertificate: async (reqObj: ViewRecord['FTB']) => (
    processInternalAPI<ViewRecord['BTF']>({
      method: 'GET',
      path: `/api/forms/pdf`,
      reqQuery: reqObj,
      withCredentials: true,
      config: { headers: { Accept: 'application/pdf', }, responseType: 'arraybuffer' }
    })
  ),
  getStudentImg: (studentId: string) => `/api/student/${studentId}/img`,

  changeUserPassword: async (reqObj: ChangePassword['FTB']) => (
    processInternalAPI<ChangePassword['BTF']>({
      method: 'POST',
      path: '/api/change-password',
      reqBody: reqObj,
      withCredentials: true
    })
  ),
  changeAdminPassword: async (reqObj: ChangePassword['FTB']) => (
    processInternalAPI<ChangePassword['BTF']>({
      method: 'POST',
      path: '/api/change-password-admin',
      reqBody: reqObj,
      withCredentials: true
    })
  ),
  logout: async () => (
    processInternalAPI({
      method: 'POST',
      path: '/api/logout',
      withCredentials: true
    })
  ),
};

export default loginApis;