import { Button } from "@blueprintjs/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomCard from "../../../common-ui/custom-card/CustomCard";
import { getCredentialsFromLocalStorage } from "../../../utils/custom-local-storage";
import StudentRegistrationWindow from "../StudentRegistrationWindow";
import InstructorRegistrationWindow from "./InstructorRegistrationWindow";

const AddInstructorCard = () => {
  const credentials = getCredentialsFromLocalStorage();
  const navigation = useNavigate();
  const [isModalOpen, setIsModelOpen] = useState(false);
  const [test, setTest] = useState(false);

  if (credentials?.role !== "ADMIN") return <></>;
  return (
    <>
      <CustomCard cardHeaderText="Add Students / Instructor">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button icon="add" large={true} onClick={() => setIsModelOpen(true)}>
            Add Instructor
          </Button>
          <Button
            style={{ marginLeft: 40 }}
            icon="add"
            large={true}
            onClick={() => navigation("/main/enroll-student")}
          >
            Enroll Student
          </Button>
        </div>
      </CustomCard>
      <InstructorRegistrationWindow
        isOpen={isModalOpen}
        onClose={() => setIsModelOpen(false)}
      />
      <StudentRegistrationWindow isOpen={test} onClose={() => setTest(false)} />
    </>
  );
};

export default AddInstructorCard;
