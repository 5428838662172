import { Icon } from "@blueprintjs/core";
import dayjs from "dayjs";
import { FC } from "react";
import { getSelectValue } from "../../utils/common";
import "./StudentProfile.scss";

export type StudentProfileProps = {
  data: {
    firstName: string;
    middleName?: string;
    lastName: string;
    licenseNumber?: string;
    dateOfBirth: string;
    sex: string;
    status: string;
    zip: string;
    houseBuilding: string;
    streetName: string;
    brgyDistrict: string;
    cityMunicipality: string;
    province: string;
    region: string;
    imageUrl: string;
  };
};
const naString = "N/A";

const StudentProfile: FC<StudentProfileProps> = (params) => {
  const sexString = getSelectValue("SEX", params.data.sex) || naString;
  const statusString = getSelectValue("STATUS", params.data.status) || naString;

  return (
    <div className="student-profile">
      <div className="student-details">
        <div className="form-row">
          <div className="field-container-vert">
            <div>First Name</div>
            <div>{params.data.firstName || naString}</div>
          </div>
          <div className="field-container-vert">
            <div>Middle Name</div>
            <div>{params.data.middleName || naString}</div>
          </div>
          <div className="field-container-vert">
            <div>Last Name</div>
            <div>{params.data.lastName || naString}</div>
          </div>
        </div>
        <div className="form-row">
          <div className="field-container-vert">
            <div>License Number</div>
            <div>{params.data.licenseNumber || naString}</div>
          </div>
          <div className="field-container-vert">
            <div>Date of Birth</div>
            <div>
              {dayjs(params.data.dateOfBirth).format("MMMM DD, YYYY") || naString}
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="field-container-vert">
            <div>Sex</div>
            <div>{sexString || naString}</div>
          </div>
          <div className="field-container-vert">
            <div>Marital Status</div>
            <div>{statusString || naString}</div>
          </div>
        </div>
        <div className="form-row">
          <div className="field-container-vert">
            <div>Address</div>
            <div>
              <div className="address-line-1">
                {`${params.data.houseBuilding}, ${params.data.streetName}`}
              </div>
              <div className="address-line-2">
                {`${params.data.brgyDistrict}, ${params.data.cityMunicipality}, ${params.data.province}`}
              </div>
              <div className="address-line-2">
                {`${params.data.region} ${params.data.zip}`}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="student-photo-container">
        <div className="placeholder">
          <Icon icon="user" size={120}></Icon>
        </div>
        <img
          className="img"
          draggable="false"
          src={params.data.imageUrl || undefined}
        ></img>
      </div>
    </div>
  );
};

export default StudentProfile;
