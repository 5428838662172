import { Card, CardProps, Divider, H3 } from "@blueprintjs/core";
import { FC, ReactElement } from "react";
import "./CustomCard.scss";

type CustomCardProps = Omit<CardProps, "elevation"> & {
  cardHeaderText?: string;
  rightElement?: ReactElement
};

const CustomCard: FC<CustomCardProps> = (props) => {
  const { style, ...propsWoStyle } = props;
  return (
    <Card {...propsWoStyle} elevation={2} style={{ ...style, minWidth: 600 }}>
      {(props.cardHeaderText || props.rightElement) && (
        <>
          <div className="custom-card-header">
          <H3 className="custom-card-header-text">{props.cardHeaderText}</H3>
          {props.rightElement}
          </div>
          <Divider className="custom-card-divider"></Divider>
        </>
      )}
      {props.children}
    </Card>
  );
};

export default CustomCard;
