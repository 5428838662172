import { Button, Checkbox, Radio, RadioGroup } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomCard from "../../../common-ui/custom-card/CustomCard";
import { DL_RESTRICTIONS } from "lto-regulations";
import {
  getCredentialsFromLocalStorage,
  mainStorage,
} from "../../../utils/custom-local-storage";
import "./DLRestrictionsPage.scss";
import NestedTableRow from "./NestedTableRow";
import RootTableRow from "./RootTableRow";
import PageRedirection from "../../page-redirection/PageRedirection";
import { formatName } from "../../../utils/common";

const DLRestrictionsPage = () => {
  const credentials = getCredentialsFromLocalStorage();
  const navigation = useNavigate();
  const studentProfile = mainStorage.get("student-profile-to-certify")!;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (credentials?.role !== "INSTRUCTOR") {
      navigation("/main");
    }

    if (!studentProfile) {
      navigation("/main");
    }

    const personalInformationForm = mainStorage.get("form-to-submit-type");
    if (personalInformationForm === "theoretical") {
      navigation("/main/submit-certificate/2");
    }
  }, []);

  const [formValues, setFormValues] = useState(
    mainStorage.get("dl-restrictions-form")
  );
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const computedFormValidity =
      formValues.every((DLCodeObj) => {
        if (DLCodeObj.vehicleCategories.length === 0) return false;

        return DLCodeObj.vehicleCategories.every((vehicleCategory) => {
          if (!["PRO", "NON-PRO"].includes(vehicleCategory.DLClassification))
            return false;
          if (!["AT", "MT"].includes(vehicleCategory.clutch)) return false;
          return true;
        });
      }) && formValues.length !== 0;

    setIsFormValid(computedFormValidity);
  }, [formValues]);

  const onPreviousButtonClick = () => {
    navigation("/main/student-list");
  };

  const onNextButtonClick = () => {
    mainStorage.set("dl-restrictions-form", formValues);
    navigation("/main/submit-certificate/2");
  };

  if (credentials?.role !== "INSTRUCTOR") return <PageRedirection to="/main" />;

  return (
    <>
      <CustomCard
        className="dl-license-classification-card"
        cardHeaderText={`Driver's License Restrictions (${formatName(
          studentProfile,
          "fl"
        )})`}
      >
        <table className="dl-license-classification-table">
          <tr>
            <th>DL Codes with Vehicle Category</th>
            <th className="table-border-left table-border-top table-border-bottom">
              NON-PRO
            </th>
            <th className="table-border-top table-border-right table-border-bottom">
              PRO
            </th>
            <th className="table-border-top table-border-bottom">AT</th>
            <th className="table-border-top table-border-right table-border-bottom">
              MT
            </th>
          </tr>
          {DL_RESTRICTIONS.map((constRoot) => {
            const DLCodeFormObj = formValues.find(
              (formElement) => formElement.DLCode === constRoot.DLCode
            );
            return (
              <>
                <RootTableRow
                  code={constRoot.DLCode}
                  description={constRoot.description}
                  disabled={constRoot.disabled}
                  checked={!!DLCodeFormObj}
                  onChange={(event) => {
                    const checked = (event.target as HTMLInputElement).checked;

                    if (checked) {
                      setFormValues(
                        formValues.concat([
                          {
                            DLCode: constRoot.DLCode,
                            vehicleCategories: [],
                          },
                        ])
                      );
                    } else {
                      setFormValues(
                        formValues.filter(
                          (formElement) =>
                            formElement.DLCode !== constRoot.DLCode
                        )
                      );
                    }
                  }}
                />
                {constRoot.vehicleCategories.map((constElement) => {
                  const vehicleCategoryFormObj =
                    DLCodeFormObj?.vehicleCategories.find(
                      (formElement) =>
                        formElement.category === constElement.category
                    ) ?? null;
                  return (
                    <NestedTableRow
                      code={constElement.category}
                      description={constElement.description}
                      disabled={constElement.disabled || !DLCodeFormObj}
                      value={{
                        checked: !!vehicleCategoryFormObj,
                        DLClassification:
                          vehicleCategoryFormObj?.DLClassification || "",
                        clutch: vehicleCategoryFormObj?.clutch || "",
                      }}
                      onChange={(value) => {
                        if (value.checked) {
                          const updatedVehicleCategoryFormObj = {
                            category: constElement.category,
                            DLClassification: value.DLClassification,
                            clutch: value.clutch,
                          };
                          const updatedVehicleCategoriesFormArray =
                            DLCodeFormObj!.vehicleCategories
                              .filter(
                                (formElement) =>
                                  formElement.category !== constElement.category
                              )
                              .concat([updatedVehicleCategoryFormObj]);
                          const updatedDLCodeFormObject: typeof DLCodeFormObj =
                            {
                              DLCode: DLCodeFormObj!.DLCode,
                              vehicleCategories:
                                updatedVehicleCategoriesFormArray,
                            };
                          setFormValues(
                            formValues
                              .filter(
                                (element) =>
                                  element.DLCode !== DLCodeFormObj?.DLCode
                              )
                              .concat([updatedDLCodeFormObject])
                          );
                        } else {
                          const updatedVehicleCategoriesFormArray =
                            DLCodeFormObj!.vehicleCategories.filter(
                              (formElement) =>
                                formElement.category !== constElement.category
                            );
                          const updatedDLCodeFormObject: typeof DLCodeFormObj =
                            {
                              DLCode: DLCodeFormObj!.DLCode,
                              vehicleCategories:
                                updatedVehicleCategoriesFormArray,
                            };
                          setFormValues(
                            formValues
                              .filter(
                                (element) =>
                                  element.DLCode !== DLCodeFormObj?.DLCode
                              )
                              .concat([updatedDLCodeFormObject])
                          );
                        }
                      }}
                    />
                  );
                })}
              </>
            );
          })}
        </table>
        <div className="dl-classification-buttons-container buttons-container">
          <Button large={true} onClick={onPreviousButtonClick}>
            PREVIOUS
          </Button>
          <Button
            intent="primary"
            large={true}
            onClick={onNextButtonClick}
            disabled={!isFormValid}
          >
            NEXT
          </Button>
        </div>
      </CustomCard>
    </>
  );
};

export default DLRestrictionsPage;
