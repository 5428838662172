import { OverlayableProps, Spinner } from "@blueprintjs/core";
import { useEffect, useRef, useState } from "react";
import { COLORS } from "../../utils/constants/CSS-values";
import { FingerprintSDK } from "../../utils/fingerprint-sdk";
import CustomDialog from "../custom-dialog/CustomDialog";
import { ReactComponent as FingerprintSVG } from "./fingerprint.svg";
import "./FingerprintAuthentication.scss";

export type FingerprintCaptureProps = {
  numberOfFingerprints: number;
  acquisitionType?: 1 | 2 | 4;
  isOpen: boolean;
  onClose: OverlayableProps["onClose"];
  onCapture: (event: string[]) => void;
  successMessage?: string;
  defaultMessage?: string[];
  status: "default" | "loading" | "fail" | "success";
};

type Status = "disconnected" | "connected" | "error";

const FingerprintCapture = (props: FingerprintCaptureProps) => {
  const [status, setStatus] = useState<Status>("disconnected");
  const [message, setMessage] = useState("");
  const [iconColor, setIconColor] = useState("");
  const [animateIcon, setAnimateIcon] = useState(false);
  const [areScriptsLoaded, setAreScriptsLoaded] = useState(false);

  let fingerprintSDKRef = useRef<FingerprintSDK>(null);
  const [fingerprintEvents, setFingerprintEvents] = useState<any[]>([]);

  useEffect(() => {
    const interval = setInterval(() => {
      const script1 = document.getElementById("fingerprint-sdk-1");
      const script2 = document.getElementById("fingerprint-sdk-2");

      if (script1?.className !== "loaded" || script2?.className !== "loaded")
        return;
      setAreScriptsLoaded(true);
      clearInterval(interval);
    }, 200);
  }, []);

  useEffect(() => {
    if (!areScriptsLoaded) return;
    // @ts-ignore
    // prettier-ignore
    fingerprintSDKRef.current = new Fingerprint.WebApi

    fingerprintSDKRef.current!.onDeviceConnected = async () => {
      console.log("device connected");
      setStatus("connected");
    };

    fingerprintSDKRef.current!.onDeviceDisconnected = async () => {
      const devices = await fingerprintSDKRef.current?.enumerateDevices();
      console.log("device disconnected");

      if (!devices?.length) {
        setStatus("disconnected");
      }
    };

    fingerprintSDKRef.current!.onErrorOccurred = () => setStatus("error");
  }, [areScriptsLoaded]);

  if (fingerprintSDKRef.current) {
    fingerprintSDKRef.current.onSamplesAcquired = (event) => {
      const newFingerPrintEvents = fingerprintEvents.concat([event]);
      setFingerprintEvents(newFingerPrintEvents);
      setAnimateIcon(true);

      if (fingerprintEvents.length + 1 < props.numberOfFingerprints) return;
      fingerprintSDKRef.current!.stopAcquisition();

      const fingerprints = newFingerPrintEvents.map((data) =>
        // @ts-ignore
        Fingerprint.b64UrlTo64(JSON.parse(data.samples)[0].Data)
      );
      props.onCapture(fingerprints);
      // props.onCapture(["123", "234"]);
      props.onClose?.({} as any);
      setFingerprintEvents([]);
    };
  }

  useEffect(() => {
    if (props.isOpen) {
      openFingerprintAsync();
      setFingerprintEvents([]);

      // DEBUGGING:
      setTimeout(() => {
        props.onCapture(["dwe", "few"]);
        props.onClose?.(null as any);
      }, 1000);
    } else {
      fingerprintSDKRef.current?.stopAcquisition();
    }
  }, [props.isOpen]);

  const openFingerprintAsync = async () => {
    const devices = await fingerprintSDKRef.current?.enumerateDevices();
    await fingerprintSDKRef.current!.startAcquisition(
      props.acquisitionType || 2
    );
  };

  useEffect(() => {
    switch (status) {
      case "connected":
        setIconColor(COLORS.BLACK);
        setMessage(
          props.defaultMessage?.[fingerprintEvents.length] ||
            "Finger scanner ready. Please scan finger."
        );
        break;
      case "disconnected":
        setIconColor(COLORS.BLACK_OPAQUE);
        setMessage(
          "Finger scanner not found. Please check if properly connected."
        );
        break;
      case "error":
        setIconColor(COLORS.RED);
        setMessage("ERROR");
        break;
    }
  }, [status, fingerprintEvents.length]);
  return (
    <CustomDialog
      isOpen={props.isOpen}
      onClose={(event) => {
        setFingerprintEvents([]);
        props.onClose?.(event);
      }}
      className="fingerprint-window"
      title="Fingerprint Authentication"
      style={{ width: 370 }}
    >
      {props.status === "default" && (
        <>
          <div className="fingerprint-icon-container">
            <FingerprintSVG
              className="icon"
              width={60}
              fill={iconColor}
            ></FingerprintSVG>
            <FingerprintSVG
              className={"icon " + (animateIcon ? "pulse" : "")}
              width={60}
              style={{ transition: "ease 0.2s" }}
              onAnimationEnd={() => {
                setAnimateIcon(false);
              }}
              fill={iconColor}
            ></FingerprintSVG>
          </div>
          <div className="message">
            ({(fingerprintEvents.length || 0) + 1} of{" "}
            {props.numberOfFingerprints}) {message}
          </div>
        </>
      )}
      {props.status === "loading" && (
        <>
          <Spinner />
          <div className="message">Loading...</div>
        </>
      )}
      {props.status === "success" && (
        <>
          <div className="fingerprint-icon-container">
            <FingerprintSVG
              className="icon"
              width={60}
              style={{ transition: "ease 0.2s" }}
              fill={COLORS.GREEN}
            ></FingerprintSVG>
          </div>
          <div className="message success-message">
            {props.successMessage || "Successfully captured fingerprints."}
          </div>
        </>
      )}
    </CustomDialog>
  );
};

export default FingerprintCapture;
