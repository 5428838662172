import { Button, Divider, H4, H5, Icon } from "@blueprintjs/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomCard from "../../../common-ui/custom-card/CustomCard";
import { getSelectValue } from "../../../utils/common";
import { naString } from "../../../utils/constants";
import { DL_RESTRICTIONS } from "lto-regulations";
import {
  getCredentialsFromLocalStorage,
  mainStorage,
} from "../../../utils/custom-local-storage";
import { HTTP_APIs } from "../../../utils/http-apis";
import { showHTTPResponseToaster } from "../../../utils/toaster-ui";
import { ReqStatus } from "../../../utils/typings";
import "./ConfirmationPage.scss";
import PageRedirection from "../../page-redirection/PageRedirection";
import FingerprintCapture from "../../../common-ui/fingerprint-authentication/FingerprintCapture";
import StudentProfile from "../../../common-ui/student-profile/StudentProfile";

const ConfirmationPage = () => {
  const navigation = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const { drivingCourseName } = mainStorage.get("course-details-form");

    if (!drivingCourseName) {
      navigation("/main/submit-certificate/2");
    }
  }, []);

  const credentials = getCredentialsFromLocalStorage();

  const [reqStatus, setReqStatus] = useState<ReqStatus>("default");
  const certificateType = mainStorage.get("form-to-submit-type");
  const studentProfile = mainStorage.get("student-profile-to-certify");
  const imageUrl = mainStorage.get("photo-url") as string;
  const DLRestrictionsForm = mainStorage.get("dl-restrictions-form");
  const courseDetailForm = mainStorage.get("course-details-form");

  if (!studentProfile) {
    navigation("/main");
  }

  const programTypeString =
    getSelectValue("PROGRAM_TYPES", certificateType) || naString;
  const trainingPurposeString =
    getSelectValue(
      certificateType === "theoretical"
        ? "THEORETICAL_TRAINING_PURPOSE"
        : "PRACTICAL_TRAINING_PURPOSE",
      courseDetailForm.trainingPurpose
    ) || naString;

  const [isFingerprintModalOpen, setIsFingerprintModalOpen] = useState(false);

  const onButtonClick = async () => {
    setIsFingerprintModalOpen(true);
  };

  const onFormSubmission = async (fingerprints: [string, string]) => {
    console.log("these are the fingerprints: ", fingerprints)
    setReqStatus("loading");

    switch (certificateType) {
      case "theoretical":
        const theoreticalResponse = await HTTP_APIs.forms.submitFormTheoretical(
          {
            studentId: studentProfile!.studentId,
            trainingPurpose: trainingPurposeString,
            issuingBranch: courseDetailForm.issuingBranch,
            drivingCourseName: courseDetailForm.drivingCourseName,
            dateStarted: courseDetailForm.dateStarted,
            dateCompleted: courseDetailForm.dateCompleted,
            noOfHours: parseFloat(courseDetailForm.noOfHours),
            assessment: courseDetailForm.assessment as "PASSED" | "FAILED",
            rating: courseDetailForm.rating as "PASSED" | "FAILED",
            notes: courseDetailForm.comments,
            fingerprint_0: fingerprints[0],
            fingerprint_1: fingerprints[1],
          }
        );
        setReqStatus(theoreticalResponse.status);
        showHTTPResponseToaster(theoreticalResponse);

        if (theoreticalResponse.status === "success") {
          setReqStatus("success");
          navigation("/main");
          mainStorage.clear("student-profile-to-certify");
          mainStorage.clear("dl-restrictions-form");
          mainStorage.clear("photo-url");
          mainStorage.clear("course-details-form");
          setIsFingerprintModalOpen(false);
        }
        break;
      case "practical":
        const practicalResponse = await HTTP_APIs.forms.submitFormPractical({
          studentId: studentProfile!.studentId,
          trainingPurpose: trainingPurposeString,
          DLRestrictions: DLRestrictionsForm,
          issuingBranch: courseDetailForm.issuingBranch,
          drivingCourseName: courseDetailForm.drivingCourseName,
          dateStarted: courseDetailForm.dateStarted,
          dateCompleted: courseDetailForm.dateCompleted,
          noOfHours: parseFloat(courseDetailForm.noOfHours),
          assessment: courseDetailForm.assessment as "PASSED" | "FAILED",
          rating: courseDetailForm.rating as "PASSED" | "FAILED",
          dateOfBirth: studentProfile!.dateOfBirth,
          notes: courseDetailForm.comments,
          fingerprint_0: fingerprints[0],
          fingerprint_1: fingerprints[1],
        });
        setReqStatus(practicalResponse.status);
        showHTTPResponseToaster(practicalResponse);
        if (practicalResponse.status === "success") {
          setReqStatus("success");
          navigation("/main");
          mainStorage.clear("student-profile-to-certify");
          mainStorage.clear("dl-restrictions-form");
          mainStorage.clear("photo-url");
          mainStorage.clear("course-details-form");
          setIsFingerprintModalOpen(false);
        }
        break;
      default:
    }
  };

  if (credentials?.role !== "INSTRUCTOR") return <PageRedirection to="/main" />;

  return (
    <>
      <CustomCard
        className="confirmation-card"
        cardHeaderText="Please Confirm Details"
      >
        <H4>Program Details</H4>
        <div className="form-row">
          <div className="field-container-vert">
            <div>Program Type</div>
            <div>{programTypeString}</div>
          </div>
          <div className="field-container-vert">
            <div>Training Purpose</div>
            <div>{trainingPurposeString}</div>
          </div>
        </div>
        <Divider className="confirmation-divider" />
        <H4>Personal Details</H4>
        <StudentProfile data={studentProfile!}></StudentProfile>
        <Divider className="confirmation-divider" />
        {certificateType === "practical" && (
          <>
            <H4>Driver's License Classification</H4>
            <table className="confirmation-dl-license-classification-table">
              {DLRestrictionsForm.map((formDLCodeObj) => {
                const DLCodeDescription = DL_RESTRICTIONS.find(
                  (constDLCodeObj) =>
                    constDLCodeObj.DLCode === formDLCodeObj.DLCode
                )!.description;
                return (
                  <>
                    <tr>
                      <td>
                        {formDLCodeObj.DLCode} - {DLCodeDescription}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {formDLCodeObj.vehicleCategories.map((formCategoryObj) => {
                      const categoryObjDescription = DL_RESTRICTIONS.map(
                        (element) => element.vehicleCategories
                      )
                        .flat(1)
                        .find(
                          (constVehicleObj) =>
                            constVehicleObj.category ===
                            formCategoryObj.category
                        )!.description;

                      return (
                        <tr>
                          <td className="nested-row">
                            {formCategoryObj.category} -{" "}
                            {categoryObjDescription}
                          </td>
                          <td className="chosen-value">
                            <b>{formCategoryObj.DLClassification}</b>
                          </td>
                          <td className="chosen-value">
                            <b>{formCategoryObj.clutch}</b>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                );
              })}
            </table>
            <Divider className="confirmation-divider" />
          </>
        )}
        <H4>Course Details</H4>
        <div className="form-row">
          <div className="field-container-vert">
            <div>Issuing Branch</div>
            <div>{courseDetailForm.issuingBranch || naString}</div>
          </div>
          <div className="field-container-vert">
            <div>Driving Course Name</div>
            <div>{courseDetailForm.drivingCourseName || naString}</div>
          </div>
        </div>
        <div className="course-details-container">
          <div className="theoretical-values subvalues">
            <H5>{programTypeString}</H5>
            <div className="form-row">
              <div className="field-container-vert">
                <div>Date Started</div>
                <div>
                  {dayjs(courseDetailForm.dateStarted).format(
                    "MMMM DD, YYYY"
                  ) || naString}
                </div>
              </div>
              <div className="field-container-vert">
                <div>Date Completed</div>
                <div>
                  {dayjs(courseDetailForm.dateCompleted).format(
                    "MMMM DD, YYYY"
                  ) || naString}
                </div>
              </div>
            </div>
            <div className="summary-container">
              <div className="field-container-hor">
                <div>Total No. of Classroom Hours:</div>
                <div>{courseDetailForm.noOfHours || naString} hours</div>
              </div>
              <div className="field-container-hor">
                <div>ASSESSMENT:</div>
                <div>{courseDetailForm.assessment || naString}</div>
              </div>
              <div className="field-container-hor">
                <div>OVERALL RATING:</div>
                <div>{courseDetailForm.rating || naString}</div>
              </div>
              <div className="field-container-hor">
                <div>Additional Comments:</div>
                <div>{courseDetailForm.comments || naString}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="buttons-container">
          <Button
            onClick={() => navigation("/main/submit-certificate/2")}
            large={true}
            disabled={reqStatus === "loading"}
          >
            BACK
          </Button>
          <Button
            intent="primary"
            large={true}
            onClick={onButtonClick}
            loading={reqStatus === "loading"}
          >
            SUBMIT
          </Button>
        </div>
      </CustomCard>
      <FingerprintCapture
        isOpen={isFingerprintModalOpen}
        onClose={() => setIsFingerprintModalOpen(false)}
        numberOfFingerprints={2}
        onCapture={(fingerprints) => {
          onFormSubmission(fingerprints as [string, string]);
        }}
        defaultMessage={[
          "Please press your thumb on the fingerprint scanner.",
          "Please press your index finger on the fingerprint scanner.",
        ]}
        status="default"
      ></FingerprintCapture>
    </>
  );
};

export default ConfirmationPage;
