import { Checkbox, CheckboxProps, Radio } from "@blueprintjs/core";
import { FC, useState } from "react";

type NestedRowValue = {
  checked: boolean;
  DLClassification: string;
  clutch: string;
};

type NestedTableRowProps = {
  code: string;
  description: string;
  disabled?: boolean;
  value: NestedRowValue;
  onChange: (value: NestedRowValue) => void;
};

const NestedTableRow: FC<NestedTableRowProps> = (props) => {
  const handleCheckboxChange: CheckboxProps["onChange"] = (event) => {
    const checked = (event.target as HTMLInputElement).checked;

    props.onChange({
      checked: checked,
      DLClassification: "",
      clutch: "",
    });
  };

  type RadioButtonKeys = "dl-classification" | "clutch";
  const handleRadioButtonChange: (
    key: RadioButtonKeys
  ) => React.FormEventHandler<HTMLInputElement> = (key) => {
    switch (key) {
      case "dl-classification":
        return (event) => {
          const value = (event.target as HTMLInputElement).value;
          props.onChange({
            ...props.value,
            DLClassification: value,
          });
        };
      case "clutch":
        return (event) => {
          const value = (event.target as HTMLInputElement).value;
          props.onChange({
            ...props.value,
            clutch: value,
          });
        };
    }
  };

  return (
    <tr>
      <td className="details-cell">
        <Checkbox
          className="table-checkbox nested-checkbox"
          large={true}
          disabled={props.disabled}
          checked={props.value.checked}
          onChange={handleCheckboxChange}
        >
          {props.code} - {props.description}
        </Checkbox>
      </td>
      <td className="radio-button-cell table-border-top table-border-bottom table-border-left">
        <Radio
          className="standalone-radio-button"
          onChange={handleRadioButtonChange('dl-classification')}
          checked={props.value.DLClassification === "NON-PRO"}
          value="NON-PRO"
          disabled={props.disabled || !props.value.checked}
        />
      </td>
      <td className="radio-button-cell table-border-top table-border-bottom table-border-right">
        <Radio
          className="standalone-radio-button"
          onChange={handleRadioButtonChange('dl-classification')}
          checked={props.value.DLClassification === "PRO"}
          value="PRO"
          disabled={props.disabled || !props.value.checked}
        />
      </td>
      <td className="radio-button-cell table-border-top table-border-bottom table-border-left">
        <Radio
          className="standalone-radio-button"
          onChange={handleRadioButtonChange('clutch')}
          checked={props.value.clutch === "AT"}
          value="AT"
          disabled={props.disabled || !props.value.checked}
        />
      </td>
      <td className="radio-button-cell table-border-top table-border-bottom table-border-right">
        <Radio
          className="standalone-radio-button"
          onChange={handleRadioButtonChange('clutch')}
          checked={props.value.clutch === "MT"}
          value="MT"
          disabled={props.disabled || !props.value.checked}
        />
      </td>
    </tr>
  );
};

export default NestedTableRow;
