import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export type PageRedirectionProps = {
  to: string;
};

const PageRedirection: FC<PageRedirectionProps> = (props) => {
  const navigation = useNavigate();

  useEffect(() => {
    navigation(props.to);
  }, []);
  return <></>;
};

export default PageRedirection;
