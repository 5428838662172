import {
  Icon,
  InputGroup,
  InputGroupProps2,
  MenuItem,
} from "@blueprintjs/core";
import { ItemRenderer, Select, SelectProps } from "@blueprintjs/select";
import React, { FC } from "react";
import { StringVal } from "../../utils/typings";
import "./CustomSelect.css";

export type CustomSelectProps = {
  items: StringVal[];
  value: string;
  onItemSelect: SelectProps<StringVal>["onItemSelect"];
  width?: React.CSSProperties["width"];
  placeholder?: InputGroupProps2["placeholder"];
  disabled?: boolean;
};

const CustomSelect: FC<CustomSelectProps> = (props) => {
  const handleItemRendering: ItemRenderer<StringVal> = (item) => {
    return (
      <MenuItem
        key={item.value}
        text={item.string}
        onClick={(event) => props.onItemSelect(item, event)}
      ></MenuItem>
    );
  };

  let selectedItemString =
    props.items.find((item) => item.value === props.value)?.string || "";

  return (
    <Select
      items={props.items}
      itemRenderer={handleItemRendering}
      onItemSelect={props.onItemSelect}
      popoverProps={{
        position: "bottom-left",
        minimal: true,
        boundary: "scrollParent",
        usePortal: false,
        className: "custom-select-container",
      }}
      filterable={false}
      disabled={props.disabled}
    >
      <InputGroup
        className="custom-select"
        style={{ width: props.width }}
        rightElement={
          <Icon
            style={{ transform: "translate(-20%, 30%)" }}
            icon="caret-down"
          ></Icon>
        }
        readOnly={true}
        value={selectedItemString}
        placeholder={props.placeholder || ""}
        disabled={props.disabled}
      ></InputGroup>
    </Select>
  );
};

export default CustomSelect;
