import dayjs from "dayjs";

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
export const MIN_DATE = dayjs().set('year', 1900).startOf('year').toDate();
Object.freeze(MIN_DATE);

export const MINIMUM_HOURS = {
  theoretical: 15,
  practical: 8
};

export const naString = 'N/A';

export const SELECT_ITEMS = {
  PROGRAM_TYPES: [
    {
      string: "Theoretical Instruction Training",
      value: "theoretical",
    },
    {
      string: "Practical Driving Instruction Training",
      value: "practical",
    },
  ],
  SEX: [
    { string: "Male", value: "male" },
    { string: "Female", value: "female" },
  ],
  STATUS: [
    { string: "Married", value: "married" },
    { string: "Single", value: "single" },
    { string: "Widow", value: "widow" },
    { string: "Separated", value: "separated" },
    { string: "Divorced", value: "divorced" },
  ],
  THEORETICAL_TRAINING_PURPOSE: [
    { string: "Student Permit", value: "student-permit" },
  ],
  PRACTICAL_TRAINING_PURPOSE: [
    { string: "New Driver's License", value: "new-dl" },
    { string: "Additional Vehicle Category", value: "add-vehicle-cat" },
  ]
};

