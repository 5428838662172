import { FC } from "react";
import "./BackgroundComponent.scss";

export type BackgroundComponentProps = {
  url: string;
};

const BackgroundComponent: FC<BackgroundComponentProps> = (props) => {
  return <img className="background-component" src={props.url} />;
};

export default BackgroundComponent;
