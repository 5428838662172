import {
  Button,
  Divider,
  InputGroup,
  IconName,
  Icon,
  Spinner,
  ButtonProps,
} from "@blueprintjs/core";
import { MouseEvent, useEffect, useRef, useState } from "react";
import CustomCard from "../../common-ui/custom-card/CustomCard";
import "./ViewAccountsPage.scss";
import { DSQueryResult } from "shared-types";
import ReactPaginate from "react-paginate";
import { formatNumber } from "../../utils/common";
import CustomTable from "../../common-ui/custom-table/CustomTable";
import { HTTP_APIs } from "../../utils/http-apis";
import { showHTTPResponseToaster } from "../../utils/toaster-ui";
import { useNavigate } from "react-router-dom";
import { getCredentialsFromLocalStorage } from "../../utils/custom-local-storage";
import PageRedirection from "../page-redirection/PageRedirection";

const itemsPerPage = 5;

const ViewAccountsPage = () => {
  const credentials = getCredentialsFromLocalStorage();
  const navigation = useNavigate();

  useEffect(() => {
    if (credentials?.role !== "SYSTEM-ADMIN") {
      navigation("/main");
      return;
    }
    window.scrollTo(0, 0);
  }, []);

  const [query, setQuery] = useState("");
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [tableData, setTableData] = useState<DSQueryResult[]>([]);
  const [emptyTableMessage, setEmptyTableMessage] = useState({
    icon: "search" as IconName,
    message: "Please fill up search field to continue.",
  });

  const onSearchButtonClick: ButtonProps["onClick"] = async (event) => {
    event.preventDefault();

    setIsTableLoading(true);
    setEmptyTableMessage({
      icon: "delete",
      message: "No Driving School found.",
    });

    const response = await HTTP_APIs.systemAdmin.getDSList({ query });
    setIsTableLoading(false);

    if (response.status === "success") {
      setTableData(response.data);
    }
    showHTTPResponseToaster(response);
  };

  const onViewProfileButtonClick = (DSId: string) => () => {
    navigation(`./${DSId}`);
  };

  if (credentials?.role !== "SYSTEM-ADMIN")
    return <PageRedirection to="/main" />;

  return (
    <>
      <CustomCard id="view-accounts-card" cardHeaderText="View Accounts">
        <form className="search-form">
          <div>Driving School Name/Code:</div>
          <InputGroup
            value={query}
            disabled={isTableLoading}
            onChange={(event) => setQuery(event.target.value)}
            spellCheck="false"
          />
          <Button
            intent="primary"
            type="submit"
            icon="search"
            disabled={!query || isTableLoading}
            onClick={onSearchButtonClick}
          />
        </form>
        <Divider />
        <div className="table-container">
          <CustomTable
            tableData={tableData}
            itemsPerPage={5}
            className="view-accounts-table"
            loading={isTableLoading}
            emptyTableMessage={emptyTableMessage}
            headerRow={
              <tr>
                <th>Code</th>
                <th className="ds-name-column">Driving School Name</th>
                <th>Total Credits</th>
                <th>Actions</th>
              </tr>
            }
            rowRenderer={(data) => (
              <tr>
                <td>{data.drivingSchoolId}</td>
                <td>{data.name}</td>
                <td className="center-content">
                  {formatNumber(data.totalCredits, "currency")}
                </td>
                <td className="center-content">
                  <Button
                    small={true}
                    onClick={onViewProfileButtonClick(data.drivingSchoolId)}
                  >
                    View Profile
                  </Button>
                </td>
              </tr>
            )}
          />
        </div>
      </CustomCard>
    </>
  );
};

export default ViewAccountsPage;
