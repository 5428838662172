import { regions, provinces, cities, barangays, RegionPH, ProvincePH, CityPH, BarangayPH } from 'select-philippines-address';
import { HTTP_ERROR_MESSAGES } from './shared';
import { APIPromise } from './typings';

const addressApis = {
  getRegionsPH: async (): APIPromise<RegionPH[]> => {
    try {
      const output = await regions()

      if (!Array.isArray(output)) return {
        status: 'error-unknown',
        message: 'Getting Philippine Regions returns unexpected value'
      };

      return {
        status: 'success',
        data: output
      }
    } catch (error) {
      return {
        status: 'error-unknown',
        message: HTTP_ERROR_MESSAGES.error_UNKNOWN
      }
    }
  },
  getProvincesPH: async (regionCode: string): APIPromise<ProvincePH[]> => {
    try {
      const output = await provinces(regionCode)

      if (!Array.isArray(output)) return {
        status: 'error-unknown',
        message: 'Getting Philippine Provinces returns unexpected value'
      };

      return {
        status: 'success',
        data: output
      }
    } catch (error) {
      return {
        status: 'error-unknown',
        message: HTTP_ERROR_MESSAGES.error_UNKNOWN
      }
    }
  },
  getCitiesPH: async (provinceCode: string): APIPromise<CityPH[]> => {
    try {
      const output = await cities(provinceCode)

      if (!Array.isArray(output)) return {
        status: 'error-unknown',
        message: 'Getting Philippine Cities returns unexpected value'
      };

      return {
        status: 'success',
        data: output
      }
    } catch (error) {
      return {
        status: 'error-unknown',
        message: HTTP_ERROR_MESSAGES.error_UNKNOWN
      }
    }
  },
  getBrgyPH: async (cityCode: string): APIPromise<BarangayPH[]> => {
    try {
      const output = await barangays(cityCode)

      if (!Array.isArray(output)) return {
        status: 'error-unknown',
        message: 'Getting Philippine Barangays returns unexpected value'
      };

      return {
        status: 'success',
        data: output
      }
    } catch (error) {
      return {
        status: 'error-unknown',
        message: HTTP_ERROR_MESSAGES.error_UNKNOWN
      }
    }
  }
};

export default addressApis;