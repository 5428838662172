export const EMPTY_FORMS = {
  PERSONAL_INFORMATION: {
    ltoPortalId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    licenseNumber: "",
    dob: "",
    sex: "",
    status: "",
    houseBuilding: "",
    streetName: "",
    region: { name: "", code: "" },
    province: { name: "", code: "" },
    cityMunicipality: { name: "", code: "" },
    brgyDistrict: { name: "", code: "" },
    zip: "",
  },
  DL_RESTRICTIONS: [] as {
    DLCode: string;
    vehicleCategories: {
      category: string;
      DLClassification: string;
      clutch: string;
    }[];
  }[],
  COURSE_DETAILS: {
    trainingPurpose: "",
    issuingBranch: "",
    drivingCourseName: "",
    dateStarted: "",
    dateCompleted: "",
    noOfHours: "0",
    assessment: "",
    rating: "",
    comments: "",
  },
};

