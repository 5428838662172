import {
  Button,
  TextArea,
} from "@blueprintjs/core";
import {
  FC,
  FormEventHandler,
  useEffect,
  useState,
} from "react";
import CustomDialog from "../../../../common-ui/custom-dialog/CustomDialog";
import CustomNumberInput, {
  CustomNumberInputProps,
} from "../../../../common-ui/custom-number-input/CustomNumberInput";
import CustomSelect from "../../../../common-ui/custom-select/CustomSelect";
import { formatName, formatNumber } from "../../../../utils/common";
import { mainStorage } from "../../../../utils/custom-local-storage";
import { HTTP_APIs } from "../../../../utils/http-apis";
import { showHTTPResponseToaster } from "../../../../utils/toaster-ui";
import { StringVal } from "../../../../utils/typings";
import { useDSDetailsContext } from "../ViewDrivingSchoolPage";
import "./AddCreditsModal.scss";

const emptyForm = {
  modeOfPayment: "",
  amount: "",
  notes: "",
};

export type AddCreditsModalProps = {
  onSuccess?: () => void;
};

const AddCreditsModal: FC<AddCreditsModalProps> = (props) => {
  const [isMainModalOpen, setIsMainModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [formValues, setFormValues] = useState(emptyForm);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const adminCredentials = mainStorage.get("admin-credentials");
  const DSDetails = useDSDetailsContext();

  const modeOfPaymentItems: StringVal[] = [
    {
      string: "Bank Transfer",
      value: "Bank Transfer",
    },
    {
      string: "GCash",
      value: "GCash",
    },
    {
      string: "Manager's Check",
      value: "Manager's Check",
    },
  ];

  useEffect(() => {
    const computedFormValidity = Object.keys(formValues).every((key) => {
      const typedkey = key as keyof typeof formValues;
      const value = formValues[typedkey];

      switch (typedkey) {
        case "modeOfPayment":
          return value;
        case "amount":
          const parsedFloat = parseFloat(value);
          return value && !isNaN(parsedFloat) && parsedFloat > 0;
        case "notes":
          return true;
      }
    });

    setIsFormValid(computedFormValidity);
  }, [formValues]);

  useEffect(() => {
    if (!isMainModalOpen) return;
    setFormValues(emptyForm);
  }, [isMainModalOpen]);

  if (!adminCredentials) {
    return <></>;
  }

  const proceedToConfirm = () => {
    setIsMainModalOpen(false);
    setIsConfirmationModalOpen(true);
  };

  const onFormSubmission: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (!isFormValid) return;
    proceedToConfirm();
  };

  const onConfirmButtonClick = async () => {
    setIsLoading(true);

    const response = await HTTP_APIs.systemAdmin.addCredits({
      drivingSchoolId: DSDetails.id,
      modeOfPayment: formValues.modeOfPayment,
      amount: parseFloat(formValues.amount),
      notes: formValues.notes,
    });
    setIsLoading(false);

    if (response.status === "success") {
      setIsConfirmationModalOpen(false);
      props.onSuccess?.();
    }
    showHTTPResponseToaster(response);
  };

  return (
    <>
      <Button
        icon="plus"
        text="Add Credits"
        onClick={() => {
          setIsMainModalOpen(true);
        }}
        disabled={isLoading}
      />
      <CustomDialog
        title="Add Credits"
        isOpen={isMainModalOpen}
        onClose={() => setIsMainModalOpen(false)}
      >
        <form className="add-credits-form" onSubmit={onFormSubmission}>
          <div className="field-container-vert">
            <div>Driving School:</div>
            <div>{DSDetails.name}</div>
          </div>
          <div className="field-container-vert">
            <div>Submitted by:</div>
            <div>{formatName(adminCredentials, "fl")}</div>
          </div>
          <div className="payment-details">
            <div className="field-container-vert">
              <div>Mode of Payment:</div>
              <CustomSelect
                value={formValues.modeOfPayment}
                onItemSelect={(item) =>
                  setFormValues({ ...formValues, modeOfPayment: item.value })
                }
                items={modeOfPaymentItems}
              />
            </div>
            <div className="field-container-vert">
              <div>Amount:</div>
              <CustomNumberInput
                value={formValues.amount}
                onValueChange={(_, value) =>
                  setFormValues({ ...formValues, amount: value })
                }
                buttonPosition="none"
              />
            </div>
          </div>
          <div className="field-container-vert">
            <div>Notes:</div>
            <TextArea
              spellCheck={false}
              value={formValues.notes}
              onChange={(event) =>
                setFormValues({ ...formValues, notes: event.target.value })
              }
            ></TextArea>
          </div>
          <div className="buttons-container">
            <Button onClick={() => setIsMainModalOpen(false)}>CANCEL</Button>
            <Button
              type="submit"
              intent="primary"
              disabled={!isFormValid}
              onClick={proceedToConfirm}
            >
              ADD CREDITS
            </Button>
          </div>
        </form>
      </CustomDialog>
      <CustomDialog
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        title="Confirmation"
        style={{ width: 400 }}
      >
        <div>
          Are you sure you want to add{" "}
          <b>{formatNumber(parseFloat(formValues.amount), "currency")}</b> worth
          of credits for <b>{DSDetails.name}</b>?
        </div>
        <div className="buttons-container">
          <Button onClick={() => setIsConfirmationModalOpen(false)}>
            BACK
          </Button>
          <Button
            intent="primary"
            loading={isLoading}
            onClick={onConfirmButtonClick}
          >
            CONFIRM
          </Button>
        </div>
      </CustomDialog>
    </>
  );
};

export default AddCreditsModal;
