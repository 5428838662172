import { AddCredits, AddDrivingSchoolIssuingBranch, DeleteDrivingSchoolIssuingBranch, GetDrivingSchoolIssuingBranches, GetDrivingSchoolList, GetDrivingSchoolStaticData, GetDrivingSchoolPersonnels, GetDrivingSchoolTransactions } from "shared-types";
import { processInternalAPI } from "./shared";

export const systemAdminApis = {
  getDSList: async (reqObj: GetDrivingSchoolList['FTB']) => (
    processInternalAPI<GetDrivingSchoolList['BTF']>({
      method: 'GET',
      path: '/api/driving-schools',
      reqQuery: reqObj,
      withCredentials: true
    })
  ),
  getDrivingSchoolStaticData: async (reqObj: GetDrivingSchoolStaticData['FTB']) => (
    processInternalAPI<GetDrivingSchoolStaticData['BTF']>({
      method: 'GET',
      path: `/api/driving-schools/${reqObj.drivingSchoolId}/static-data`,
      withCredentials: true
    })
  ),
  getDrivingSchoolTransactions: async (reqObj: GetDrivingSchoolTransactions['FTB']) => (
    processInternalAPI<GetDrivingSchoolTransactions['BTF']>({
      method: 'GET',
      path: `/api/driving-schools/${reqObj.drivingSchoolId}/transactions/${reqObj.type}`,
      withCredentials: true
    })
  ),
  addCredits: async (reqObj: AddCredits['FTB']) => (
    processInternalAPI<AddCredits['BTF']>({
      method: 'POST',
      path: `/api/add-credits`,
      reqBody: reqObj,
      withCredentials: true
    })
  ),
  getDrivingSchoolPersonnels: async (reqObj: GetDrivingSchoolPersonnels['FTB']) => (
    processInternalAPI<GetDrivingSchoolPersonnels['BTF']>({
      method: 'GET',
      path: `/api/driving-schools/${reqObj.drivingSchoolId}/personnels`,
      withCredentials: true
    })
  ),
  getDrivingSchoolIssuingBranches: async (reqObj: GetDrivingSchoolIssuingBranches['FTB']) => (
    processInternalAPI<GetDrivingSchoolIssuingBranches['BTF']>({
      method: 'GET',
      path: `/api/driving-schools/${reqObj.drivingSchoolId}/issuing-branches`,
      withCredentials: true
    })
  ),
  addDrivingSchoolIssuingBranches: async (reqObj: AddDrivingSchoolIssuingBranch['FTB']) => (
    processInternalAPI<AddDrivingSchoolIssuingBranch['BTF']>({
      method: 'POST',
      path: `/api/issuing-branches/add`,
      reqBody: reqObj,
      withCredentials: true
    })
  ),
  deleteDrivingSchoolIssuingBranch: async (reqObj: DeleteDrivingSchoolIssuingBranch['FTB']) => (
    processInternalAPI<DeleteDrivingSchoolIssuingBranch['BTF']>({
      method: 'POST',
      path: `/api/issuing-branches/delete`,
      reqBody: reqObj,
      withCredentials: true
    })
  ),
}