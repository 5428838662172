import { H1 } from "@blueprintjs/core";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import BackgroundComponent from "../../common-ui/background/BackgroundComponent";
import LoginForm from "./LoginForm";
import "./LoginPage.scss";

export type LoginPageProps = {
  isAdmin: boolean;
};

const LoginPage: FC<LoginPageProps> = (props) => {
  return (
    <>
      <BackgroundComponent url="/login-bg.jpg" />
      <div className="main-container">
        <div className="form-container">
          <div className="header">
            <H1 className="header-text">
              Login {props.isAdmin ? "(ADMIN)" : ""}
            </H1>
            <div className="logos-container">
              <img
                className="company-logo logo"
                src="coso-logo.png"
                draggable="false"
                alt="COSO Logo"
                title="About COSO"
              />
              <img
                className="logo"
                src="lto-logo.png"
                draggable="false"
                alt="LTO Logo"
              />
            </div>
          </div>
          <LoginForm isAdmin={props.isAdmin} />
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default LoginPage;
