import { Button, H4, Tab, Tabs, TabsProps } from "@blueprintjs/core";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { DrivingSchoolTransaction } from "shared-types";
import CustomTable, {
  CustomTableProps,
} from "../../../../common-ui/custom-table/CustomTable";
import NotesButton from "../../../../common-ui/notes-button/NotesButton";
import { formatNumber } from "../../../../utils/common";
import { HTTP_APIs } from "../../../../utils/http-apis";
import { showHTTPResponseToaster } from "../../../../utils/toaster-ui";
import { useDSDetailsContext } from "../ViewDrivingSchoolPage";
import AddCreditsModal from "./AddCreditsModal";
import "./DSTransactions.scss";

export type TransactionFilters = "YTD" | "MTD";

const DSTransactions = () => {
  const drivingSchoolId = useDSDetailsContext().id;
  const [tabId, setTabId] = useState<TransactionFilters>("MTD");
  const [credits, setCredits] = useState(0);
  const [tableData, setTableData] = useState<DrivingSchoolTransaction[]>([]);
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const emptyTableMessage: CustomTableProps["emptyTableMessage"] = {
    icon: "remove",
    message: "No transactions found.",
  };

  useEffect(() => {
    getTransactions();
  }, []);

  const getTransactions = async () => {
    setIsTableDataLoading(true);
    const response = await HTTP_APIs.systemAdmin.getDrivingSchoolTransactions({
      drivingSchoolId: drivingSchoolId,
      type: tabId,
    });
    setIsTableDataLoading(false);

    showHTTPResponseToaster(response);

    if (response.status === "success") {
      setTableData(response.data.history.reverse());
      setCredits(response.data.credits);
    }
  };

  const onTabIdChange: TabsProps["onChange"] = (tabId) => {
    setTabId(tabId as TransactionFilters);
    getTransactions();
  };

  return (
    <section id="ds-profile-transactions">
      <H4>Transactions</H4>
      <div className="field-container-hor">
        <div>Totals Credits:</div>
        <div>{formatNumber(credits, "currency")}</div>
      </div>
      <div className="options-container">
        <Tabs className="tabs" selectedTabId={tabId} onChange={onTabIdChange}>
          <Tab id="MTD" title="MTD"></Tab>
          <Tab id="YTD" title="YTD"></Tab>
        </Tabs>
        <AddCreditsModal onSuccess={getTransactions} />
      </div>
      <CustomTable
        className="transactions-table"
        headerRow={
          <tr>
            <th>Date & Time</th>
            <th>Amount</th>
            <th className="descriptions-header">Description</th>
            <th>Notes</th>
          </tr>
        }
        rowRenderer={(item) => (
          <tr>
            <td className="center-cell">
              {dayjs(item.date).format("MM/DD/YYYY hh:mm A")}
            </td>
            <td className="center-cell">
              {formatNumber(item.amount, "currency")}
            </td>
            <td>{item.description}</td>
            <td className="center-cell">
              <NotesButton notes={item.notes} small={true} />
            </td>
          </tr>
        )}
        loading={isTableDataLoading}
        emptyTableMessage={emptyTableMessage}
        itemsPerPage={5}
        tableData={tableData}
      />
    </section>
  );
};

export default DSTransactions;
