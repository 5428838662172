import { GetDLDetails } from 'shared-types';
import { parseToFormData, processInternalAPI } from './shared';

const LTOApis = {
  getDLDetails: async (reqObj: GetDLDetails['FTB']) => {
    return processInternalAPI<GetDLDetails['BTF']>({
      method: 'GET',
      path: '/api/lto/dl-details',
      reqQuery: reqObj,
      withCredentials: true,
    })
  }
};

export default LTOApis;

// /lto/dl-details