import { IToaster, IToastProps, Toaster } from "@blueprintjs/core";
import { CustomResponseData } from "./http-apis/typings";
import { ReqStatus } from "./typings";

let toaster: IToaster;

export function initToaster() {
  toaster = Toaster.create({ maxToasts: 3 })
}

export function getToaster() {
  if (!toaster) initToaster();
  return toaster;
}

export function showHTTPResponseToaster(params: { status: ReqStatus; message?: string }) {
  if (!params.message) return;

  let intent: IToastProps['intent'];
  let icon: IToastProps['icon'];

  switch (params.status) {
    case 'success':
      intent = 'success';
      icon = 'tick';
      break;
    default:
      intent = 'warning';
      icon = 'warning-sign';
      break;
  };

  getToaster().show({
    message: params.message,
    intent,
    icon,
  })
} 