import { Button } from "@blueprintjs/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../common-ui/custom-dialog/CustomDialog";
import {
  clearLocalStorage,
  getCredentialsFromLocalStorage,
} from "../utils/custom-local-storage";
import { HTTP_APIs } from "../utils/http-apis";
import { showHTTPResponseToaster } from "../utils/toaster-ui";

const LogoutModal = () => {
  const navigation = useNavigate();
  const credentials = getCredentialsFromLocalStorage();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onLogoutButtonClick = () => {
    setIsLoading(true);

    HTTP_APIs.personnel.logout().then((response) => {
      setIsLoading(false);
      showHTTPResponseToaster(response);
      // clearLocalStorage()
      navigation(
        credentials?.role === "SYSTEM-ADMIN" ? "/admin-login" : "/login"
      );
    });
  };
  return (
    <>
      <Button onClick={() => setIsOpen(true)} disabled={isLoading}>
        Logout
      </Button>
      <CustomDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Logout"
        style={{ width: 320 }}
      >
        <span>Are you sure you want to logout?</span>
        <div style={{ marginTop: 50 }} className="buttons-container">
          <Button onClick={() => setIsOpen(false)}>BACK</Button>
          <Button
            onClick={onLogoutButtonClick}
            intent="danger"
            loading={isLoading}
          >
            LOGOUT
          </Button>
        </div>
      </CustomDialog>
    </>
  );
};

export default LogoutModal;
