import { H3, Icon } from "@blueprintjs/core";
import dayjs from "dayjs";
import CustomCard from "../../../common-ui/custom-card/CustomCard";
import { formatName } from "../../../utils/common";
import { getCredentialsFromLocalStorage } from "../../../utils/custom-local-storage";

const GreetingsCard = () => {
  const credentials = getCredentialsFromLocalStorage();

  let name: string;
  let lastLogin: string;

  if (!credentials) return <></>;

  name = formatName(credentials.data, "fl");
  lastLogin = dayjs(credentials.data.lastLogin).format("MMMM DD, YYYY hh:mm A");

  return (
    <CustomCard className="welcome-back-card card">
      <Icon size={70} icon="thumbs-up"></Icon>
      <div className="text-container">
        <H3>Welcome back, {name}!</H3>
        <div className="field-container-hor">
          <span>Last Login:</span>
          <span>{lastLogin}</span>
        </div>
      </div>
    </CustomCard>
  );
};

export default GreetingsCard;
