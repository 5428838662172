import { Button, Dialog, InputGroup } from "@blueprintjs/core";
import { ChangeEventHandler, FC, useEffect, useState } from "react";
import CustomDialog from "../../../common-ui/custom-dialog/CustomDialog";
import { getCredentialsFromLocalStorage } from "../../../utils/custom-local-storage";
import { HTTP_APIs } from "../../../utils/http-apis";
import { showHTTPResponseToaster } from "../../../utils/toaster-ui";
import PageRedirection from "../../page-redirection/PageRedirection";
import "./ChangePasswordModal.scss";

type ChangePasswordModalProps = {
  className?: string;
};

const emptyForm = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const ChangePasswordModal: FC<ChangePasswordModalProps> = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const credentials = getCredentialsFromLocalStorage();

  const [isMainModalOpen, setIsMainModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const closeMainWindow = () => setIsMainModalOpen(false);
  const openMainWindow = () => setIsMainModalOpen(true);

  const closeConfirmationWindow = () => setIsConfirmationModalOpen(false);
  const openConfirmationWindow = () => setIsConfirmationModalOpen(true);

  const [formValues, setFormValues] = useState(emptyForm);
  const [isFormValid, setIsFormValid] = useState(false);

  type InputKeys = keyof typeof formValues;
  const handleInputChange = (
    key: InputKeys
  ): ChangeEventHandler<HTMLInputElement> => {
    return (event) =>
      setFormValues({ ...formValues, [key]: event.target.value });
  };

  useEffect(() => {
    if (
      !formValues.currentPassword ||
      !formValues.newPassword ||
      !formValues.confirmNewPassword
    ) {
      setIsFormValid(false);
      return;
    }

    if (formValues.newPassword !== formValues.confirmNewPassword) {
      setIsFormValid(false);
      return;
    }

    setIsFormValid(true);
  }, [formValues]);

  useEffect(() => {
    if (isMainModalOpen) {
      setFormValues(emptyForm);
    }
  }, [isMainModalOpen]);

  const onSubmitButtonClick = () => {
    closeMainWindow();
    openConfirmationWindow();
  };

  const onConfirmButtonClickUser = () => {
    setIsLoading(true);
    HTTP_APIs.personnel
      .changeUserPassword({
        currentPassword: formValues.currentPassword,
        newPassword: formValues.newPassword,
      })
      .then((response) => {
        setIsLoading(false);
        setIsConfirmationModalOpen(false);
        showHTTPResponseToaster(response);
      });
  };

  const onConfirmButtonClickAdmin = () => {
    setIsLoading(true);
    HTTP_APIs.personnel
      .changeAdminPassword({
        currentPassword: formValues.currentPassword,
        newPassword: formValues.newPassword,
      })
      .then((response) => {
        setIsLoading(false);
        setIsConfirmationModalOpen(false);
        showHTTPResponseToaster(response);
      });
  };

  if (!credentials) return <PageRedirection to="main"/>;

  return (
    <>
      <Button
        className={props.className}
        onClick={openMainWindow}
        icon="key"
        disabled={isLoading}
      >
        Change Password
      </Button>
      <Dialog
        style={{ width: 320 }}
        className="change-password-window"
        isOpen={isMainModalOpen}
        onClose={closeMainWindow}
        title="Change Password"
      >
        <form className="password-form">
          <div className="field-container-vert">
            <div>Current Password</div>
            <InputGroup
              value={formValues.currentPassword}
              onChange={handleInputChange("currentPassword")}
              type="password"
              autoComplete="password"
            />
          </div>
          <div className="field-container-vert">
            <div>New Password</div>
            <InputGroup
              value={formValues.newPassword}
              onChange={handleInputChange("newPassword")}
              type="password"
              intent={
                formValues.newPassword &&
                formValues.confirmNewPassword &&
                formValues.newPassword !== formValues.confirmNewPassword
                  ? "danger"
                  : "none"
              }
            />
          </div>
          <div className="field-container-vert">
            <div>Confirm New Password</div>
            <InputGroup
              value={formValues.confirmNewPassword}
              onChange={handleInputChange("confirmNewPassword")}
              type="password"
              intent={
                formValues.confirmNewPassword &&
                formValues.newPassword !== formValues.confirmNewPassword
                  ? "danger"
                  : "none"
              }
            />
          </div>
          <div className="buttons-container">
            <Button onClick={closeMainWindow}>BACK</Button>
            <Button
              intent="primary"
              disabled={!isFormValid}
              onClick={onSubmitButtonClick}
            >
              SUBMIT
            </Button>
          </div>
        </form>
      </Dialog>
      <CustomDialog
        style={{ width: 380 }}
        className="change-password-confirmation-window"
        title="Confirm to Change Password"
        isOpen={isConfirmationModalOpen}
        onClose={closeConfirmationWindow}
      >
        Are you sure you want to change your password?
        <div className="buttons-container">
          <Button onClick={closeConfirmationWindow}>BACK</Button>
          <Button
            intent="primary"
            onClick={
              credentials.role === "SYSTEM-ADMIN"
                ? onConfirmButtonClickAdmin
                : onConfirmButtonClickUser
            }
            loading={isLoading}
          >
            CONFIRM
          </Button>
        </div>
      </CustomDialog>
    </>
  );
};

export default ChangePasswordModal;
