import { Button, InputGroup } from "@blueprintjs/core";
import { ChangeEvent, FC, useEffect, useState } from "react";
import ForgotCredentialsModal from "./ForgotCredentialsModal";
import { useNavigate } from "react-router-dom";

import "./LoginForm.scss";
import { HTTP_APIs } from "../../utils/http-apis";
import { showHTTPResponseToaster } from "../../utils/toaster-ui";
import { mainStorage } from "../../utils/custom-local-storage";

export type LoginFormProps = {
  isAdmin: boolean;
};

const RegistrationForm: FC<LoginFormProps> = (props) => {
  const navigate = useNavigate();

  const [formDisabled, setButtonDisabled] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function handleUsername(event: ChangeEvent<HTMLInputElement>) {
    setUsername(event.target.value);
  }

  function handlePassword(event: ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
  }

  useEffect(() => {
    setTimeout(() => {
      authenticateUser()
    }, 2000);
  }, [])

  useEffect(() => {
    setButtonDisabled(!username || !password);
  }, [username, password]);

  async function authenticateUser() {
    setIsLoading(true);

    const response = await HTTP_APIs.personnel.authenticateUser({
      username,
      password,
    });
    setIsLoading(false);

    switch (response.status) {
      case "success":
        if (response.data.auth) {
          setErrorMessage("");
          mainStorage.set("user-credentials", response.data);
          navigate("/main");
        } else {
          setErrorMessage("Incorrect Username/Password combination.");
        }
        return;
      default:
        showHTTPResponseToaster(response);
        setErrorMessage(response.message);
    }
  }

  async function authenticateAdmin() {
    setIsLoading(true);

    const response = await HTTP_APIs.personnel.authenticateAdmin({
      username,
      password,
    });
    setIsLoading(false);

    switch (response.status) {
      case "success":
        if (response.data.auth) {
          mainStorage.set("admin-credentials", response.data);
          navigate("/main");
        } else {
          setErrorMessage("Incorrect Username/Password combination.");
        }
        return;
      default:
        showHTTPResponseToaster(response);
        setErrorMessage(response.message);
    }
  }

  return (
    <>
      <form className="registration-form">
        <InputGroup
          className="text-input"
          placeholder="Username"
          large={true}
          leftIcon="user"
          value={username}
          autoComplete="username"
          onChange={handleUsername}
          spellCheck={false}
          disabled={isLoading}
          intent={errorMessage ? "danger" : "none"}
        ></InputGroup>
        <InputGroup
          className="text-input"
          placeholder="Password"
          leftIcon="key"
          large={true}
          value={password}
          onChange={handlePassword}
          type="password"
          autoComplete="current-password"
          spellCheck={false}
          disabled={isLoading}
          intent={errorMessage ? "danger" : "none"}
        ></InputGroup>
        <div className="error-message big-font">{errorMessage}</div>
        <ForgotCredentialsModal disabled={isLoading} />
        <Button
          className="form-button"
          large={true}
          intent="primary"
          fill={true}
          disabled={formDisabled}
          onClick={props.isAdmin ? authenticateAdmin : authenticateUser}
          loading={isLoading}
          type="submit"
        >
          LOGIN
        </Button>
      </form>
      <div className="information-buttons-container">
        <Button
          small={true}
          minimal={true}
          onClick={() => navigate("/terms-of-use")}
        >
          Terms of Use
        </Button>
        <Button
          small={true}
          minimal={true}
          onClick={() => navigate("/privacy-policy")}
        >
          Privacy Policy
        </Button>
        <Button
          small={true}
          minimal={true}
          onClick={() => navigate("/about-the-company")}
        >
          About the Company
        </Button>
        <Button
          small={true}
          minimal={true}
          onClick={() => navigate("/about-the-system")}
        >
          About the System
        </Button>
        <Button
          small={true}
          minimal={true}
          onClick={() => navigate("/refund-policy")}
        >
          Refund Policy
        </Button>
      </div>
    </>
  );
};

export default RegistrationForm;
