import AboutCompanyCard from "./about-company-card/AboutCompanyCard";
import "./AboutPage.scss";
import InformationTextCard from "./information-text-card/InformationTextCard";
import {
  aboutTheSystem,
  privacyPolicy,
  refundPolicy,
  termsOfUseText,
} from "./information-texts";

const AboutPage = () => {
  return (
    <>
      <div
        className="about-page-container"
        style={{ backgroundImage: "url(login-bg.jpg)" }}
      ></div>
      <div id="cards-container" className="about-cards-container">
        <AboutCompanyCard />
        <InformationTextCard
          includeBackToLoginPageButton={true}
          header="About the System"
          text={aboutTheSystem}
        />
        <InformationTextCard
          includeBackToLoginPageButton={true}
          header="Terms of Use"
          text={termsOfUseText}
        />
        <InformationTextCard
          includeBackToLoginPageButton={true}
          header="Privacy Policy"
          text={privacyPolicy}
        />
        <InformationTextCard
          includeBackToLoginPageButton={true}
          header="Refund Policy"
          text={refundPolicy}
        />
      </div>
    </>
  );
};

export default AboutPage;
