import { Divider, H1, H2 } from "@blueprintjs/core";
import CustomCard from "../../../common-ui/custom-card/CustomCard";
import BackToLoginButton from "../back-to-login-button/BackToLoginButton";
import "./AboutCompanyCard.scss";

const AboutCompanyCard = () => {
  return (
    <CustomCard id="about-company-card">
      <BackToLoginButton />
      <img
        className="coso-logo"
        src="coso-logo.png"
        draggable="false"
        alt="COSO Logo"
      />
      <H1 className="main-header-text">COSO TECHNOLOGIES INC.</H1>
      <p>
        <span className="highlight">COSO TECH</span> was established in 2021 to
        develop information technology development of software, hardware, and
        the management and implementation of the same, and to engage in
        activities that are directly and indirectly related to such. The company
        also engages in investing in startup enterprises aligned to the primary
        purpose of COSO TECH such as but not limited to software solutions,
        application development for mobile, finance technologies, and the like.
        Moreover, the company also engages in the purchase of technology stocks
        local or abroad.
      </p>
      <Divider />
      <div className="container-buffer">
        <section>
          <H2>Our Mission</H2>
          <p>
            COSO TECH exists to improve organizational processes through
            software.
          </p>
        </section>
        <section>
          <H2>Our Vision</H2>
          <p>
            COSO TECH envisions to be a leading and consistent provider of
            software solutions in the Philippines.
          </p>
        </section>
      </div>
      <div className="column-divider">
        <div>
          <section>
            <H2>Our Services</H2>
            <ol>
              <li>Software Consultation</li>
              <li>Custom Software Development</li>
              <li>Maintenance and Support</li>
            </ol>
          </section>
          <section>
            <H2>Our Key Officers</H2>
            <p>
              President, <b>Froilan Leonardo</b>
              <br />
              Vice President, <b>Catherine Co Soriano</b>
            </p>
          </section>
        </div>
        <img src="rocket-launch.png"></img>
      </div>
      <Divider />
      <H2>Our Process</H2>
      <img className="our-process-img" src="our-process.png" />
      <p>
        <b className="green">Analyze</b>
        &nbsp; is the first part of all transactions, which clarifies what the
        client needs to improve in their system or the lack of it.
      </p>
      <p>
        <b className="yellow">Plan.</b>
        &nbsp; The next step is to plan the design, development, testing, and
        launching of the software.
      </p>
      <p>
        <b className="red">Design</b>
        &nbsp; is not limited to aesthetics. It also involves the process,
        structure, and functionality of the software, with special focus on the
        users.
      </p>
      <p>
        <b className="purple">Build.</b>
        &nbsp; is all about programming and graphics, which covers functionality
        and artistic appeal.
      </p>
      <p>
        <b className="blue">Beta</b>
        &nbsp; is about testing the software and looking for bugs. This could be
        the longest part of the process depending on the complexity of the
        requirements.
      </p>
      <p>
        <b className="blue-green">Launch</b>
        &nbsp; is making the software available to the intended users. Although
        beta tests have been made, continuous improvement will take place.
      </p>
      <Divider />
      <div className="imri-container">
        <div className="center-container">
          <img className="imri-img" src="imri.png"></img>
        </div>
        <p>
          <b className="red bigger-font">Implement.</b>
          &nbsp; Our team will operate the software and watch it 24/7.
        </p>
        <p>
          <b className="yellow bigger-font">Monitor.</b>
          &nbsp; Our team will observe and cite areas that need improvement.
        </p>
        <p>
          <b className="green bigger-font">Report.</b>
          &nbsp; Our team will report all observed bugs to the client.
        </p>
        <p>
          <b className="purple bigger-font">Improve.</b>
          &nbsp; Our team will make the necessary adjustments in the software.
        </p>
      </div>
      <BackToLoginButton />
    </CustomCard>
  );
};

export default AboutCompanyCard;
