import { Button, Divider, H4 } from "@blueprintjs/core";
import dayjs from "dayjs";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomCard from "../../../common-ui/custom-card/CustomCard";
import { getCredentialsFromLocalStorage } from "../../../utils/custom-local-storage";
import { HTTP_APIs } from "../../../utils/http-apis";
import PageRedirection from "../../page-redirection/PageRedirection";
import DSIssuingBranches from "./ds-issuing-branches/DSIssuingBranches";
import DSPersonnels from "./ds-personnels/DSPersonnels";
import DSTransactions from "./ds-transactions/DSTransactions";
import "./ViewDrivingSchoolPage.scss";

const DSDetailsContext = createContext({
  id: "",
  name: "",
});
export const useDSDetailsContext = () => useContext(DSDetailsContext);

const ViewDrivingSchoolPage = () => {
  const navigation = useNavigate();
  const credentials = getCredentialsFromLocalStorage();
  const routeParams = useParams<"drivingSchoolId">();
  const drivingSchoolId = routeParams.drivingSchoolId || "";
  const [staticData, setStaticData] = useState({ name: "", registeredAt: "" });

  useEffect(() => {
    if (!drivingSchoolId) return;
    HTTP_APIs.systemAdmin
      .getDrivingSchoolStaticData({
        drivingSchoolId: drivingSchoolId,
      })
      .then((response) => {
        if (response.status === "success") {
          setStaticData(response.data);
        }
      });
  }, []);

  if (credentials?.role !== "SYSTEM-ADMIN")
    return <PageRedirection to="/main" />;

  return (
    <CustomCard
      id="driving-school-profile"
      cardHeaderText={`${staticData.name} (${drivingSchoolId}) Profile`}
      rightElement={<Button onClick={() => navigation("/main/accounts")} icon="arrow-left">BACK</Button>}
    >
      <DSDetailsContext.Provider
        value={{
          id: drivingSchoolId,
          name: staticData.name,
        }}
      >
        <DSTransactions />
        <Divider className="divider" />
        <DSPersonnels />
        <Divider className="divider" />
        <DSIssuingBranches />
        <Divider className="divider" />
        <H4>Other Data</H4>
        <div className="field-container-hor">
          <div>Register Date:</div>
          <div>{dayjs(staticData.registeredAt).format("MMMM DD, YYYY")}</div>
        </div>
      </DSDetailsContext.Provider>
    </CustomCard>
  );
};

export default ViewDrivingSchoolPage;
