import { Icon } from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons";
import { FC } from "react";
import { COLORS } from "../../utils/constants/CSS-values";
import "./CustomNotification.scss";

export type CustomNotificationProps = {
  icon: IconName;
  message?: string;
  color?: "black" | "red";
  className?: string;
};

const CustomNotification: FC<CustomNotificationProps> = (props) => {
  let color: string;

  switch (props.color) {
    case "black":
      color = COLORS.BLACK;
      break;
    case "red":
      color = COLORS.RED;
      break;
    default:
      color = COLORS.BLACK;
  }

  return (
    <div className={"notification-container " + (props.className || "")}>
      <Icon icon={props.icon} size={50} color={color} />
      <div className="message" style={{ color: color }}>
        {props.message}
      </div>
    </div>
  );
};

export default CustomNotification;
