import { Button } from "@blueprintjs/core";
import { FC, useEffect, useState } from "react";
import { getCredentialsFromLocalStorage } from "../../utils/custom-local-storage";
import CustomDialog, { CustomDialogProps } from "../custom-dialog/CustomDialog";
import "./AddCreditsWindow.scss";
import dayjs from "dayjs";
import { formatNumber } from "../../utils/common";
import CustomNumberInput from "../custom-number-input/CustomNumberInput";
import CustomSelect from "../custom-select/CustomSelect";

const emptyForm = {
  amount: "0",
  paymentMethod: "",
};

export type AddCreditsWindowProps = {
  isOpen: boolean;
  onClose?: CustomDialogProps["onClose"];
};
const AddCreditsWindow: FC<AddCreditsWindowProps> = (props) => {
  const credentials = getCredentialsFromLocalStorage();

  const [formValues, setFormValues] = useState(emptyForm);
  const [isFormValid, setIsFormValid] = useState(false);

  const onWindowClose: CustomDialogProps["onClose"] = (event) => {
    setFormValues(emptyForm);
    props.onClose?.(event);
  };

  useEffect(() => {
    const computedValidity = Object.entries(formValues).every(
      ([key, value]) => {
        switch (key) {
          case "amount":
            const parsedFloat = parseFloat(formValues.amount);
            if (isNaN(parsedFloat)) return false;
            if (parsedFloat <= 0) return false;
            return true;
          case "paymentMethod":
            if (!value) return false;
            return true;
        }
      }
    );
    setIsFormValid(computedValidity);
  }, [formValues]);
  if (!credentials) return <></>;
  if (credentials.role === "SYSTEM-ADMIN") return <></>;
  return (
    <>
      <CustomDialog
        title="Add Credits"
        isOpen={props.isOpen}
        onClose={onWindowClose}
      >
        <form className="add-credits-personnel-form">
          <div className="field-container-vert">
            <div>Driving School:</div>
            <div>{credentials.data.drivingSchoolName}</div>
          </div>
          <div className="field-container-vert">
            <div>Remaining Credits:</div>
            <div>
              {formatNumber(credentials.data.remainingAmount, "currency")}
            </div>
          </div>

          <div className="last-deposit-date-field-container field-container-vert">
            <div>Last Deposit Date:</div>
            <div>
              {dayjs(credentials.data.lastDepositDate).format(
                "MMMM DD, YYYY hh:mm A"
              )}{" "}
              ({formatNumber(credentials.data.lastDepositAmount, "currency")})
            </div>
          </div>
          <div className="field-container-vert">
            <div>Add Credit Amount:</div>
            <CustomNumberInput
              buttonPosition="none"
              value={formValues.amount}
              onValueChange={(_, value) =>
                setFormValues({ ...formValues, amount: value })
              }
            ></CustomNumberInput>
          </div>
          <div className="field-container-vert">
            <div>Payment Method</div>
            <CustomSelect
              value={formValues.paymentMethod}
              onItemSelect={(item) =>
                setFormValues({ ...formValues, paymentMethod: item.value })
              }
              items={[
                { string: "GCash", value: "GCash" },
                { string: "Bank Transfer", value: "Bank Transfer" },
                // { string: "PayMaya", value: "paymaya" },
              ]}
            ></CustomSelect>
          </div>
          <div className="buttons-container">
            <Button onClick={onWindowClose}>BACK</Button>
            <Button intent="primary" disabled={!isFormValid}>
              ADD CREDITS
            </Button>
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default AddCreditsWindow;
