import CustomCard from "../../common-ui/custom-card/CustomCard";
import { Chart, GoogleChartWrapperChartType } from "react-google-charts";
import { useEffect, useState } from "react";
import CustomSelect from "../../common-ui/custom-select/CustomSelect";

const ReportsPage = () => {
  const [reportType, setReportType] = useState("monthly-report");
  const [chartType, setChartType] = useState(
    "LineChart" as GoogleChartWrapperChartType
  );
  const [data, setData] = useState({} as any);
  const [options, setOptions] = useState({} as any);

  useEffect(() => {
    switch (reportType) {
      case "monthly-report":
        setChartType("LineChart");
        setData([
          ["Month", "No. of Students", "Certificate Transmittals"],
          ["Jan", 104, 100],
          ["Feb", 88, 98],
          ["Mar", 122, 111],
          ["Apr", 45, 57],
          ["May", 88, 70],
          ["June", 62, 48],
        ]);
        setOptions({
          curveType: "function",
          legend: { position: "bottom" },
        });
        break;
      case "yearly-report":
        setChartType("LineChart");
        setData([
          ["Year", "No. of Students", "Certificate Transmittals"],
          ["2019", 502, 499],
          ["2020", 402, 348],
          ["2021", 602, 620],
          ["2022", 203, 48],
        ]);
        setOptions({
          curveType: "function",
          legend: { position: "bottom" },
        });
        break;
      case "monthly-no-of-days":
        setChartType("Bar");
        setData([
          ["Month", "TDC", "PD"],
          ["Jan", 12, 16],
          ["Feb", 15, 12],
          ["Mar", 9, 13],
          ["Apr", 14, 20],
          ["May", 18, 23],
          ["June", 13, 25],
        ]);
        setOptions({
          curveType: "function",
          legend: { position: "bottom" },
        });
        break;
      case "yearly-no-of-days":
        setChartType("Bar");
        setData([
          ["Month", "TDC", "PD"],
          ["2019", 20, 24],
          ["2020", 15, 16],
          ["2021", 14, 14],
          ["2022", 12, 18],
        ]);
        setOptions({
          curveType: "function",

          legend: { position: "bottom" },
        });
        break;
    }
  }, [reportType]);

  return (
    <CustomCard cardHeaderText="Reports">
      <CustomSelect
        width={300}
        items={[
          { string: "Monthly Performance Report", value: "monthly-report" },
          { string: "Yearly Performance Report", value: "yearly-report" },
          {
            string: "No of Days for Completion (Monthly)",
            value: "monthly-no-of-days",
          },
          {
            string: "No of Days for Completion (Yearly)",
            value: "yearly-no-of-days",
          },
        ]}
        value={reportType}
        onItemSelect={(value) => setReportType(value.value)}
      ></CustomSelect>
      <Chart
        style={{ marginTop: 60 }}
        chartType={chartType}
        data={data}
        options={options}
        width="100%"
        height="400px"
        legendToggle
      />
    </CustomCard>
  );
};

export default ReportsPage;
