import { Button } from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";
import "./BackToLoginButton.scss"
const BackToLoginButton = () => {
  const navigation = useNavigate();

  return (
    <Button
      id="back-to-login-button"
      large={true}
      minimal={true}
      icon="arrow-left"
      onClick={() => navigation("/login")}
    >
      Back to Login Page
    </Button>
  );
};

export default BackToLoginButton;
