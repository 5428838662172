import { ViewCertificates, FormPracticalSubmission, FormTheoreticalSubmission, FormTheoreticalTransmit, FormPracticalTransmit, GetRecentActivities, GetStudentList, StudentProfileSubmission } from 'shared-types';
import { parseToFormData, processInternalAPI } from './shared';

const formApis = {
  getRecentActivities: async (reqObj: GetRecentActivities['FTB']) => {
    return processInternalAPI<GetRecentActivities['BTF']>({
      method: 'GET',
      path: '/api/forms/recent-activities',
      reqQuery: reqObj,
      withCredentials: true
    })
  },
  getStudentList: async (reqObj: GetStudentList['FTB']) => {
    return processInternalAPI<GetStudentList['BTF']>({
      method: 'GET',
      path: '/api/student-list',
      reqQuery: reqObj,
      withCredentials: true
    })
  },
  submitStudentProfile: async (reqObj: StudentProfileSubmission['FTB']) => {
    console.log(reqObj)
    return processInternalAPI<StudentProfileSubmission['BTF']>({
      method: 'POST',
      path: '/api/forms/student',
      reqBody: parseToFormData(reqObj),
      withCredentials: true
    })
  },
  submitFormTheoretical: async (reqObj: FormTheoreticalSubmission['FTB']) => {
    console.log(reqObj)

    return processInternalAPI<FormTheoreticalSubmission['BTF']>({
      method: 'POST',
      path: '/api/forms/theoretical/submit',
      reqBody: reqObj,
      withCredentials: true
    })
  },
  submitFormPractical: async (reqObj: FormPracticalSubmission['FTB']) => {
    return processInternalAPI<FormPracticalSubmission['BTF']>({
      method: 'POST',
      path: '/api/forms/practical/submit',
      reqBody: reqObj,
      withCredentials: true
    })
  },
  transmitFormTheoretical: async (reqObj: FormTheoreticalTransmit['FTB']) => {
    return processInternalAPI<FormTheoreticalTransmit['BTF']>({
      method: 'POST',
      path: '/api/forms/theoretical/transmit',
      reqBody: reqObj,
      withCredentials: true
    })
  },
  transmitFormPractical: async (reqObj: FormPracticalTransmit['FTB']) => {
    return processInternalAPI<FormPracticalTransmit['BTF']>({
      method: 'POST',
      path: '/api/forms/practical/transmit',
      reqBody: reqObj,
      withCredentials: true
    })
  },
};

export default formApis;