import { Button } from "@blueprintjs/core";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FingerprintCapture, {
  FingerprintCaptureProps,
} from "../../common-ui/fingerprint-authentication/FingerprintCapture";
import {
  getCredentialsFromLocalStorage,
  mainStorage,
} from "../../utils/custom-local-storage";
import { HTTP_APIs } from "../../utils/http-apis";
import { showHTTPResponseToaster } from "../../utils/toaster-ui";
import AccountDetailsCard from "./account-details-card/AccountDetailsCard";
import GreetingsCard from "./greetings-card/GreetingsCard";
import "./HomePage.css";
import InstructorRegistrationWindow from "./add-instructor-card/InstructorRegistrationWindow";
import ManageDrivingSchools from "./manage-driving-schools/ManageDrivingSchools";
import RecentActivitiesCard from "./recent-activities-card/RecentActivitiesCard";
import StudentRegistrationWindow from "./StudentRegistrationWindow";
import AddInstructorCard from "./add-instructor-card/AddInstructorCard";
import DashboardReportCard from "./dashboard-report-card/DashboardReportCard";
import InformationTextCard from "../about/information-text-card/InformationTextCard";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigation = useNavigate();
  const credentials = getCredentialsFromLocalStorage();

  const [fingerprintStatus, setFingerprintStatus] =
    useState<FingerprintCaptureProps["status"]>("default");

  const [isFingerprintModalOpen, setIsFingerprintModalOpen] = useState(false);

  const onAuthenticateFingerprint = async (event: any[]) => {
    console.log("in home page.");
    setFingerprintStatus("loading");
    console.log(
      event.map((data) =>
        // @ts-ignore
        Fingerprint.b64UrlTo64(JSON.parse(data.samples)[0].Data)
      )[0]
    );
  };

  if (!credentials) return <></>;

  return (
    <>
      <GreetingsCard />
      <DashboardReportCard />
      {credentials.role === "ADMIN" && <AccountDetailsCard />}
      <RecentActivitiesCard />
      <ManageDrivingSchools />
      <AddInstructorCard />
    </>
  );
};

export default HomePage;
