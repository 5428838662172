import { DateFormatProps } from "@blueprintjs/datetime";
import dayjs from "dayjs";
import { SELECT_ITEMS } from "./constants";
import { mainStorage } from "./custom-local-storage";
import { Roles } from 'shared-types';
export type ParseDateOptions = 'MM/DD/YYYY';

export const parseDateString: (format: ParseDateOptions) => DateFormatProps['parseDate'] =
  (format) => {
    return (string) => {
      if (!string) return null;
      const date = dayjs(string, format)
      return date.isValid() ? date.toDate() : false
    }
  }

export const formatDate: (format: ParseDateOptions) => DateFormatProps['formatDate'] =
  (format) => {
    return (inputDate) => {
      const date = dayjs(inputDate)
      return date.format(format)
    }
  }

// Thanks to Dan @ https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number
export function isStringNumeric(str: string) {
  if (typeof str !== "string") return false // we only process strings!  
  return !isNaN(str as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export const getSelectValue = (selectName: keyof typeof SELECT_ITEMS, itemValue: string) => {
  return SELECT_ITEMS[selectName].find(item => item.value === itemValue)?.string ?? null;
}

type NameFormat = 'fl' | 'lfi' | 'full';
export function formatName(name: { firstName: string; middleName?: string; lastName: string }, format: NameFormat) {
  switch (format) {
    case 'fl':
      return `${name.firstName} ${name.lastName}`;
    case 'lfi':
      return `${name.lastName}, ${name.firstName}.`;
    case 'full':
      return `${name.firstName} ${name.middleName ? name.middleName + " " : ""}${name.lastName}`;
  }
};


type NumberFormat = 'currency';
export function formatNumber(number: number, format: NumberFormat) {
  switch (format) {
    case 'currency':
      const numberFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'PHP'
      });
      return numberFormatter.format(number);
  }
}

export const dataUritoBlob = (dataURI: string): Blob => {
  // Lifted from https://stackoverflow.com/questions/12168909/blob-from-dataurl.
  // Thanks to devnull69.

  var byteString = atob(dataURI.split(',')[1]);
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  var arrayBuffer = new ArrayBuffer(byteString.length);
  var _ia = new Uint8Array(arrayBuffer);
  for (var i = 0; i < byteString.length; i++) {
    _ia[i] = byteString.charCodeAt(i);
  };

  var dataView = new DataView(arrayBuffer);
  var blob = new Blob([dataView], { type: mimeString });
  return blob;
};