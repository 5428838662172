import { EnrollFingerprints, VerifyFingerprints } from 'shared-types';
import { parseToFormData, processInternalAPI } from './shared';

const fingerprintApis = {
  enrollFingerprints: async (reqObj: EnrollFingerprints['FTB']) => {
    return processInternalAPI<EnrollFingerprints['BTF']>({
      method: 'POST',
      path: '/api/fingerprints/enroll',
      reqBody: reqObj,
      withCredentials: true
    })
  },
  verifyFingerprints: async (reqObj: VerifyFingerprints['FTB']) => {
    return processInternalAPI<VerifyFingerprints['BTF']>({
      method: 'POST',
      path: '/api/fingerprints/verify',
      reqBody: reqObj,
      withCredentials: true
    })
  },
};

export default fingerprintApis;