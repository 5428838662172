import { Checkbox, CheckboxProps } from "@blueprintjs/core";
import { FC } from "react";

type RootTableRowProps = {
  code: string;
  description: string;
  disabled?: boolean;
  checked: boolean;
  onChange: CheckboxProps["onChange"];
};

const RootTableRow: FC<RootTableRowProps> = (props) => {
  return (
    <tr>
      <td>
        <Checkbox
          className="table-checkbox"
          large={true}
          disabled={props.disabled}
          checked={props.checked}
          onChange={props.onChange}
        >
          {props.code} - {props.description}
        </Checkbox>
      </td>
      <td className="table-border-left"></td>
      <td className="table-border-right"></td>
      <td className="table-border-left"></td>
      <td className="table-border-right"></td>
    </tr>
  );
};

export default RootTableRow;
