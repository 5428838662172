import { StudentProfileProps } from "../common-ui/student-profile/StudentProfile";
import { EMPTY_FORMS } from "./constants/empty-forms";
import { CourseDetailsFormValues, UserCredentialsStored, DLRestrictionsFormValues, PersonalInformationFormValues, AdminCredentialsStore as AdminCredentialsStored, AdminCredentialsStore } from "./typings";

const MainStorageKeys = [
  'admin-credentials',
  'user-credentials',
  'student-profile-to-enroll',
  'student-profile-to-certify',
  'dl-restrictions-form',
  'course-details-form',
  'form-to-submit-type',
  'photo-url',
] as const;

type MainStorageKeys = typeof MainStorageKeys[number];

class MainStorage {
  private storage = sessionStorage;
  private readonly storageRootKey = '@main-storage/';

  get(key: 'admin-credentials'): AdminCredentialsStored | null;
  get(key: 'user-credentials'): UserCredentialsStored | null;
  get(key: 'form-to-submit-type'): 'theoretical' | 'practical';
  get(key: 'student-profile-to-enroll'): PersonalInformationFormValues;
  get(key: 'student-profile-to-certify'): StudentProfileProps['data'] & { studentId: string } | null;
  get(key: 'course-details-form'): CourseDetailsFormValues;
  get(key: 'dl-restrictions-form'): DLRestrictionsFormValues;
  get(key: 'photo-url'): string | null;
  get(key: MainStorageKeys) {
    const value = this.storage.getItem(this.storageRootKey + key);

    switch (key) {
      case 'admin-credentials':
        return value ? JSON.parse(value) as AdminCredentialsStored : null;
      case 'user-credentials':
        return value ? JSON.parse(value) as UserCredentialsStored : null;
      case 'student-profile-to-enroll':
        return value ? JSON.parse(value) as PersonalInformationFormValues : EMPTY_FORMS.PERSONAL_INFORMATION;
      case 'student-profile-to-certify':
        return value ? JSON.parse(value) as StudentProfileProps['data'] & { studentId: string } : null;
      case 'dl-restrictions-form':
        return value ? JSON.parse(value) as DLRestrictionsFormValues : EMPTY_FORMS.DL_RESTRICTIONS;
      case 'course-details-form':
        return value ? JSON.parse(value) as CourseDetailsFormValues : EMPTY_FORMS.COURSE_DETAILS;
      case 'photo-url':
      case 'form-to-submit-type':
        return value;
      default:
        throw new Error(`Main storage keys (${key}) not found.`);
    }
  }

  set(key: 'admin-credentials', value: AdminCredentialsStored): void;
  set(key: 'user-credentials', value: UserCredentialsStored): void;
  set(key: 'form-to-submit-type', value: 'theoretical' | 'practical'): void;
  set(key: 'student-profile-to-enroll', value: PersonalInformationFormValues): void
  set(key: 'dl-restrictions-form', value: DLRestrictionsFormValues): void;
  set(key: 'course-details-form', value: CourseDetailsFormValues): void;
  set(key: 'photo-url', value: string): void;
  set(key: 'student-profile-to-certify', value: StudentProfileProps['data']): void
  set(key: MainStorageKeys, value: any) {
    switch (key) {
      case 'admin-credentials':
        this.storage.setItem(this.storageRootKey + key, JSON.stringify(value));
        this.storage.removeItem(this.storageRootKey + 'user-credentials');
        return;
      case 'user-credentials':
        this.storage.setItem(this.storageRootKey + key, JSON.stringify(value));
        this.storage.removeItem(this.storageRootKey + 'admin-credentials');
        return;
      case 'student-profile-to-enroll':
      case 'student-profile-to-certify':
      case 'dl-restrictions-form':
      case 'course-details-form':
        this.storage.setItem(this.storageRootKey + key, JSON.stringify(value));
        return;
      case 'photo-url':
      case 'form-to-submit-type':
        this.storage.setItem(this.storageRootKey + key, value);
        return;
      default:
      // this.storage.setItem(key,);
    };
  };

  clear(key: MainStorageKeys) {
    this.storage.removeItem(this.storageRootKey + key)
  };
}

export const mainStorage = new MainStorage()


export function getCredentialsFromLocalStorage():
  ({
    role: 'ADMIN' | 'INSTRUCTOR';
    data: UserCredentialsStored
  } | {
    role: 'SYSTEM-ADMIN';
    data: AdminCredentialsStore
  } | null) {
  const adminCredentials = mainStorage.get('admin-credentials');
  if (adminCredentials) return {
    role: 'SYSTEM-ADMIN',
    data: adminCredentials
  };

  const userCredentials = mainStorage.get('user-credentials');
  if (userCredentials) return {
    data: userCredentials,
    role: userCredentials.role,
  };

  return null;
};

export function clearLocalStorage() {
  localStorage.clear();
  sessionStorage.clear();
};
