import { HTMLInputProps, NumericInput, NumericInputProps } from "@blueprintjs/core";
import { DateInputProps } from "@blueprintjs/datetime";
import React from "react";
import { formatDate, isStringNumeric, parseDateString } from "./common";
import { DEFAULT_DATE_FORMAT } from "./constants";

export const templateDateInputProps: DateInputProps = {
  formatDate: formatDate(DEFAULT_DATE_FORMAT),
  parseDate: parseDateString(DEFAULT_DATE_FORMAT),
  popoverProps: { minimal: true, position: "bottom-left" },
  placeholder: DEFAULT_DATE_FORMAT
}
