import { Button, InputGroup, InputGroupProps } from "@blueprintjs/core";
import { DateInput, DatePicker } from "@blueprintjs/datetime";
import { FC, useEffect, useState } from "react";
import CustomDialog, {
  CustomDialogProps,
} from "../../../common-ui/custom-dialog/CustomDialog";
import FingerprintCapture from "../../../common-ui/fingerprint-authentication/FingerprintCapture";
import { getCredentialsFromLocalStorage } from "../../../utils/custom-local-storage";
import { HTTP_APIs } from "../../../utils/http-apis";
import { templateDateInputProps } from "../../../utils/template-props";

export type InstructorRegistrationWindowProps = {
  isOpen: boolean;
  onClose: CustomDialogProps["onClose"];
};

const emptyForm = {
  firstName: "",
  middleName: "",
  lastName: "",
  emailAddress: "",
  accreditationNumber: "",
  password: "",
  confirmPassword: "",
  dateOfBirth: null as null | Date,
};

const InstructorRegistrationWindow: FC<InstructorRegistrationWindowProps> = (
  props
) => {
  const credentials = getCredentialsFromLocalStorage();
  const [formValues, setFormValues] = useState(emptyForm);
  const [fingerprints_0, setFingerprints_0] = useState<string[]>([]);
  const [fingerprints_1, setFingerprints_1] = useState<string[]>([]);
  const [formValidity, setFormValidity] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const isFormValid = Object.entries(formValues).every((entry) => {
      if (entry[0] === "middleName") return true;
      if (!entry[1]) return false;
      return true;
    });
    const arePasswordsTheSame =
      formValues.password === formValues.confirmPassword;
    setFormValidity(
      isFormValid &&
        arePasswordsTheSame &&
        !!fingerprints_0.length &&
        !!fingerprints_1.length
    );
  }, [formValues, fingerprints_0, fingerprints_1]);

  const [isFingerprintModal_0_Open, setIsFingerprintModal_0_Open] =
    useState(false);
  const [isFingerprintModal_1_Open, setIsFingerprintModal_1_Open] =
    useState(false);
  const onInputChange = (
    key: Exclude<keyof typeof formValues, "fingerprints">
  ): InputGroupProps["onChange"] => {
    return (event) =>
      setFormValues({
        ...formValues,
        [key]: (event.target as HTMLInputElement).value,
      });
  };

  async function onFormSubmit() {
    setIsLoading(true);
    const response = await HTTP_APIs.personnel.registerInstructor({
      ...formValues,
      dateOfBirth: formValues.dateOfBirth!.toISOString(),
      fingerprints: [fingerprints_0, fingerprints_1],
    });
    setIsLoading(false);
    console.log(response)
    if (response.status === "success") {
      props.onClose?.({} as any);
      setFormValues(emptyForm)
      setFingerprints_0([])
      setFingerprints_1([])
      console.log("success!");
    }
  }

  if (credentials?.role !== "ADMIN") return <></>;

  return (
    <>
      <CustomDialog
        style={{ width: "auto" }}
        isOpen={props.isOpen}
        title="Register Instructor"
        onClose={props?.onClose}
      >
        <form style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <div style={{ display: "flex", gap: 60 }}>
            <div className="field-container-vert">
              <div>Driving School</div>
              <div>{credentials.data.drivingSchoolName}</div>
            </div>
          </div>
          <div style={{ display: "flex", gap: 20 }}>
            <div className="field-container-vert">
              <div>First Name</div>
              <InputGroup
                style={{ width: 150 }}
                value={formValues.firstName}
                onChange={onInputChange("firstName")}
              />
            </div>
            <div className="field-container-vert">
              <div>Middle Name</div>
              <InputGroup
                style={{ width: 100 }}
                value={formValues.middleName}
                onChange={onInputChange("middleName")}
              />
            </div>
            <div className="field-container-vert">
              <div>Last Name</div>
              <InputGroup
                style={{ width: 150 }}
                value={formValues.lastName}
                onChange={onInputChange("lastName")}
              />
            </div>
          </div>
          <div style={{ display: "flex", gap: 20 }}>
            <div className="field-container-vert">
              <div>Date of Birth</div>
              <DateInput
                value={formValues.dateOfBirth}
                onChange={(date) =>
                  setFormValues({
                    ...formValues,
                    dateOfBirth: date,
                  })
                }
                {...templateDateInputProps}
              />
            </div>
          </div>
          <div style={{ display: "flex", gap: 20 }}>
            <div className="field-container-vert">
              <div>Email Address</div>
              <InputGroup
                style={{ width: 175 }}
                value={formValues.emailAddress}
                onChange={onInputChange("emailAddress")}
              />
            </div>
            <div className="field-container-vert">
              <div>Accreditation Number</div>
              <InputGroup
                style={{ width: 200 }}
                value={formValues.accreditationNumber}
                onChange={onInputChange("accreditationNumber")}
              />
            </div>
          </div>
          <div style={{ display: "flex", gap: 20 }}>
            <div className="field-container-vert">
              <div>Password</div>
              <InputGroup
                type="password"
                style={{ width: 200 }}
                value={formValues.password}
                onChange={onInputChange("password")}
              />
            </div>
            <div className="field-container-vert">
              <div>Confirm Password</div>
              <InputGroup
                type="password"
                style={{ width: 200 }}
                value={formValues.confirmPassword}
                onChange={onInputChange("confirmPassword")}
              />
            </div>
          </div>
          <div style={{ display: "flex", gap: 10, marginTop: 40 }}>
            <Button
              icon={fingerprints_0.length === 0 ? "upload" : "tick"}
              disabled={fingerprints_0.length !== 0}
              onClick={() => setIsFingerprintModal_0_Open(true)}
              intent={fingerprints_0.length === 0 ? "none" : "success"}
            >
              Fingerprint (thumb)
            </Button>
            <Button
              icon={fingerprints_1.length === 0 ? "upload" : "tick"}
              disabled={fingerprints_1.length !== 0}
              onClick={() => setIsFingerprintModal_1_Open(true)}
              intent={fingerprints_1.length === 0 ? "none" : "success"}
            >
              Fingerprint (index)
            </Button>
          </div>
          <div className="buttons-container">
            <Button onClick={props.onClose}>CANCEL</Button>
            <Button
              intent="primary"
              disabled={!formValidity}
              onClick={onFormSubmit}
              loading={isLoading}
            >
              SUBMIT
            </Button>
          </div>
        </form>
      </CustomDialog>
      <FingerprintCapture
        numberOfFingerprints={3}
        onCapture={(event) => {
          setFingerprints_0(event);
        }}
        acquisitionType={2}
        isOpen={isFingerprintModal_0_Open}
        onClose={() => setIsFingerprintModal_0_Open(false)}
        successMessage="Fingerprints successfully captured."
        status={fingerprints_0.length === 0 ? "default" : "success"}
      />
      <FingerprintCapture
        numberOfFingerprints={3}
        onCapture={(event) => {
          setFingerprints_1(event);
        }}
        acquisitionType={2}
        isOpen={isFingerprintModal_1_Open}
        onClose={() => setIsFingerprintModal_1_Open(false)}
        successMessage="Fingerprints successfully captured."
        status={fingerprints_1.length === 0 ? "default" : "success"}
      />
    </>
  );
};

export default InstructorRegistrationWindow;
